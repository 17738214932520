import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProgressBar from '@ramonak/react-progress-bar'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import RatingContainer from '../RatingContainer/Rating'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import Slider from '../ReviewsAndRating/slider'
import ServicesProfile from '../Services/Services'
import Jobs from '../EducationSchoolUnivercity/Jobs'
import CurruntJobs from '../EducationSchoolUnivercity/CurruntJobs'
import Services from '../Agency/Services'
import ProjectPortfolio from '../Agency/ProjectPortfolio'
import RecentProject from '../Agency/RecentProject'
import Portfolio from '../Agency/Portfolio'
import Workplaces from './Workplaces'
import Donation from './Donation'
import AboutIco from '../../images/AboutIco.svg'
import HomeIco from '../../images/HomeIco.svg'
import FeedbackIco from '../../images/FeedbackIco.svg'
import JobIco from '../../images/JobIco.svg'
import PortfolioIco from '../../images/PortfolioIco.svg'
import ServiceIco from '../../images/ServiceIco.svg'
import DonationIco from '../../images/DonationIco.svg'
import HandIco from '../../images/HandIco.svg'

const Foundation = () => {
  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={HomeIco} />
                  Home
                </Tab>
                <Tab>
                  <img alt="" src={ServiceIco} />
                  Services
                </Tab>
                <Tab>
                  <img alt="" src={DonationIco} />
                  Donations
                </Tab>
                <Tab>
                  <img alt="" src={PortfolioIco} />
                  Portfolio
                </Tab>
                <Tab>
                  <img alt="" src={JobIco} />
                  Jobs
                </Tab>
                <Tab>
                  <img alt="" src={FeedbackIco} />
                  Reviews/Rating
                </Tab>
                <Tab>
                  <img alt="" src={AboutIco} />
                  About
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="mt-40">
                  <Workplaces />
                </div>
                <div className="mt-40 common-card-body recent-video-component">
                  <h3>Donation</h3>
                  <p className="total-raised">
                    €14,800
                    {' '}
                    <span className="percentage-raised m-0 d-inline">
                      Raised
                    </span>
                  </p>
                  <ProgressBar
                    customLabel="|"
                    className="profress-line"
                    baseBgColor="#D9D9D9"
                    height={28}
                    completed={80}
                    borderRadius={30}
                    bgColor="linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%)"
                    isLabelVisible={false}
                    labelClassName="polls-label"
                  />
                  <span className="percentage-raised">
                    80% raised of €17,000 goal
                    {' '}
                  </span>
                  <div className="text-center mt-40">
                    <button type="button" className="primary-btn w-400">Donate</button>
                  </div>
                </div>
                <div className="mt-40">
                  <Services />
                </div>
                <div className="mt-40">
                  <ProjectPortfolio />
                </div>
                <div className="mt-40">
                  <RecentProject />
                </div>
                <div className="mt-40">
                  <CurruntJobs />
                </div>
                <div className="about common-card-body recent-video-component mt-40">
                  <h3>About</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                    pellentes nullam sit amet, lorem. Semper vel egestas
                    convallis nisl duis mattis ut molestie sed. Mauris feugiat
                    tincidunt nunc, quam mauris, massa diam faucibus.
                    {' '}
                    <span>See more</span>
                  </p>
                </div>

                <div className="bg-linier-blue mt-40">
                  <img alt="" src={HandIco} />
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="bg-linier-blue-left">
                      <h4>Say Hello!</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Dolor, diam dignissim malesuada eget laoreet libero
                        feugiat eu. Pharetra ornare risus scelerisque sed
                        mauris.
                      </p>
                    </div>
                    <button type="button" className="light-button">
                      <span>Say Hello</span>
                    </button>
                  </div>
                </div>

                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <ServicesProfile />
              </TabPanel>
              <TabPanel>
                <Donation />
              </TabPanel>
              <TabPanel>
                <Portfolio />
              </TabPanel>
              <TabPanel>
                <Jobs />
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Foundation
