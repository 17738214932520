import React, { useEffect } from 'react'
import './CreatorProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useNavigate, useParams } from 'react-router-dom'
// import ProfileHeadStore from '../ProfileBuilder/ProfileHeadStore'
// import Feeds from "../Feeds/AddFeeds";
// import ProfileReviewsAndReting from "../ReviewsAndRating/ProfileReviewAndRating";
import InputComponent from '../UploadComponent/InputComponent'
import AddProduct from '../AddProduct/AddProduct'
import AddProductFilter from '../AddProduct/AddProductFilter'
import HomeIco from '../../images/HomeIco.svg'
import FeedIco from '../../images/FeedIco.svg'
import StoreIco from '../../images/StoreIco.svg'
import ProfileHomePage from './profileHomePage'
import Feeds from '../Feeds/Feeds'
// import FeedbackIco from '../../images/FeedbackIco.png'

const CommunityBuilder = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(`/store-builder/${id}`)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  return (
    <div className="creator-profile-main creator-profile-create">
      {/* <ProfileHeadStore /> */}
      <div className="mt-15">
        <div className="details-tab-view pt-0">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={HomeIco} alt="Home icon" />
                  Home
                </Tab>
                <Tab>
                  <img src={FeedIco} alt="Feed icon" />
                  Feed
                </Tab>
                <Tab>
                  <img src={StoreIco} alt="Store icon" />
                  Store
                </Tab>
                {/* <Tab>
                  <img src={FeedbackIco} />
                  Reviews/Rating
                </Tab> */}
              </TabList>

              <TabPanel>
                <ProfileHomePage />
              </TabPanel>
              <TabPanel>
                <div>
                  <Feeds />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <InputComponent />
                </div>
                <div className="mt-40">
                  <AddProduct />
                </div>
                <div className="mt-40">
                  <AddProductFilter />
                </div>
              </TabPanel>
              {/* <TabPanel>
                <div>
                  <Feeds />
                </div>
              </TabPanel> */}
              {/* <TabPanel>
                <div>
                  <InputComponent />
                  <div className="mt-40">
                    <AddProductFilter />
                  </div>
                </div>
              </TabPanel> */}
              {/* <TabPanel>
                <div>
                  <ProfileReviewsAndReting />
                </div>
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CommunityBuilder
