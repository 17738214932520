import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PlusTag from '../../images/Plus-tag-blue.svg'
import Remove from '../../images/remove.svg'
import { getAppAddons } from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'

const PlatformTag = ({ onItemSelect, Platformtags, setPlatformTags }) => {
  const [addOns, setAddOns] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])

  const tags = Array.isArray(Platformtags) ? Platformtags : []

  const getAddOnsData = () => {
    getAppAddons().then((res) => {
      if (res.data.success) {
        const DecodedData = dataDecrypt(res.data.data)
        setAddOns(DecodedData)
        // localStorage.setItem(
        //   "app_id",
        //   JSON.stringify(dataDecrypt(DecodedData[0])),
        // );
      }
    })
  }

  useEffect(() => {
    getAddOnsData()
  }, [])

  useEffect(() => {
    const appId = localStorage.getItem('app_id')
    if (appId && addOns.length > 0) {
      const parsedAppId = JSON.parse(appId)
      const defaultTag = addOns.find((tag) => tag.name === parsedAppId.name)
      if (
        defaultTag &&
        !tags.some((tag) => tag.name === defaultTag.name)
      ) {
        const updatedTags = [...tags, defaultTag]
        setPlatformTags(updatedTags)
        onItemSelect(updatedTags)
      }
    }
  }, [addOns])

  const handleAddPlatformTag = () => {
    if (tags.length + selectedTags.length <= 10 && selectedTags.length > 0) {
      const updatedTags = [...tags, ...selectedTags]
      setPlatformTags(updatedTags)
      setSelectedTags([])
      setIsOpen(false)
      onItemSelect(updatedTags)
    }
  }

  const handleRemovePlatformTag = (tagIndex) => {
    const updatedTags = tags.filter((_, index) => index !== tagIndex)
    setPlatformTags(updatedTags)
    onItemSelect(updatedTags)
  }

  const handleTagSelect = (tag) => {
    if (!selectedTags.some((selectedTag) => selectedTag.name === tag.name) &&
        !tags.some((platformTag) => platformTag.name === tag.name)) {
      setSelectedTags((prevSelectedTags) => [...prevSelectedTags, tag])
    }
  }

  const isTagSelected = (tag) => {
    return selectedTags.some((selectedTag) => selectedTag.name === tag.name)
  }

  const availableAddOns = addOns.filter(
    (tag) => !tags.some((selectedTag) => selectedTag.name === tag.name)
  )

  const allPlatformsSelected = availableAddOns.length === 0

  return (
    <div className="platformtag">
      <div className="add-tags d-block mt-3">
        <div className="">
          <span className="mb-3 d-block">Add Platform</span>
          <div className="d-flex flex-wrap column-gap-20 platformTag_dropdown">

            <div className={`dropdown ${isOpen ? 'active' : ''}`}>
              <div
                className="dropdown__text"
                onClick={() => setIsOpen(!isOpen)}
              >
                <img src={PlusTag} alt="Add" />
                <span>Select Platform</span>
              </div>
              {isOpen && (
                <div className="dropdown__items">
                  {allPlatformsSelected ? (
                    <div className="dropdown__item">
                      Already Selected
                    </div>
                  ) : (
                    availableAddOns.map((tag, index) => (
                      <div
                        onClick={() => handleTagSelect(tag)}
                        className={`dropdown__item mt-2 ${isTagSelected(tag) ? 'selected' : ''}`}
                        key={index}
                      >
                        <img src={tag.img} alt="" />
                        {tag.name}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <button
              type="button"
              className="add-tag-btn h-100"
              onClick={handleAddPlatformTag}
            >
              <span className="select-plat">Add</span>
            </button>
          </div>
        </div>
        <ul className="selected-tags">
          {tags.map((tag, index) => (
            <li key={index} className="dropdown__item">
              <img src={tag.img} alt="" />
              {tag.name}
              <button
                type="button"
                className="remove-tag"
                onClick={() => handleRemovePlatformTag(index)}
              >
                <img src={Remove} alt="" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
PlatformTag.propTypes = {
  onItemSelect: PropTypes.func.isRequired
}
export default PlatformTag
