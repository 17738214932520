import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import './Stories.css'

const Stories = () => {
  const items = [
    {
      name: 'Workouts',
      cover: './images/story/workouts.svg',
      img: './images/story/workouts.png'
    },
    {
      name: 'Nutrition',
      cover: './images/story/nutrition.svg',
      img: './images/story/nutrition.png'
    },
    {
      name: 'Lifestyle',
      cover: './images/story/lifestyle.svg',
      img: './images/story/lifestyle.png'
    },
    {
      name: 'Menu',
      cover: './images/story/menu.svg',
      img: './images/story/menu.png'
    },
    {
      name: 'Art',
      cover: './images/story/art.svg',
      img: './images/story/art.png'
    },
    {
      name: 'Workouts',
      cover: './images/story/workouts.svg',
      img: './images/story/workouts.png'
    },
    {
      name: 'Nutrition',
      cover: './images/story/nutrition.svg',
      img: './images/story/nutrition.png'
    },
    {
      name: 'Lifestyle',
      cover: './images/story/lifestyle.svg',
      img: './images/story/lifestyle.png'
    },
    {
      name: 'Menu',
      cover: './images/story/menu.svg',
      img: './images/story/menu.png'
    },
    {
      name: 'Art',
      cover: './images/story/art.svg',
      img: './images/story/art.png'
    }
  ]
  return (
    <div className="stories-component common-card-body">
      <h3>Stories</h3>
      <div className="position-relative">
        <Swiper
          slidesPerView="auto"
          spaceBetween={30}
          navigation
          modules={[Navigation]}
          className="mySwiper-story position-static"
        >
          {items.map((item, i) => (
            <SwiperSlide key={item.name}>
              <div className="slider-story" key={i}>
                <div
                  className="story-slider-item"
                  style={{
                    background: `url(${item.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="story-culture">
                    <div className="story-culture-img">
                      <img alt="" src={item.cover} />
                    </div>
                    <div className="culture-name">{item.name}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
export default Stories
