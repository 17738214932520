import React from 'react'
import PodcastComponent from './PodcastComponent'


const AddPodcast = () => {
  return (
    <div className="recent-video-component recent-podcast common-card-body add-podcaest-video">
      <PodcastComponent showCount={3} />
    </div>
  )
}
export default AddPodcast
