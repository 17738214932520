import React from 'react'
import './Ads.css'
import Que from '../../images/que.svg'
import AerrowUpIco from '../../images/AerrowUpIco.svg'

const AdsHome = () => {
  const statistics = [
    {
      newLikes: '356',
      totalLikes: '15.240',
      newEngage: '1.342',
      totalEngage: '25.378',
      newReachout: '5.321',
      totalReachout: '30.552',
      likeGrow: '3.58',
      engageGrow: '3.58',
      reachoutGrow: '3.58',
      likeGrowFrom: '69,899',
      engageGrowFrom: '69,899',
      reachoutGrowFrom: '69,899'
    }
  ]
  return (
    <div className="ads-banner-section d-flex">
      <div className="ads-campaign-statistic w-50">
        <h2>Find Way to Burn Fats</h2>
        <p>
          A new method to burn fat and loose weight. Forget about Keto and all
          the other diets. This method is the way to go!
        </p>
        <h3>Running Compaign Statistics</h3>
        <div className="running-campaign-statistic">
          <div className="statistic-item">
            <h4>
              New Likes
              {' '}
              <img alt="" src={Que} />
            </h4>
            <h5 className="gredient-txt">{statistics[0].newLikes}</h5>
            <h4>
              Total Likes
              {' '}
              <img alt="" src={Que} />
            </h4>
            <h5 className="gredient-txt">{statistics[0].totalLikes}</h5>
            <div className="statistic-footer">
              <div className="badge-txt">
                <img alt="" src={AerrowUpIco} />
                <p className="gredient-txt2">
                  {statistics[0].likeGrow}
                  {' '}
                  %
                </p>
              </div>
              <span className="gredient-txt">
                From
                {' '}
                {statistics[0].likeGrowFrom}
              </span>
            </div>
          </div>
          <div className="statistic-item">
            <h4>
              New Engage
              {' '}
              <img alt="" src={Que} />
            </h4>
            <h5 className="gredient-txt">{statistics[0].newEngage}</h5>
            <h4>
              Total Engage
              {' '}
              <img alt="" src={Que} />
            </h4>
            <h5 className="gredient-txt">{statistics[0].totalEngage}</h5>
            <div className="statistic-footer">
              <div className="badge-txt">
                <img alt="" src={AerrowUpIco} />
                <p className="gredient-txt2">
                  {statistics[0].engageGrow}
                  {' '}
                  %
                </p>
              </div>
              <span className="gredient-txt">
                From
                {' '}
                {statistics[0].engageGrowFrom}
              </span>
            </div>
          </div>
          <div className="statistic-item">
            <h4>
              New Reachout
              {' '}
              <img alt="" src={Que} />
            </h4>
            <h5 className="gredient-txt">{statistics[0].newReachout}</h5>
            <h4>
              Total Reachout
              {' '}
              <img alt="" src={Que} />
            </h4>
            <h5 className="gredient-txt">{statistics[0].totalReachout}</h5>
            <div className="statistic-footer">
              <div className="badge-txt">
                <img alt="" src={AerrowUpIco} />
                <p className="gredient-txt2">
                  {statistics[0].reachoutGrow}
                  {' '}
                  %
                </p>
              </div>
              <span className="gredient-txt">
                From
                {' '}
                {statistics[0].reachoutGrowFrom}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="ads-campaign-video w-50"
        style={{
          background: 'url(./images/podcast-video-cover.jpg)',
          backgroundSize: 'cover'
        }}
      />
    </div>
  )
}
export default AdsHome
