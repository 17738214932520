/* eslint-disable no-multi-assign */
import axios from 'axios'
import { decode as base64_decode, encode as base64_encode } from 'base-64'
import imageCompression from 'browser-image-compression'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'

export const getData = (url) => {
  const token = localStorage.getItem('token')
  return axios({
    method: 'GET',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export const postData = (url, data) => {
  const formData = new FormData()
  formData.append('data', dataEncrypt(data))
  const token = localStorage.getItem('token')

  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export const ImagePostData = (url, data) => {
  const formData = new FormData()
  formData.append('image', data)
  return axios.post(url, formData).then((res) => {
    return res
  })
}

export const compressImage = async (imageFile) => {
  const options = {
    maxSizeMB: 0.02, // (20 KB)
    maxWidthOrHeight: 1024,
    useWebWorker: true
  }

  try {
    console.log(`Original file size: ${(imageFile.size / 1024).toFixed(2)} KB`)
    const compressedFile = await imageCompression(imageFile, options)
    console.log(
      `Compressed file size: ${(compressedFile.size / 1024).toFixed(2)} KB`,
    )
    return compressedFile
  } catch (error) {
    console.error('Image compression error', error)
  }
}

export const uploadFileData = (url, formData) => {
  return axios
    .post(url, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then((res) => {
      return res
    })
}

export const compressVideo = async (file) => {
  const ffmpeg = new FFmpeg({ log: true })

  try {
    await ffmpeg.load()

    await ffmpeg.writeFile('input.mp4', await fetchFile(file))
    await ffmpeg.exec([
      '-i',
      'input.mp4',
      '-c:v',
      'libx264',
      '-crf',
      '30',
      '-preset',
      'ultrafast',
      'output.mp4'
    ])

    const fileData = await ffmpeg.readFile('output.mp4')
    const compressedFile = new Blob([fileData.buffer], { type: 'video' })


    const newFile = new File([compressedFile], {
      type: 'video/mp4'
    })

    return newFile
  } catch (err) {
    throw new Error(`Video compression failed: ${err.message}`)
  }
}

const generateRandomString = () => {
  const randomString = Math.random().toString(36).substring(2, 18)
  const remainrandomString = Math.random().toString(36).substring(2, 18)
  return randomString.padEnd(16, remainrandomString).substring(0, 16)
}

export const dataEncrypt = (data) => {
  const string = JSON.stringify(data, null, 2)

  const key = generateRandomString()
  let result = ''
  for (let i = 0; i < string.length; i++) {
    const char = string.charAt(i)
    let index = (i % key.length) - 1
    index = index = ((index % key.length) + key.length) % key.length
    const keychar = key.slice(index, index + 1)
    const encryptedChar = String.fromCharCode(
      char.charCodeAt(0) + keychar.charCodeAt(0),
    )

    result += encryptedChar
  }
  const base64EncodedResult = base64_encode(result)

  return base64EncodedResult + key
}

export const dataDecrypt = (encryptedString) => {
  if (Array.isArray(encryptedString) || String(encryptedString).length <= 16) {
    return encryptedString
  }

  try {
    const key = encryptedString?.slice(-16)
    const encryptedData = encryptedString?.slice(0, -16)

    let result = ''
    const decodedData = base64_decode(encryptedData)

    for (let i = 0; i < decodedData.length; i++) {
      const char = decodedData.charAt(i)
      let index = (i % key.length) - 1
      index = index = ((index % key.length) + key.length) % key.length
      const keychar = key.slice(index, index + 1)
      const encryptedChar = String.fromCharCode(
        char.charCodeAt(0) - keychar.charCodeAt(0),
      )

      result += encryptedChar
    }
    const decryptedJson = JSON?.parse(result) ?? ''
    return decryptedJson
  } catch (e) {
    return encryptedString
  }
}
