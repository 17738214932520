import React, { useState } from 'react'
import './Dashboard.css'
import ProgressBar from '@ramonak/react-progress-bar'
import AZFit from '../../images/a-z-fit-table.svg'
import Scalco from '../../images/scalco-table.svg'
import CreatorTelents from '../../images/creator-telents-table.svg'
import GoAthlete from '../../images/go-athelete-table.svg'
import Spacure from '../../images/spacure-table.svg'
import Testiee from '../../images/testiee-table.svg'
import PetZino from '../../images/petzino-table.svg'
import LuxuryEleven from '../../images/luxury-eleven-table.svg'
import Fyerdates from '../../images/fyredates-table.svg'
import TheTesteClub from '../../images/the-teste-club-table.svg'
import FamilyTrends from '../../images/family-trends-table.svg'
import Filiyo from '../../images/filiyo-table.svg'
import DownIco from '../../images/DownIco.svg'
import DonutChart from './DonutChart'

const Insights = () => {
  const [visibleElements, setVisibleElements] = useState(6)

  const handleShowMore = () => {
    setVisibleElements((prevVisibleElements) => prevVisibleElements + 3)
  }

  return (
    <div className="insights">
      <div className="users-interactions">
        <div className="row">
          <div className="col-xl-6">
            <h3>Users Interactions</h3>
            <DonutChart />
          </div>
          <div className="col-xl-6 chart-progress">
            <h3>Active Users Percentage</h3>
            <p>
              0
              {' '}
              <span>Total</span>
            </p>
            <ProgressBar
              className="profress-line"
              baseBgColor="#edeef3"
              height={14}
              completed={30}
              bgColor="linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%)"
              isLabelVisible={false}
            />
            <ul>
              <li>
                <span className="online-user-sign" />
                <div className="online-user">
                  <span>Online</span>
                  0 Users
                </div>
              </li>
              <li>
                <span className="offline-user-sign" />
                <div className="online-user">
                  <span>Offline</span>
                  0 Users
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="insight-table">
        <table className="w-100 text-center">
          <tr>
            <th>Platform name</th>
            <th>Daily visitors</th>
            <th>Age</th>
            <th>Video clicks</th>
            <th>Story clicks</th>
            <th>Total comments</th>
          </tr>
          {visibleElements >= 1 && (
            <tr>
              <td>
                <img src={AZFit} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>50</td>
              <td>55</td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              {/* <td>
                <span className="td-link">50</span>
              </td> */}
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 2 && (
            <tr>
              <td>
                <img src={Scalco} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-60</td>
              <td>600</td>
              <td>66</td>
              <td>
                <span className="td-link">100</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 3 && (
            <tr>
              <td>
                <img src={CreatorTelents} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>60</td>
              <td>20</td>
              <td>
                <span className="td-link">66</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 4 && (
            <tr>
              <td>
                <img src={GoAthlete} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-70</td>
              <td>150</td>
              <td>56</td>
              <td>
                <span className="td-link">75</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 5 && (
            <tr>
              <td>
                <img src={Spacure} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>20-50</td>
              <td>600</td>
              <td>100</td>
              <td>
                <span className="td-link">100</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 6 && (
            <tr>
              <td>
                <img src={Testiee} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>300</td>
              <td>150</td>
              <td>
                <span className="td-link">50</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 7 && (
            <tr>
              <td>
                <img src={PetZino} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>325</td>
              <td>170</td>
              <td>
                <span className="td-link">30</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 8 && (
            <tr>
              <td>
                <img src={LuxuryEleven} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>600</td>
              <td>175</td>
              <td>
                <span className="td-link">89</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 9 && (
            <tr>
              <td>
                <img src={Fyerdates} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>600</td>
              <td>158</td>
              <td>
                <span className="td-link">87</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 10 && (
            <tr>
              <td>
                <img src={TheTesteClub} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>750</td>
              <td>149</td>
              <td>
                <span className="td-link">53</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 11 && (
            <tr>
              <td>
                <img src={FamilyTrends} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>20-80</td>
              <td>730</td>
              <td>200</td>
              <td>
                <span className="td-link">46</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
          {visibleElements >= 12 && (
            <tr>
              <td>
                <img src={Filiyo} alt="" />
              </td>
              {/* <td>200-300</td>
              <td>22-50</td>
              <td>99</td>
              <td>150</td>
              <td>
                <span className="td-link">35</span>
              </td> */}
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>
                <span className="td-link">0</span>
              </td>
            </tr>
          )}
        </table>
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={handleShowMore}>
            <img src={DownIco} alt="" />
          </button>
        </div>
      </div>
    </div>
  )
}
export default Insights
