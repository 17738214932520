/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ReactDatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import OrderChart from './chart'
import CustomerChart from './chart'
import ProductChart from './chart'
import RatingStar from '../../images/rating-start.svg'
import Location from '../../images/Locations.svg'
import threedot from '../../images/three-dot.svg'

const Event = () => {
  const items = [
    {
      cover: './images/hotels/3.jpg',
      name: 'Gym Name',
      location: '3517 W. Gray St. Utica, ',
      rating: '4.9'
    },
    {
      cover: './images/hotels/1.jpg',
      name: 'Gym Name',
      location: '3517 W. Gray St. Utica, ',
      rating: '4.9'
    },
    {
      cover: './images/hotels/2.jpg',
      name: 'Gym Name',
      location: '3517 W. Gray St. Utica, ',
      rating: '4.9'
    },
    {
      cover: './images/hotels/3.jpg',
      name: 'Gym Name',
      location: '3517 W. Gray St. Utica, ',
      rating: '4.9'
    }
  ]
  const itemsSlide = [
    {
      img: './images/hotels/2.jpg'
    },
    {
      img: './images/hotels/3.jpg'
    },
    {
      img: './images/hotels/6.jpg'
    },
    {
      img: './images/hotels/7.jpg'
    }
  ]
  const itemsRooms = [
    {
      img: './images/rooms/5.jpg',
      name: 'Bedroom 3',
      detail: '1 sofa bed'
    },
    {
      img: './images/rooms/5.jpg',
      name: 'Bedroom 3',
      detail: '1 sofa bed'
    }
  ]
  const booking = [
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    },
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    },
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    },
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    },
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    },
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    },
    {
      profile: './images/task-profile1.png',
      name: 'Jacob Jones',
      guest: '3'
    }
  ]
  const travel = [
    {
      profile: './images/hotels/1.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/3.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/1.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/1.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/1.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/3.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/1.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    },
    {
      profile: './images/hotels/1.jpg',
      period: 'Designation',
      place: 'Jacob Jones'
    }
  ]
  const [startDate, setStartDate] = useState(new Date())

  const generateStars = (rating) => {
    const totalStars = 5
    const filledStars = Math.min(Math.floor(parseFloat(rating)), totalStars)
    const stars = []
    for (let i = 0; i < filledStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star.svg" />)
    }
    for (let i = filledStars; i < totalStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
    }
    return stars
  }

  const [visibleItems1, setVisibleItems1] = useState(6)
  const [visibleItems2, setVisibleItems2] = useState(6)
  const [visibleItems3, setVisibleItems3] = useState(1)

  const showMoreItems1 = () => {
    setVisibleItems1((prevVisibleItems) => prevVisibleItems + 3)
  }
  const showMoreItems2 = () => {
    setVisibleItems2((prevVisibleItems) => prevVisibleItems + 3)
  }
  const showMoreItems3 = () => {
    setVisibleItems3((prevVisibleItems) => prevVisibleItems + 3)
  }

  return (
    <div className="content-manager">
      <div className="content-manager-Statistics">
        <div className="content-manager-Statistics-chart">
          <div className="statistic-chart-container position-relative">
            <h4>Local Overview</h4>
            <div>
              <Tabs>
                <div>
                  <TabList>
                    <Tab>Hotels</Tab>
                    <Tab>Restaurants</Tab>
                    <Tab>Gym</Tab>
                  </TabList>
                  <div className="drop-and-date top-40">
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </div>
                </div>

                <TabPanel>
                  <OrderChart />
                </TabPanel>
                <TabPanel>
                  <ProductChart />
                </TabPanel>
                <TabPanel>
                  <CustomerChart />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <div className="offers--hotel common-card-body">
        <div className="services-container recent-video-component ">
          <h3 className="m-0 d-flex align-items-center justify-content-between">
            Hotels
            {' '}
            <Link>view all</Link>
          </h3>
        </div>
        <div className="hotel-offer--grid grid-4 mt-30">
          {items.map((item) => (
            <div className="hotel-offer-grid--item" key={item.name}>
              <div
                className="hotel-offer--cover height-184"
                style={{
                  background: `url(${item.cover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="rating">
                  <img alt="" src={RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="hotel-offer-grid--detail">
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{item.name}</h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="location d-flex align-items-center col-gap-10">
                    <img alt="" src={Location} />
                    <span>{item.location}</span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex justify-content-between mt-30">
        <div className="common-card-body local-left-sec d-flex col-gap-20">
          <div className="common-shadow w-50 d-flex flex-column justify-content-between">
            <div>
              <div className="services-container recent-video-component mb-30">
                <h3 className="m-0 d-flex align-items-center justify-content-between">
                  Members
                  <Link className="border-0">
                    <img
                      alt=""
                      src={threedot}
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  </Link>
                </h3>
              </div>
              <div className="booking--list">
                {booking.slice(0, visibleItems1).map((item) => (
                  <div className="booking-list--item" key={item.status}>
                    <div className="booking-list--profile">
                      <img alt="" src={item.profile} />
                      <ul>
                        <li className="list-profile--name">{item.name}</li>
                        <li className="number-of-guest">
                          {item.guest}
                          {' '}
                          guests
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {booking.length > visibleItems1 && (
              <div className="hotel-btn mt-40">
                <button type="button" className="gredient-txt" onClick={showMoreItems1}>
                  <span>View All</span>
                </button>
              </div>
            )}
          </div>
          <div className="common-shadow w-50 d-flex flex-column justify-content-between">
            <div>
              <div className="services-container recent-video-component mb-30">
                <h3 className="m-0 d-flex align-items-center justify-content-between">
                  Requests
                  <Link className="border-0">
                    <img
                      alt=""
                      src={threedot}
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  </Link>
                </h3>
              </div>
              <div className="travel--detail">
                {travel.slice(0, visibleItems2).map((item) => (
                  <div
                    className="travel-detail--item d-flex align-items-center justify-content-between"
                    key={item}
                  >
                    <div className="travel--profile">
                      <img alt="" src={item.profile} />
                      <ul>
                        <li className="travel-place">{item.place}</li>
                        <li className="travel-period">{item.period}</li>
                      </ul>
                    </div>
                    <div>
                      <button type="button" className="primary-btn d-block mb-1">
                        <span>Accept</span>
                      </button>
                      <button type="button" className="light-button d-block">
                        <span>Reject</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {travel.length > visibleItems2 && (
              <div className="hotel-btn">
                <button type="button" className="gredient-txt" onClick={showMoreItems2}>
                  <span>View All</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="common-card-body local-right-sec recent-video-component">
          <div className="booking-list--profile">
            <img alt="" src="./images/task-profile1.png" />
            <ul>
              <li className="list-profile--name">Jacob Jones</li>
              <li className="number-of-guest">@jacob_jones</li>
            </ul>
          </div>
          <div className="devider mb-3- mt-30" />
          <div className="hotel-swiper-slider">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="m-0">Shikara Hotel</h3>
              <div className="rating-star-container">
                {generateStars(4.5)}
                {' '}
                4.5 rating
              </div>
            </div>
            <div className="slider-for-reviews slider-local-- position-relative slider-for-local">
              <Swiper
                slidesPerView={1}
                navigation
                pagination={false}
                modules={[Navigation, Pagination]}
                className="mySwiper-story review-slider position-static"
              >
                {itemsSlide.map((item) => (
                  <SwiperSlide key={item}>
                    <div>
                      <img alt="" src={item.img} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="local-tabs">
              <Tabs>
                <TabList>
                  <Tab>Overview</Tab>
                  <Tab>Rooms</Tab>
                  <Tab>Reviews</Tab>
                </TabList>

                <TabPanel>
                  <div className="local-component">
                    <p>
                      Lorem ipsum dolor sit amet, consecte adipiscing elit.
                      Maecenas purus varius ullamcorper consequat, ullamcorper a
                      nulla enim. Fusce quis turpis pretium amet. Iaculis morbi
                      diam nunc vestibulum, nec sed. Ac accumsan nunc augue amet
                      odio et. Adipiscing vestibulum scelerisque integer ut diam
                      cras. Viverra proin ut arcu aliquam at odio orci, cras.
                      Tristique aliquam.
                    </p>
                    <h3 className="m-0 mt-30">Location</h3>
                    <div className="map mb-4 mt-20">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
                        width="100%"
                        height="197"
                        allowfullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        style={{ borderRadius: '15px' }}
                        title="Location"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="local-component">
                    <h3 className="m-0 gredient-txt">Rooms</h3>
                    {itemsRooms.slice(0, visibleItems3).map((item) => (
                      <div key={item} className="mt-3">
                        <img alt="" src={item.img} />
                        <h3 className="m-0">{item.name}</h3>
                        <span>{item.detail}</span>
                      </div>
                    ))}
                  </div>
                  {itemsRooms.length > visibleItems3 && (
                    <div className="hotel-btn">
                      <button type="button" className="gredient-txt" onClick={showMoreItems3}>
                        <span>View All</span>
                      </button>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>3</TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Event
