import React from 'react'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const numButtonsToShow = 3

  let startPage = currentPage - Math.floor(numButtonsToShow / 2)
  startPage = Math.max(startPage, 1)

  let endPage = startPage + numButtonsToShow - 1
  if (endPage > totalPages) {
    endPage = totalPages
    startPage = Math.max(endPage - numButtonsToShow + 1, 1)
  }

  const renderPaginationButtons = () => {
    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${currentPage === pageNumber ? 'active' : ''}`}
          onClick={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  return (
    <div className="pagination-container">
      <button
        type="button"
        className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Prev
      </button>
      {renderPaginationButtons()}
      <button
        type="button"
        className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  )
}

export default Pagination
