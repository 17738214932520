import React from 'react'
import './slider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

const FitnessExpertSlider = () => {
  const items = [
    {
      heading: 'Your Own Fitness Expert',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim morbi eu elementum pellentesque arcu. Suscipit id arcu scelerisque non egestas leo risus viverra. Pretium, dictum eget nulla volutpat.',
      img: './images/traning/1.jpg'
    },
    {
      heading: 'Your Own Fitness Expert',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim morbi eu elementum pellentesque arcu. Suscipit id arcu scelerisque non egestas leo risus viverra. Pretium, dictum eget nulla volutpat.',
      img: './images/traning/1.jpg'
    }
  ]
  return (
    <div className="fitness-expert-slider position-relative">
      <Swiper
        slidesPerView={1}
        navigation
        modules={[Navigation]}
        className="mySwiper-story fitness-expert position-static"
      >
        {items.map((item) => (
          <SwiperSlide key={item.heading}>
            <div className="fitness-expert-item">
              <div className="m-auto">
                <h5>{item.heading}</h5>
                <p>{item.text}</p>
                <button type="button" className="light-button">
                  <span>Make an Appointment</span>
                </button>
              </div>
              <div className="text-center">
                <img alt="" src={item.img} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default FitnessExpertSlider
