import React, { useState } from 'react'
import Delete from '../../images/delete.svg'
import Add from '../../images/Plus-tag.svg'
import DownIco from '../../images/DownIco.svg'
import SearchIco from '../../images/SearchIco.svg'

const Clients = () => {
  const items = [
    {
      image: './images/cities/berlin.png',
      name: 'Berlin'
    },
    {
      image: './images/cities/hamburg.png',
      name: 'Hamburg'
    },
    {
      image: './images/cities/munich.png',
      name: 'Munich'
    },
    {
      image: './images/cities/cologne.png',
      name: 'Cologne'
    },
    {
      image: './images/cities/cologne.png',
      name: 'Cologne'
    },
    {
      image: './images/cities/islamabad.png',
      name: 'Islamabad'
    },
    {
      image: './images/cities/lisbon.png',
      name: 'Lisbon'
    },
    {
      image: './images/cities/madrid.png',
      name: 'Madrid'
    },
    {
      image: './images/cities/capetown.png',
      name: 'Cape Town'
    },
    {
      image: './images/cities/geneva.png',
      name: 'Geneva'
    },
    {
      image: './images/cities/berlin.png',
      name: 'Berlin'
    },
    {
      image: './images/cities/berlin.png',
      name: 'Berlin'
    },
    {
      image: './images/cities/berlin.png',
      name: 'Berlin'
    },
    {
      image: './images/cities/berlin.png',
      name: 'Berlin'
    },
    {
      image: './images/cities/berlin.png',
      name: 'Berlin'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(4)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  return (
    <div className="cities-admin">
      <div className="">
        <h3>Clients</h3>
        <div>
          <button type="button" className="light-button">
            <span>
              <img alt="" src={Delete} />
              {' '}
              Remove Selected
            </span>
          </button>
          <button type="button" className="primary-btn">
            <span>
              <img alt="" src={Add} />
              {' '}
              Add Client
            </span>
          </button>
        </div>
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
        imperdiet pharetra, tempus, sollicitudin lectus. Proin quam pellentesque
        id mus gravida ultricies cursus.
      </p>
      <div className="serch-input position-relative">
        <input placeholder="Search Cities..." />
        <div className="serch-icon">
          <img alt="" src={SearchIco} />
        </div>
      </div>
      <div className="cities-grid">
        {items.slice(0, visibleItems).map((item) => (
          <div className="cities-item" key={item}>
            <img alt="" src={item.image} />
            <h2>{item.name}</h2>
            <input type="checkbox" />
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img alt="" src={DownIco} />
          </button>
        </div>
      )}
    </div>
  )
}
export default Clients
