import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from '../sidebar/SidebarBuilder'
import ProfileHeadCreator from '../ProfileBuilder/ProfileHeadCreator'
import ProfileHeadStore from '../ProfileBuilder/ProfileHeadStore'

const LayoutBuilder = () => {
  const location = useLocation()
  return (
    <>
      <div className="sidebar-wrapper">
        <Sidebar />
      </div>
      <div className="creator-profile-main creator-profile-create pb-0">
        {(location.pathname.includes('profile-builder') || location.pathname.includes('community-builder')) && (
        <ProfileHeadCreator />
        )}
        {location.pathname.includes('store-builder') && (
          <ProfileHeadStore />
        )}
      </div>
      <Outlet />
    </>
  )
}
export default LayoutBuilder
