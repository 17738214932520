import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import RatingStar from '../../images/rating-start.svg'
import Location from '../../images/Locations.svg'
import Parking from '../../images/parking.svg'
import Wifi from '../../images/wifi-linier.svg'
import Tv from '../../images/television.svg'
import RoomService from '../../images/room-service-linear.svg'
import Library from '../../images/library.svg'
import Breakfast from '../../images/breakfast.svg'
import ShowIco from '../../images/ShowIco.svg'

const HotelDetail = () => {
  const items = [
    {
      images: './images/rooms/5.jpg',
      name: 'Bedroom 3',
      about: '1 sofa bed'
    },
    {
      images: './images/rooms/6.jpg',
      name: 'Bedroom 4',
      about: '2 single beds'
    },
    {
      images: './images/rooms/7.jpg',
      name: 'Bedroom 4',
      about: '2 single beds'
    }
  ]
  const itemsReview = [
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    }
  ]
  return (
    <div className="creator-profile-main">
      <div className="common-card-body hotel-resrve">
        <div className="hotel-reserve-detail d-flex justify-content-between">
          <div className="hotel-reserve-detail--header">
            <h4>Four Seasons Hotel Gresham Palace Suites</h4>
            <p>
              2nd street, clubs road
              <div className="rating">
                <img alt="" src={RatingStar} />
                <span>4.9</span>
              </div>
            </p>
          </div>
          <div className="d-flex align-items-center col-gap-10 common-contnt-wrapper">
            <img alt="" src={Location} />
            <Link>View map</Link>
          </div>
        </div>
        <div className="hotel-reserve-form">
          <div
            className="position-relative hotel-reserve-form--photos"
            style={{
              background: 'url(./images/bookig-bg.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <button type="button" className="show-photos">
              <img alt="" src={ShowIco} />
              <span>Show all photos</span>
            </button>
          </div>
          <div className="hotel-reserve--form">
            <form>
              <h6>
                €500
                {' '}
                <span>night</span>
              </h6>
              <p className="m-0">
                Lorem ipsum dolor sit amet, consectetu adipis elit. Non turpis
                egestas nunc, faucibus enim ac tellus lacus.
              </p>
              <div className="input-label">
                <label>Check In</label>
                <input placeholder="User name" />
              </div>
              <div className="input-label">
                <label>Check Out</label>
                <input placeholder="User name" />
              </div>
              <div className="input-label">
                <label>Add guests</label>
                <input placeholder="Enter your message here..." />
              </div>
              <button type="button" className="primary-btn w-100">Reserve</button>
            </form>
          </div>
        </div>
      </div>
      <div className="discription-box recent-video-component mb-0 mt-40">
        <h3>Description</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin iaculis
          morbi tempus volutpat tortor. Ipsum vitae enim sit suscipit in
          ridiculus. Morbi potenti eu facilisis vitae posuere suspendisse.
          Praesent sit lorem amet posuere vel diam scelerisque a. Nisl imperdiet
          egestas dui consequat, diam et, at tempor.
        </p>
      </div>
      <div className="common-card-body recent-video-component place-offer mt-40">
        <h3>What this place offers</h3>
        <ul>
          <li>
            <img alt="" src={Parking} />
            Parking
          </li>
          <li>
            <img alt="" src={Wifi} />
            Free Wifi
          </li>
          <li>
            <img alt="" src={Tv} />
            TV
          </li>
          <li>
            <img alt="" src={RoomService} />
            Room Service
          </li>
          <li>
            <img alt="" src={Library} />
            Library
          </li>
          <li>
            <img alt="" src={Breakfast} />
            Breakfast
          </li>
        </ul>
      </div>
      <div className="common-card-body mt-40">
        <div className="common-contnt-wrapper">
          <h2>Rooms</h2>
          <Link>See All</Link>
        </div>
        <div className="hotel-resrve--room">
          {items.map((item) => (
            <div className="hotel-reserve-room--item" key={item.name}>
              <div className="hotel-reserve-room--img">
                <img alt="" src={item.images} />
              </div>
              <div className="hotel-reserve-room--detail">
                <h5>{item.name}</h5>
                <p>{item.about}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="services-container common-card-body recent-video-component position-relative mt-40">
        <h3>Room Photos</h3>
        <div className="grid-gellery">
          <div className="gellery-item grid-1">
            <img alt="" src="./images/gellery/1.jpg" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img alt="" src="./images/gellery/2.jpg" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img alt="" src="./images/gellery/3.jpg" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img alt="" src="./images/gellery/4.jpg" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img alt="" src="./images/gellery/5.jpg" />
            <div className="overlay-gredient" />
          </div>
        </div>
      </div>
      <div className="common-card-body mt-40 recent-video-component">
        <h3>Reviews</h3>
        <div className="hotel-reserve--review">
          {itemsReview.map((item) => (
            <div className="hotel-reserve-review--item" key={item.name}>
              <p>{item.review}</p>
              <div className="devider" />
              <div className="hotel-reserve-review--detail d-flex align-items-center col-gap-20">
                <img alt="" src={item.image} />
                <div>
                  <p>{item.name}</p>
                  <span>{item.date}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default HotelDetail
