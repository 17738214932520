import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Message from '../../images/Message.svg'
import Notification from '../../images/Notification.svg'
import './header.css'
import { s3ImagePath } from '../../services/Service'
import SearchIco from '../../images/SearchIco.svg'

const Header = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  return (
    <div className="navbar-custom d-flex align-items-center justify-content-between">
      {isLoading ? (
        <>
          <div className="serch-input position-relative">
            <Skeleton className="rounded-3" width={329} height={39} />
          </div>
          <div className="header-right-bar d-flex align-items-center">
            <button type="button" className="right-button">
              <Skeleton width={25} height={25} />
            </button>
            <button type="button" className="right-button">
              <Skeleton width={25} height={25} />
            </button>
            <div className="profile-dropdown d-flex gap-2">
              <Skeleton className="rounded-3" width={30} height={30} />
              <Skeleton className="mt-2" width={15} height={15} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="serch-input position-relative">
            <input disabled placeholder="Search..." />
            <div className="serch-icon">
              <img src={SearchIco} alt="" />
            </div>
          </div>
          <div className="header-right-bar d-flex align-items-center">
            <button type="button" className="right-button">
              <img src={Message} alt="" />
            </button>
            <button type="button" className="right-button">
              <img src={Notification} alt="" />
            </button>
            <div className="profile-dropdown">
              <button
                className="nav-link dropdown-toggles"
                id="navbarDropdown"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={s3ImagePath + userData.profile_image} alt="" />
              </button>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item">Action</Link>
                <Link className="dropdown-item">Another action</Link>
                <div className="dropdown-divider" />
                <Link className="dropdown-item">Something else here</Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default Header
