import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SideBarPage from './SidebarPage'
import eyeoff from '../../images/eye-off.svg'
import eyeon from '../../images/eye-on.svg'
import StarIco from '../../images/StarIco.svg'

const ReLogin = () => {
  const [showPassword1, setShowPassword1] = useState(false)

  const divStyle = {
    background: 'url("./images/profile.png") lightgray 50% / cover no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 overflow-y-auto">
          <div className="inline-padding mt-xl-5 pt-5">
            <h2 className="mb-5 mt-xl-5 text-center">HELLO, TORBEN!</h2>
            <div className="login-img" style={divStyle} />
            <div className="profile-full-name text-center">
              <p className="m-0 clip-text">
                TORBEN SCHÜTT
                <span>
                  <img src={StarIco} alt="" />
                </span>
              </p>
              <Link className="clip-text">Switch Profile</Link>
            </div>
            <form action="/dashboard">
              <p>
                <label className="clip-text">Re-enter Password *</label>
                <div className="password-input">
                  <input
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder="*********************"
                    required
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={eyeon} />
                    ) : (
                      <img alt="" src={eyeoff} />
                    )}
                  </button>
                </div>
              </p>
              <p className="mt-5">
                <button
                  id="sub_btn"
                  className="primary-button m-auto"
                  type="submit"
                >
                  <span>Log in</span>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ReLogin
