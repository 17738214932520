import React, { useEffect, useRef, useState } from 'react'
import '../AddAwards/AddAwards.css'
import ReactDatePicker from 'react-datepicker'
import Plus from '../../images/plus-rounded.svg'
import UploadImg from '../../images/upload.svg'
import './style.css'

const AddEvents = () => {
  const [ShowEventModel, setShowEventModel] = useState(false)
  const AddEvent = () => {
    setShowEventModel(true)
  }
  const HandleCancel = () => {
    setShowEventModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowEventModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [startDate, setStartDate] = useState(new Date())
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Events</h2>
        </div>
        <div className="awards-row">
          <div className="add-local-events-item">
            <div className="position-relative add-local-event-top">
              <button type="button" className="add-item-btn" onClick={AddEvent}>
                <img alt="" src={Plus} />
              </button>
              <p className="people-block" />
              <p className="date-block" />
              <div className="detail-block">
                <h4 className="add-value">.</h4>
                <h5 className="add-value">.</h5>
                <h6 className="add-value">.</h6>
              </div>
            </div>
            <div className="add-event-detail">
              <p className="add-value" />
              <span className="add-value" />
            </div>
          </div>
        </div>
      </div>
      {ShowEventModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={UploadImg} />
                  </div>
                  <h4>Upload a Photo</h4>
                  <p>
                    Drag and drop your file or click the button below to upload
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>Upload Photo</span>
                  </button>
                </label>
              </div>
              <div>
                <label>Tagline</label>
                <input placeholder="Tagline" />
              </div>
              <div>
                <label>Title</label>
                <input placeholder="Title" />
              </div>
              <div>
                <label>Sub title</label>
                <input placeholder="Sub title" />
              </div>
              <div>
                <label>Price</label>
                <input placeholder="Enter Price" />
              </div>
              <div className="celender-input">
                <label>Date</label>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                <label>Decription</label>
                <textarea placeholder="Type here" />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button type="button" className="secondary-btn-model">Add</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddEvents
