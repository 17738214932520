import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import DatePicker from 'react-datepicker'
import Skeleton from 'react-loading-skeleton'
import Activity from './Activity'
import Campaigns from './Campaings'
import LetestContent from './LetestContent'
import Insights from './Insights'
import BlueCard from '../../images/blue-card.png'
import CircleLogo from '../../images/gbn-one.svg'
import { s3ImagePath } from '../../services/Service'
import VisitorIco from '../../images/VisitorIco.svg'
import OrderIco from '../../images/OrderIco.svg'
import RateIco from '../../images/RateIco.svg'
import RevenueIco from '../../images/RevenueIco.svg'
import VisitorIndicatorIco from '../../images/VisitorIndicatorIco.svg'
import OrderIndicatorIco from '../../images/OrderIndicatorIco.svg'
import RateIndicatorIco from '../../images/RateIndicatorIco.svg'
import RevenueIndicatorIco from '../../images/RevenueIndicatorIco.svg'
import InsightIco from '../../images/InsightIco.svg'
import ContentIco from '../../images/ContentIco.svg'
import CampaignIco from '../../images/CampaignIco.svg'
import ActivityIco from '../../images/ActivityIco.svg'
import FiveStarIco from '../../images/FiveStarIco.svg'
// import DownIco from '../../images/DownIco.svg'
import FollowUser from '../../images/3User.svg'
import Creators from '../../images/Creators.svg'

import 'react-datepicker/dist/react-datepicker.css'
import MainCart from './MainChart'

const Dashboard = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  // const [visibleTasks, setVisibleTasks] = useState(6)

  // const handleShowMoreTask = () => {
  //   setVisibleTasks((prevVisibleTasks) => prevVisibleTasks + 3)
  // }

  const [startDate, setStartDate] = useState(new Date())
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  return (
    <div className="dashboard">
      <div className="dashboard-wrapper">
        <div className="dashboard-main">
          <div className="dashboard-left">
            <div className="dashbord-left-wrapper">
              <div className="counter-main">
                {loading ? (
                  Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <div key={index} className="daily-visitors counter-item">
                        <Skeleton width={25} height={25} />
                        <p>
                          <Skeleton width={100} />
                        </p>
                        <p className="counter">
                          <Skeleton width={15} />
                          <span className="gap-2" style={{ color: '#FE7354' }}>
                            <Skeleton width={10} />
                            <Skeleton width={15} />
                          </span>
                        </p>
                      </div>
                    ))
                ) : (
                  <>
                    <div className="daily-visitors counter-item">
                      <img src={VisitorIco} alt="" />
                      <p>Daily Visitors</p>
                      <p className="counter">
                        0
                        <span style={{ color: '#FE7354' }}>
                          <img src={VisitorIndicatorIco} alt="" />
                          0%
                        </span>
                      </p>
                    </div>
                    <div className="total-orders counter-item">
                      <img src={OrderIco} alt="" />
                      <p>Daily Visitors</p>
                      <p className="counter">
                        0
                        <span style={{ color: '#00D1BB' }}>
                          <img src={OrderIndicatorIco} alt="" />
                          0%
                        </span>
                      </p>
                    </div>
                    <div className="conversion-rate counter-item">
                      <img src={RateIco} alt="" />
                      <p>Conversion Rate</p>
                      <p className="counter">
                        0
                        <span style={{ color: '#F400A7' }}>
                          <img src={RateIndicatorIco} alt="" />
                          0%
                        </span>
                      </p>
                    </div>
                    <div className="total-revenue counter-item">
                      <img src={RevenueIco} alt="" />
                      <p>Total revenue made</p>
                      <p className="counter">
                        0
                        <span style={{ color: '#001C93' }}>
                          <img src={RevenueIndicatorIco} alt="" />
                          0%
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>

              <div className="chart">
                <MainCart />
              </div>
              <div className="tab-dashboard">
                <Tabs>
                  <TabList>
                    <Tab disabled>
                      <img src={ActivityIco} alt="" />
                      Activity
                    </Tab>
                    <Tab disabled>
                      <img src={CampaignIco} alt="" />
                      Campaigns
                    </Tab>
                    <Tab disabled>
                      <img src={ContentIco} alt="" />
                      Latest Content
                    </Tab>
                    <Tab disabled>
                      <img src={InsightIco} alt="" />
                      Insights
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <Activity />
                  </TabPanel>
                  <TabPanel>
                    <Campaigns />
                  </TabPanel>
                  <TabPanel>
                    <LetestContent />
                  </TabPanel>
                  <TabPanel>
                    <Insights />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="dashboard-right">
            {loading ? (
              <div className="gbn-business-profile">
                <div className="business-profile-logo d-flex">
                  <Skeleton circle width={65} height={65} />
                  <span className="align-self-center p-0">
                    <Skeleton width={100} />
                  </span>
                </div>
                <div className="text-center">
                  <Skeleton
                    className="shots-profile m-auto"
                    circle
                    width={85}
                    height={85}
                  />
                  <h3>
                    <Skeleton width={100} />
                  </h3>
                  <span className="profile-detail">
                    <Skeleton width={100} />
                  </span>
                  <div className="profile-rating">
                    <Skeleton width={70} />
                  </div>
                  <div className="follower-creator">
                    <ul>
                      <li>
                        <Skeleton circle width={30} height={30} />
                        <Skeleton width={10} />
                      </li>
                      <li>
                        <Skeleton circle width={30} height={30} />
                        <Skeleton width={10} />
                      </li>
                    </ul>
                  </div>
                  <div className="blue-card-detail">
                    <Skeleton width={80} height={50} />
                    <span className="name">
                      <Skeleton width={70} height={30} />
                    </span>
                    <span className="credit">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="gbn-business-profile">
                <div className="business-profile-logo">
                  <img src={CircleLogo} alt="" />
                  <span>Business Profile</span>
                </div>
                <div className="text-center">
                  <div
                    className="profile-img"
                    style={{
                      background: `url(${s3ImagePath}${userData.profile_image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <h3>
                    {userData.full_name}
                    {' '}
                    <span className="status" />
                  </h3>
                  <span className="profile-detail">{userData.user_name}</span>
                  <div className="profile-rating">
                    <img src={FiveStarIco} alt="" />
                  </div>
                  <div className="follower-creator">
                    <ul>
                      <li>
                        <img src={FollowUser} alt="" />
                        0
                      </li>
                      <li>
                        <img src={Creators} alt="" />
                        0
                      </li>
                    </ul>
                  </div>
                  <div className="blue-card-detail">
                    <img src={BlueCard} alt="" />
                    <span className="name">{userData.full_name}</span>
                    <span className="credit">€00.00</span>
                  </div>
                </div>
              </div>
            )}

            {loading ? (
              <div className="tasks">
                <div className="tasks-head">
                  <h3>
                    <Skeleton width={50} />
                  </h3>
                  <span>
                    <Skeleton width={150} />
                  </span>
                </div>
                <div className="tasks-celender text-center">
                  <div className="d-flex gap-4 mb-3 justify-content-center">
                    <Skeleton width={25} height={25} />
                    <Skeleton width={100} height={20} />
                    <Skeleton width={25} height={25} />
                  </div>
                  {Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <div className="d-flex gap-3 mb-2  justify-content-center" key={index}>
                        <Skeleton width={25} height={25} />
                        <Skeleton width={25} height={25} />
                        <Skeleton width={25} height={25} />
                        <Skeleton width={25} height={25} />
                        <Skeleton width={25} height={25} />
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="tasks">
                <div className="tasks-head">
                  <h3>Tasks</h3>
                  <span>
                    {startDate.toLocaleDateString([], {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </span>
                </div>
                <div className="tasks-celender text-center">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    inline
                  />
                </div>
                <div className="tasls-list">
                  {/* {visibleTasks >= 1 && (
                  <div className="tasks-list-item">
                    <div
                      className="tasks-profile"
                      style={{
                        background: 'url("./images/task-profile1.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="about-tasks">
                      <h5>Darrell Steward</h5>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        adipiscing.
                      </p>
                      <ul>
                        <li>
                          Tasks
                          {' '}
                          <span>. 2</span>
                        </li>
                        <li>
                          Hours
                          {' '}
                          <span>. 4</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {visibleTasks >= 2 && (
                  <div className="tasks-list-item">
                    <div
                      className="tasks-profile"
                      style={{
                        background: 'url("./images/task-profile2.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="about-tasks">
                      <h5>Robert Fox</h5>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        adipiscing.
                      </p>
                      <ul>
                        <li>
                          Tasks
                          {' '}
                          <span>. 2</span>
                        </li>
                        <li>
                          Hours
                          {' '}
                          <span>. 4</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {visibleTasks >= 3 && (
                  <div className="tasks-list-item">
                    <div
                      className="tasks-profile"
                      style={{
                        background: 'url("./images/task-profile3.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="about-tasks">
                      <h5>Jane Cooper</h5>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        adipiscing.
                      </p>
                      <ul>
                        <li>
                          Tasks
                          {' '}
                          <span>. 2</span>
                        </li>
                        <li>
                          Hours
                          {' '}
                          <span>. 4</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {visibleTasks >= 4 && (
                  <div className="tasks-list-item">
                    <div
                      className="tasks-profile"
                      style={{
                        background: 'url("./images/task-profile4.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="about-tasks">
                      <h5>Cameron Williamson</h5>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        adipiscing.
                      </p>
                      <ul>
                        <li>
                          Tasks
                          {' '}
                          <span>. 2</span>
                        </li>
                        <li>
                          Hours
                          {' '}
                          <span>. 4</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {visibleTasks >= 5 && (
                  <div className="tasks-list-item">
                    <div
                      className="tasks-profile"
                      style={{
                        background: 'url("./images/task-profile4.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="about-tasks">
                      <h5>Cameron Williamson</h5>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        adipiscing.
                      </p>
                      <ul>
                        <li>
                          Tasks
                          {' '}
                          <span>. 2</span>
                        </li>
                        <li>
                          Hours
                          {' '}
                          <span>. 4</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {visibleTasks >= 6 && (
                  <div className="tasks-list-item">
                    <div
                      className="tasks-profile"
                      style={{
                        background: 'url("./images/task-profile4.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="about-tasks">
                      <h5>Cameron Williamson</h5>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        adipiscing.
                      </p>
                      <ul>
                        <li>
                          Tasks
                          {' '}
                          <span>. 2</span>
                        </li>
                        <li>
                          Hours
                          {' '}
                          <span>. 4</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )} */}
                  {/* <div className="collapse-button text-center mt-3">
                  <button type="button" onClick={handleShowMoreTask}>
                    <img src={DownIco} alt="" />
                  </button>
                </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
