import React from 'react'
import './Feeds.css'
import { Link } from 'react-router-dom'
import DropLink from '../../images/three-dot.svg'
import Play from '../../images/play.svg'
import Like from '../../images/group-like.svg'
import Comment from '../../images/group-comment.svg'
import Share from '../../images/group-share.svg'
import Save from '../../images/group-save.svg'

const AddFeeds = () => {
  const items = [
    {
      profile: './images/podcast.png',
      name: 'Bessie Cooper',
      designation: 'Ernahrungsberater',
      video: '',
      cover: './images/feeds/1.jpg',
      feed: 'Fitness Diet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus platea duis ac, lorem. Id viverra egestas.'
    },
    {
      profile: './images/award-second-profile.png',
      name: 'Ronald Richards',
      designation: 'Ernahrungsberater',
      video: '',
      cover: './images/feeds/2.jpg',
      feed: 'Fitness',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus platea duis ac, lorem. Id viverra egestas.'
    }
  ]
  return (
    <div className="feeds-main">
      {items.map((item) => (
        <div className="feeds-item common-card-body mt-30" key={item.feed}>
          <div className="feeds-profile">
            <div className="feeds-profile-main">
              <img src={item.profile} alt="" />
              <ul>
                <li className="name">{item.name}</li>
                <li className="designation">{item.designation}</li>
              </ul>
            </div>
            <Link>
              <img src={DropLink} alt="" />
            </Link>
          </div>
          <div
            className="feeds-video"
            style={{
              background: `url(${item.cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="play">
              <img src={Play} alt="" />
            </div>
          </div>
          <div className="feeds-detail">
            <h6>{item.feed}</h6>
            <p>{item.text}</p>
          </div>
          <div className="feeds-button">
            <div className="feed-btn-group">
              <button type="button">
                <img src={Like} alt="" />
                Like
              </button>
              <button type="button">
                <img src={Comment} alt="" />
                Comment
              </button>
              <button type="button">
                <img src={Share} alt="" />
                Share
              </button>
            </div>
            <div className="save-btn">
              <button type="button">
                <img src={Save} alt="" />
                Save
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default AddFeeds
