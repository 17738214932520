/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ProgressBar from '@ramonak/react-progress-bar'
import Skeleton from 'react-loading-skeleton'
import Comment from '../../images/ls_comments.svg'
import IconsMore from '../../images/akar-icons_more-horizontal-fill.svg'
import GroupDiscussion from '../../images/Group-discussion.svg'
import {
  getPollsLibrary,
  s3ImagePath,
  voterListData
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import CloseIco from '../../images/CloseIco.svg'
import Pagination from '../Pagination/Pagination'
import Plus from '../../images/plus-rounded.svg'

const PollsDetails = () => {
  const navigate = useNavigate()
  const componentRef = useRef()
  const [voteList, setVoteList] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pollsDetail, setPollsDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 2 } })
  }

  const getPollLibraryData = () => {
    setIsLoading(true)
    getPollsLibrary({}).then((res) => {
      if (res?.data?.success) {
        setPollsDetail(dataDecrypt(res?.data?.data).data)
        const { data } = dataDecrypt(res?.data?.data)
        // if (data.length !== 0) {
        //   const poll_id = data.map((poll) => poll.poll_id)
        //   voterList(poll_id)
        // }
        // data.forEach((poll) => {
        //   voterList(poll.poll_id, poll)
        // })
        if (data.length !== 0) {
          voterList(data[0].poll_id)
        }
        setIsLoading(false)
      }
    })
  }

  const voterList = async (pollIds) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData?.id,
      poll_ids: pollIds
    }

    try {
      const res = await voterListData(platFormData)
      if (res.data.success) {
        setVoteList(dataDecrypt(res.data.data).data)
      }
    } catch (error) {
      console.error('Error fetching voter list data:', error)
    }
  }

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const voteListData = voteList.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = voteList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const showList = () => {
    setShowProfileModel(true)
  }
  const close = () => {
    setShowProfileModel(false)
  }

  useEffect(() => {
    getPollLibraryData()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowProfileModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  return (
    <div className="content-manager-Polls-wrapper common-card-body community-margin">
      <div className="polls-wrapper-header common-contnt-wrapper">
        <h2>Polls</h2>
        <div className="" onClick={viewAll}>
          <Link>View All</Link>
        </div>
      </div>
      {pollsDetail.length === 0 && !isLoading ? (
        <div className="add-polls-container mb-3">
          <div className="add-polls-item common-card-body">
            <div className="position-relative polls-item-head">
              <h6 className="add-value font-hidden">Heading 2</h6>
              <button
                type="button"
                className="add-item-btn"
              >
                <img src={Plus} alt="add poll" />
              </button>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 1</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 1</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 2</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 2</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 3</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 3</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 4</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 4</h5>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="polls-item common-card-body position-relative">
          <div className="">
            <Skeleton width={50} height={25} />
          </div>
          <div className="poll-header">
            <p className="poll_topic">
              <Skeleton width={600} height={30} />
            </p>
            <div className="discussion-group">
              <Skeleton width={25} height={25} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>

          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
        </div>
      ) : (
        pollsDetail.slice(0, 1).map((item) => {
          const totalVotes =
            item.option_1_vote +
            item.option_2_vote +
            item.option_3_vote +
            item.option_4_vote

          const optionPercentages = [
            ((item.option_1_vote / totalVotes) * 100).toFixed(2),
            ((item.option_2_vote / totalVotes) * 100).toFixed(2),
            ((item.option_3_vote / totalVotes) * 100).toFixed(2),
            ((item.option_4_vote / totalVotes) * 100).toFixed(2)
          ]

          return (
            <div
              className="polls-item common-card-body position-relative"
              key={item.tag}
            >
              <div className="polls-tag">
                <span>
                  {/* {item.tag} */}
                  Web Design
                </span>
              </div>
              <div className="poll-header">
                <p className="poll_topic">{item.topic}</p>
                <div className="discussion-group">
                  {item?.total_votes > 0 &&
                    voteList
                      .slice(0, 3)
                      .map((vote, index) => (
                        <img
                          key={index}
                          src={s3ImagePath + vote.profile_icon}
                          alt={vote.user_name}
                        />
                      ))}
                  <img
                    src={GroupDiscussion}
                    alt=""
                    onClick={item.total_votes > 0 ? showList : undefined}
                  />
                  {ShowProfileModel && (
                    <div className="add-item-model edit-profile">
                      <div className="add-item-content" ref={componentRef}>
                        <div className="d-flex justify-content-between header">
                          <h3>Voting List</h3>
                          <button
                            type="button"
                            className="border-0 bg-none p-0"
                            onClick={close}
                          >
                            <span>
                              <img
                                className="close-btns"
                                src={CloseIco}
                                alt=""
                              />
                            </span>
                          </button>
                        </div>

                        <div className="scroll-bars">
                          <div className="item-details-input pt-0">
                            <div className="list_data">
                              {voteListData.map((list, index) => {
                                const calculatedIndex =
                                  (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImagePath + list.profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {list.user_name}
                                    </span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        {totalPages > 1 && (
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="discussion-comment">
                  <img src={Comment} alt="" />
                  {item.total_votes}
                </div>
                <div className="discussion-button">
                  <button type="button">
                    <img src={IconsMore} alt="" />
                  </button>
                </div>
              </div>

              {[item.option_1, item.option_2, item.option_3, item.option_4]
                .filter((option) => option !== '')
                .map((option, optIndex) => (
                  <div className="polls-option" key={optIndex}>
                    <ProgressBar
                      customLabel={`${option}`}
                      className="profress-line"
                      baseBgColor="linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)"
                      height={43}
                      completed={optionPercentages[optIndex]}
                      borderRadius={15}
                      bgColor={
                        item.total_votes > 0
                          ? 'linear-gradient(134deg, rgba(0, 21, 151, 1) 3.03%, rgba(1, 16, 77, 1) 47.1%, rgba(2, 12, 28, 1) 80.14%, rgba(2, 11, 9, 1) 96.45%)'
                          : 'linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)'
                      }
                      isLabelVisible={false}
                      labelClassName={
                        item[`option_${optIndex + 1}_vote`] > 0
                          ? 'active_label'
                          : 'polls-label'
                      }
                    />

                    <div className="polls-group">
                      <div className="discussion-group">
                        {item?.total_votes > 0 &&
                          voteList
                            .filter((vote) => vote.option === optIndex + 1)
                            .slice(0, 3)
                            .map((vote, index) => (
                              <img
                                key={index}
                                src={s3ImagePath + vote.profile_icon}
                                alt={vote.user_name}
                              />
                            ))}
                        <img src={GroupDiscussion} alt="Group Discussion" />
                      </div>
                      {item[`option_${optIndex + 1}_vote`]}
                      {' '}
                      votes
                    </div>
                  </div>
                ))}
              {item.app_data.length > 0 && (
                <span
                  className={`platform-badge ${
                    item.app_data.length === 2 ? 'platform-badge-alt' : ''
                  }`}
                >
                  {item.app_data.map((data, i) => (
                    <div
                      key={i}
                      className={`badge-cover ${
                        item.app_data.length === 2 ? 'badge-cover-alt' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${s3ImagePath + data.icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '90%'
                      }}
                    />
                  ))}
                </span>
              )}
            </div>
          )
        })
      )}
    </div>
  )
}

export default PollsDetails
