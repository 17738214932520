import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import './Stories.css'
import { toast } from 'react-toastify'
import {
  getStories,
  deleteStories,
  s3ImagePathCreator
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'

const AddStories = () => {
  const [storyId, setStoryId] = useState()
  const [stories, setStories] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const componentRef = useRef()
  const storiesGet = () => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id
    }
    getStories(platFormData).then((res) => {
      if (res.data.success) {
        const data = dataDecrypt(res.data.data)
        setStories(data)
      }
    })
  }

  useEffect(() => {
    storiesGet()
  }, [])

  const ShowConfirm = (story_id) => {
    setStoryId(story_id)
    setShowConfirmModel(true)
  }

  const deleteStory = () => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const data = {
      app_id: parsedData.id,
      story_id: storyId
    }
    deleteStories(data).then((res) => {
      if (res.data.success) {
        setStories((stories) => stories.filter((data) => data.story_id === storyId))
        toast.success(res.data.message)
        setShowConfirmModel(false)
        setStoryId()
      }
    })
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setStoryId()
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowConfirmModel(false)
        setStoryId()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="stories-component add-stories common-card-body">
      <h3>Stories</h3>
      <div className="story-card">
        {stories.length === 0 ? (
          <div className="story-item">
            <h5 className="story_text">No Story Available</h5>
          </div>
        ) : (
          <div className="position-relative">
            <Swiper
              slidesPerView="auto"
              spaceBetween={30}
              navigation
              modules={[Navigation]}
              className="mySwiper-story position-static pt-3"
            >
              {stories.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="slider-story position-relative">
                    <div
                      className="story-slider-item"
                      style={{
                    backgroundImage: `url(${
                      s3ImagePathCreator + item.attachment
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                    />
                    <button
                      type="button"
                      onClick={() => ShowConfirm(item.story_id)}
                      className="delete-btn"
                    />
                  </div>
                </SwiperSlide>
          ))}
            </Swiper>
          </div>
      )}
      </div>
      {ShowConfirmModel && (
        <div className="add-item-model">
          <div className="add-item-contents h-auto w-auto" ref={componentRef}>
            <div className="item-details-input pt-0">
              <h3 className="mb-0 text-center">Do you want to delete this?</h3>
              <div className="button-row justify-content-center">
                <button
                  type="button"
                  className="secondary-btn-modelConfirm"
                  onClick={HandleCancelConfirm}
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="primary-btn-model"
                  onClick={() => {
                    deleteStory()
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddStories
