import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { NavLink, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import GbnLogo from '../../images/gbn-one.svg'
import Home from '../../images/home.svg'
import Profile from '../../images/profile.svg'
import Workspace from '../../images/workspace.svg'
import ContentManager from '../../images/contentmanager.svg'
import MyStore from '../../images/mystore.svg'
import Local from '../../images/local.svg'
import CloudStore from '../../images/cloudstore.svg'
import ADS from '../../images/ads.svg'
import ServiceCenter from '../../images/servicecenter.svg'
import Settings from '../../images/settings.svg'
import Logout from '../../images/logout.svg'
import Affiliate from '../../images/Affiliate.svg'
// import RightIco from "../../images/RightIco.svg";
import RightAerrow from '../../images/Vector.svg'
import Dropdown from '../Dropdown/Dropdown'
import { getAppAddons } from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'

const SidebarBuilder = () => {
  const params = useParams()

  const [inputFields, setInputFields] = useState({
    id: '',
    name: ''
  })

  const LogoutClick = () => {
    localStorage.clear()
    toast.success('User logged out successfully')
  }

  const [userTypesData, setUserTypesData] = useState([])
  const [addOns, setAddOns] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getPlatformData = async () => {
    setIsLoading(true)
    await getAppAddons().then((res) => {
      if (res.data.success) {
        const DecodedData = dataDecrypt(res.data.data)
        setUserTypesData(DecodedData)
        const app_id = localStorage.getItem('app_id')
        const parsedData = JSON.parse(app_id)
        const FinalData = DecodedData.find((d) => d.id === parsedData.id)
        localStorage.setItem('app_id', JSON.stringify(dataDecrypt(FinalData)))
        setAddOns(FinalData.add_ons_data)
        setInputFields({
          ...inputFields,
          id: FinalData.id,
          name: FinalData.name
        })
        setIsLoading(false)
      }
    })
  }

  const changeAddOns = (id) => {
    setAddOns(userTypesData.filter((item) => item.id === id)[0].add_ons_data)
    localStorage.setItem(
      'app_id',
      JSON.stringify(userTypesData.filter((item) => item.id === id)[0])
    )
    window.dispatchEvent(new Event('storage'))
  }

  const [localStorageValue, setLocalStorageValue] = useState(() => {
    return JSON.parse(localStorage.getItem('app_id')) || null
  })

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(JSON.parse(localStorage.getItem('app_id')) || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPlatformData()
    }
  }, [localStorageValue])

  useEffect(() => {
    const fetchData = async () => {
      await getPlatformData()
    }
    fetchData()
  }, [])

  return (
    <div className="custom-sidebar custom-sidebar-builder p-0">
      <div className="sidebar-collapse">
        <div className="logo">
          <img src={GbnLogo} alt="" />
        </div>
        <ul className="main-navigation">
          <li>
            <NavLink to="/dashboard">
              <div>
                <div className="image-block">
                  <img src={Home} alt="" />
                </div>
                <span className="sidebar-link-text">Home</span>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/profiles">
              <div>
                <div className="image-block">
                  <img src={Profile} alt="" />
                </div>
                <span className="sidebar-link-text">Profile</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/workspace">
              <div>
                <div className="image-block">
                  <img src={Workspace} alt="" />
                </div>
                <span className="sidebar-link-text">Workspace</span>
              </div>
            </NavLink>
          </li>
          <div className="mt-40" />
          <li>
            <NavLink to="/content-manager">
              <div>
                <div className="image-block">
                  <img src={ContentManager} alt="" />
                </div>
                <span className="sidebar-link-text">Content Manager</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/store">
              <div>
                <div className="image-block">
                  <img src={MyStore} alt="" />
                </div>
                <span className="sidebar-link-text">My Store</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <div className="d-flex align-items-center justify-content-between">
              <NavLink to="/local" className="w-100">
                <div className="position-relative">
                  <div className="image-block">
                    <img src={Local} alt="" />
                  </div>
                  <span className="sidebar-link-text">Local</span>
                  {/* <div
                  onClick={toggleMenu}
                  className="cursor-pointer image-block position-absolute"
                  type="button"
                >
                  <img src={RightIco} alt=""/>
                </div> */}
                </div>
              </NavLink>
            </div>
            {/* {isOpen && (
            <ul className="custom-menu-options ms-4">
              <li>
                <NavLink to="/hotels">
                  <div>Hotels</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/restaurant">
                  <div>Restaurant</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/event">
                  <div>Events</div>
                </NavLink>
              </li>
            </ul>
          )} */}
          </li>
          <li className="disableSideMenu">
            <NavLink to="cloud-store">
              <div>
                <div className="image-block">
                  <img src={CloudStore} alt="" />
                </div>
                <span className="sidebar-link-text">Cloud Store</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/ads">
              <div>
                <div className="image-block">
                  <img src={ADS} alt="" />
                </div>
                <span className="sidebar-link-text">ADS</span>
              </div>
            </NavLink>
          </li>
          <div className="mt-40" />
          <li className="disableSideMenu">
            <NavLink to="/affiliate">
              <div>
                <div className="image-block">
                  <img src={Affiliate} alt="" />
                </div>
                <span className="sidebar-link-text">Affiliate</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/service-center">
              <div>
                <div className="image-block">
                  <img src={ServiceCenter} alt="" />
                </div>
                <span className="sidebar-link-text">Service Center</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/settings">
              <div>
                <div className="image-block">
                  <img src={Settings} alt="" />
                </div>
                <span className="sidebar-link-text">Settings</span>
              </div>
            </NavLink>
          </li>
          <div className="mt-40" />
          <li>
            <NavLink to="login" onClick={LogoutClick}>
              <div>
                <div className="image-block">
                  <img src={Logout} alt="" />
                </div>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="sidebar-right-part">
        <label>Select Platform</label>
        <Dropdown
          title={inputFields.name}
          onChanged={(d) => {
            changeAddOns(d.id)
            setInputFields({
              ...inputFields,
              id: d.id,
              name: d.name
            })
          }}
          Data={userTypesData.map((d) => {
            return {
              title: d.name,
              id: d.id
            }
          })}
        />

        <div className="links-end">
          <ul>
            {isLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="rounded-3 mb-1"
                  height={48}
                  width={181}
                />
                ))
              : addOns.map((i, index) => {
                  return (
                    <div key={index}>
                      <li>
                        <NavLink
                          to={
                            (i.name === 'Store' &&
                              `/store-builder/${params.id}`) ||
                            (i.name === 'Community' &&
                              `/community-builder/${params.id}`) ||
                            (i.name === 'Entertainment' &&
                              `/profile-builder/${params.id}`)
                          }
                        >
                          <div>
                            {i.name}
                            <div className="cursor-pointer image-block position-absolute">
                              <img src={RightAerrow} alt="" />
                            </div>
                          </div>
                        </NavLink>
                      </li>
                    </div>
                  )
                })}
            {/* {(
            <>
              <li>
                <NavLink to="/local-gastronomy">
                  <div>
                    Local Gastronomy
                    <div className="cursor-pointer image-block position-absolute">
                      <img src={RightAerrow} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/local-hotels">
                  <div>
                    Local Hotels
                    <div className="cursor-pointer image-block position-absolute">
                      <img src={RightAerrow} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/local-events">
                  <div>
                    Local Event
                    <div className="cursor-pointer image-block position-absolute">
                      <img src={RightAerrow} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </li>
            </>
            )} */}
          </ul>
          <ul>
            <li>
              <NavLink to="">
                <div>
                  General Settings
                  <div className="cursor-pointer image-block position-absolute">
                    <img src={RightAerrow} alt="" />
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={`install-addons/${params.id}`}>
                <div>
                  Install ads On
                  <div className="cursor-pointer image-block position-absolute">
                    <img src={RightAerrow} alt="" />
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="">
                <div>
                  Add Page
                  <div className="cursor-pointer image-block position-absolute">
                    <img src={RightAerrow} alt="" />
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default SidebarBuilder
