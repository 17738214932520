/* eslint-disable import/no-duplicates */
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useLocation } from 'react-router-dom'
import VideoDetails from './VideoDetail'
import PodcastDetails from './PodcastDetails'
import CoursesDetails from './CoursesDetail'
import RecipiesDetails from './RecipiesDetails'
import MagazinesDetails from './MagazinesDetails'
import CommunityDetails from './CommunityDetails'
import ShotsDetail from './ShotsDetail'
import VideoIco from '../../images/VideoIco.svg'
import PodcastIco from '../../images/PodcastIco.svg'
import CoursesIco from '../../images/CalendarIco.svg'
import RecipiesIco from '../../images/RecipiesIco.svg'
import MegazinesIco from '../../images/MegazinesIco.svg'
import CommunityIco from '../../images/CommunityIco.svg'
import ShotsIco from '../../images/ShotsIco.svg'

const DetailsComponent = () => {
  const { state } = useLocation()
  return (
    <div className="detail-main content-manager">
      <div className="details-tab-view common-card-body">
        <div className="tabs-for-details-view">
          <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
            <TabList>
              <Tab>
                <img className="component_icon" alt="" src={VideoIco} />
                Videos
              </Tab>
              <Tab>
                <img className="component_icon" alt="" src={PodcastIco} />
                Podcasts
              </Tab>
              <Tab>
                <img className="component_icon" alt="" src={CommunityIco} />
                Community
              </Tab>
              <Tab>
                <img className="component_icon" alt="" src={ShotsIco} />
                Shots
              </Tab>
              <Tab disabled>
                <img className="component_icon" alt="" src={CoursesIco} />
                Courses
              </Tab>
              <Tab disabled>
                <img className="component_icon" alt="" src={RecipiesIco} />
                Recipies
              </Tab>
              <Tab disabled>
                <img className="component_icon" alt="" src={MegazinesIco} />
                Megazines
              </Tab>
            </TabList>

            <TabPanel>
              <VideoDetails />
            </TabPanel>
            <TabPanel>
              <PodcastDetails />
            </TabPanel>
            <TabPanel>
              <CommunityDetails />
            </TabPanel>
            <TabPanel>
              <ShotsDetail />
            </TabPanel>
            <TabPanel>
              <CoursesDetails />
            </TabPanel>
            <TabPanel>
              <RecipiesDetails />
            </TabPanel>
            <TabPanel>
              <MagazinesDetails />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
export default DetailsComponent
