import React from 'react'
import Stories from '../Stories/AddStories'
import AddVideo from '../RecentVideos/AddVideos'
import AddPodcast from '../RecentPodcast/AddPodcast'
import AddShots from '../RecentShots/AddShots'
import AddGiveway from '../AddGiveway/AddGiveway'
import AddPolls from '../AddPolls/AddPolls'
import AddDiscussions from '../AddDiscussions/AddDiscussions'
import AddPhotos from '../Creator-profile-photos/AddPhotos'

const ProfileHomePage = () => {
    return (
      <>
        <div>
          <Stories />
        </div>
        <div className="mt-30">
          <AddPhotos />
        </div>
        <div className="mt-30">
          <AddVideo />
        </div>
        <div className="mt-30">
          <AddShots />
        </div>
        <div className="mt-30">
          <AddPodcast />
        </div>
        <div className="mt-30">
          <AddGiveway />
        </div>
        <div className="mt-30">
          <AddPolls />
        </div>
        <div className="mt-30">
          <AddDiscussions />
        </div>
      </>
    )
}
export default ProfileHomePage
