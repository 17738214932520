import React from 'react'

const ConfirmationModal = ({
  isVisible,
  onConfirm,
  onCancel,
  message = 'Do you want to delete this?',
  isLoading = false
}) => {
  if (!isVisible) return null

  return (
    <div className="add-item-model">
      <div className="add-item-contents h-auto w-auto">
        <div className="item-details-input pt-0">
          <h3 className="mb-0 text-center">{message}</h3>
          <div className="button-row justify-content-center">
            <button
              type="button"
              className="secondary-btn-modelConfirm"
              onClick={onCancel}
              disabled={isLoading}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              onClick={onConfirm}
              className="primary-btn-modelConfirm"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
