import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules'
import 'swiper/css'
import './recent-video.css'
import Health from '../../images/health.svg'
import Play from '../../images/play.svg'

const RecentVideo = () => {
  const items = [
    {
      time: '10:20',
      cover: './images/recentvideo/1.jpg',
      title: 'Gesundheit// Sub category ',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/2.jpg',
      title: 'Gesundheit// Sub category ',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/1.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/2.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/1.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/2.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/1.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/2.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/1.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    },
    {
      time: '10:20',
      cover: './images/recentvideo/2.jpg',
      title: 'Gesundheit// Sub category',
      text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Justo eu'
    }
  ]
  return (
    <div className="recent-video-component common-card-body">
      <h3>Recent Videos</h3>
      <div className="position-relative">
        <Swiper
          slidesPerView="auto"
          autoHeight
          loop
          // effect={"coverflow"}
          // coverflowEffect={{
          //   rotate: 0,
          //   stretch: 0,
          //   depth: 100,
          //   modifier: 1,
          //   slideShadows: true,
          // }}
          pagination={{
            clickable: true
          }}
          spaceBetween={30}
          navigation
          modules={[Navigation, Pagination, EffectCoverflow]}
          centeredSlides
          className="mySwiper-story position-static"
        >
          {items.map((item) => (
            <SwiperSlide key={item.name}>
              <div className="recent-video-slider">
                <div
                  className="video-cover"
                  style={{
                    backgroundImage: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="time">{item.time}</div>
                  <div className="play">
                    <img alt="" src={Play} />
                  </div>
                </div>
                <div className="recent-video-detail">
                  <h4>
                    <img alt="" src={Health} />
                    <span>{item.title}</span>
                  </h4>
                  <p>{item.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
export default RecentVideo
