import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import RatingContainer from '../RatingContainer/Rating'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import Slider from '../ReviewsAndRating/slider'
import SliderLocal from '../LocalProfile/Slider'
import RecentProduct from './RecentProducts'
import Store from './store'
import HomeIco from '../../images/HomeIco.svg'
import StoreIco from '../../images/StoreIco.svg'
import FeedbackIco from '../../images/FeedbackIco.svg'
import AboutIco from '../../images/AboutIco.svg'

const StoreProfile = () => {
  const items = [
    {
      cover: './images/slider/1.png',
      text: 'Be quick and enjoy our exclusive offer for this month. Save 30% on our Samsung Galaxy S23 and use a smartphone that suits your needs!',
      heading: 'Save 30% on the Samsung Galaxy S23',
      offer: 'OFF Only this month!',
      percentage: '30%',
      background: 'rgba(32, 78, 158, 0.60)',
      subhead: 'New Offers'
    },
    {
      cover: './images/slider/1.png',
      text: 'Be quick and enjoy our exclusive offer for this month. Save 30% on our Samsung Galaxy S23 and use a smartphone that suits your needs!',
      heading: 'Save 30% on the Samsung Galaxy S23',
      offer: 'OFF Only this month!',
      percentage: '30%',
      background: 'rgba(32, 78, 158, 0.60)',
      subhead: 'New Offers'
    }
  ]
  const storeitems = [
    {
      cover: './images/store/925150afea589b36ad6caea6195ec69e.jpg',
      text: 'Be quick and enjoy our exclusive offer for this month. Save 30% on our Samsung Galaxy S23 and use a smartphone that suits your needs!',
      heading: 'Save 30% on the Samsung Galaxy S23',
      offer: 'OFF Only this month!',
      percentage: '30%',
      background: '#8D8D8D',
      subhead: 'Sales Products'
    },
    {
      cover: './images/store/925150afea589b36ad6caea6195ec69e.jpg',
      text: 'Be quick and enjoy our exclusive offer for this month. Save 30% on our Samsung Galaxy S23 and use a smartphone that suits your needs!',
      heading: 'Save 30% on the Samsung Galaxy S23',
      offer: 'OFF Only this month!',
      percentage: '30%',
      background: '#8D8D8D',
      subhead: 'Sales Products'
    }
  ]
  return (
    <div className="creator-profile-main store-profile">
      <ProfileHead />
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={HomeIco} />
                  Home
                </Tab>
                <Tab>
                  <img alt="" src={StoreIco} />
                  Store
                </Tab>
                <Tab>
                  <img alt="" src={FeedbackIco} />
                  Reviews/Rating
                </Tab>
                <Tab>
                  <img alt="" src={AboutIco} />
                  About
                </Tab>
              </TabList>

              <TabPanel>
                <div className="mt-40 store-home">
                  <SliderLocal propsitems={items} />
                </div>
                <div className="mt-40">
                  <RecentProduct />
                </div>
                <div className="about common-card-body recent-video-component mt-40">
                  <h3>About us</h3>
                  <p>
                    Samsung has a simple business philosophy: to dedicate its
                    talents and technologies to developing outstanding products
                    and services that contribute to a better global society.
                    Samsung believes that living by strong values is the key to
                    doing good business. That&apos;s why these core values,
                    along with a strict Code of Conduct, are at the heart of
                    every decision the company makes.
                  </p>
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40 common-card-body recent-video-component">
                  <h3>Customer Reviews</h3>
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-40 store-slider">
                  <SliderLocal propsitems={storeitems} />
                </div>
                <Store />
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default StoreProfile
