import React from 'react'
import './OurTeam.css'
import GreenTick from '../../images/green-tick.svg'
import RatingStars from '../../images/reting-stars.svg'
import Follower from '../../images/3User.svg'
import Creator from '../../images/Creators.svg'
import ViewProfile from '../../images/3Friends.svg'

const OurTeamProfile = () => {
  const items = [
    {
      profile: './images/profile/1.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      follower: '2.128',
      creator: '2.128'
    },
    {
      profile: './images/profile/2.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      follower: '2.128',
      creator: '2.128'
    },
    {
      profile: './images/profile/3.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      follower: '2.128',
      creator: '2.128'
    }
  ]
  return (
    <div
      className="our-team-profile"
      style={{
        background: 'url(./images/profile/profile-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="d-flex col-gap-20 align-items-center justify-content-between">
        <div className="position-relative z-index-1">
          <h4>Our Team</h4>
          <p>
            View our team. We are eager to help you with any issue you have!
          </p>
          <button type="button" className="light-button">
            <span>See All</span>
          </button>
        </div>
        <div className="position-relative z-index-1">
          <div className="profile-team">
            {items.map((item) => (
              <div className="team-profile-item" key={item.name}>
                <img alt="" className="profile-img" src={item.profile} />
                <h6>
                  {item.name}
                  <img alt="" src={GreenTick} />
                </h6>
                <span className="designation">{item.designation}</span>
                <div className="rating-star">
                  <img alt="" src={RatingStars} />
                </div>
                <div className="follower-creater">
                  <span>
                    <img alt="" src={Follower} />
                    {item.follower}
                  </span>
                  <span>
                    <img alt="" src={Creator} />
                    {item.creator}
                  </span>
                </div>
                <button type="button">
                  <img alt="" src={ViewProfile} />
                  {' '}
                  <span>View Profile</span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default OurTeamProfile
