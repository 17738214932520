import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import Dropdown from '../Dropdown/Dropdown'
import Health from '../../images/health.svg'
import RatingStar from '../../images/rating-start.svg'
import LikeEmpty from '../../images/like-empty.svg'
import ReadMore from '../../images/Read more.svg'
import DownIco from '../../images/DownIco.svg'
import ViewIco from '../../images/ViewIco.svg'
import ClockFillIco from '../../images/ClockFillIco.svg'
import book from '../../images/book.svg'

const ExpertMegazines = () => {
  const items = [
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/scalco-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/testiee-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/a-z-fit-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/the-teste-club-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/scalco-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/testiee-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }

  return (
    <div className="recent-video-component expert-megazines">
      <div className="megazines-slider position-relative mb-30">
        <Swiper
          slidesPerView={1}
          navigation
          pagination={{
            clickable: true
          }}
          modules={[Navigation, Pagination]}
          className="mySwiper-story position-static"
        >
          <SwiperSlide>
            <div
              className="megazines-slider-container"
              style={{
                background: 'url(./images/megazineslider.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="megazines-slider-detail">
                <h6>By</h6>
                <h5>Julie Marie Urte</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Ipsum, ut quam libero, tincidunt. Iaculis malesuada maecenas
                  vel hac vivamus metus, tortor facilisis id. Metus nec velit ac
                  in dui porttitor volutpat. Habitant dolor, posuere in lorem
                  netus ultrices. Facilisis magna curabitur lorem at tortor.
                  {' '}
                </p>
                <button type="button" className="light-button d-flex align-items-center col-gap-10">
                  <img src={ReadMore} alt="" />
                  <span>Read</span>
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="megazines-slider-container"
              style={{
                background: 'url(./images/megazineslider.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="megazines-slider-detail">
                <h6>By</h6>
                <h5>Julie Marie Urte</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Ipsum, ut quam libero, tincidunt. Iaculis malesuada maecenas
                  vel hac vivamus metus, tortor facilisis id. Metus nec velit ac
                  in dui porttitor volutpat. Habitant dolor, posuere in lorem
                  netus ultrices. Facilisis magna curabitur lorem at tortor.
                  {' '}
                </p>
                <button type="button" className="light-button d-flex align-items-center col-gap-10">
                  <img src={ReadMore} alt="" />
                  <span>Read</span>
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="common-card-body">
        <div className="add-video-row">
          <h3 className="m-0">Magazines</h3>
          <div className="items-filter">
            <div className="position-relative input-position">
              <input placeholder="Search Magazines" />
            </div>
            <Dropdown
              title="Filter"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
          </div>
        </div>
        <div className="video-detail-manager">
          {items.slice(0, visibleItems).map((item) => (
            <div
              className="video-detail-item magazines-detail-item"
              key={item.platformImg}
            >
              <div
                className="video-detail-cover"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="like">
                  <img src={LikeEmpty} alt="" />
                </div>
              </div>
              <div className="video-content">
                <div className="magezines-title">
                  <h6>
                    <img src={Health} alt="" />
                    <span>{item.Title}</span>
                  </h6>
                  <div className="rating">
                    <img src={RatingStar} alt="" />
                    <span>{item.rating}</span>
                  </div>
                </div>
                <p>{item.text}</p>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="views-and-time">
                    <span>
                      <img src={ViewIco} alt="" />
                      {item.view}
                    </span>
                    <span>
                      <img src={ClockFillIco} alt="" />
                      {item.days}
                    </span>
                  </div>
                  <div className="views-and-time-read">
                    <span>
                      <img src={book} alt="" />
                      Read
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img src={DownIco} alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default ExpertMegazines
