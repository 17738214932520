import React from 'react'
import './Dashboard.css'
import Dropdown from '../Dropdown/Dropdown'
// import Notification from '../../images/notification-activity.svg'
// import Belle from '../../images/belle.png'

const Activity = () => {
  return (
    <div className="activity">
      {/* <div className="custome-select"> */}
      <Dropdown
        customClass="content-filter disabledDropdown"
        title="All Activity"
      />
      {/* </div> */}
      <div className="activity-list">
        {/* <div className="list-item">
          <div className="activity-details">
            <img src={Notification} alt="" />
            <div>
              <p>
                <span className="name">Tim Zahang</span>
                {' '}
                left
                {' '}
                <span className="name">funeralOne</span>
                {' '}
                and
                {' '}
                <span className="discription">started a new job.</span>
                {' '}
                To learn
                more about your contact’s new role, upgrade your plan and
                subscribe to our Job Change Alert features.
                {' '}
              </p>
              <div className="list-btn-row">
                <button type="button" className="primary-btns">
                  <span>Subscribe</span>
                </button>
                <button type="button" className="secondary-btns">
                  <span>Upgrade</span>
                </button>
              </div>
            </div>
          </div>
          <div className="activity-date">Mar 26</div>
        </div>
        <div className="list-item">
          <div className="activity-details">
            <img src={Notification} alt="" />
            <div>
              <p>
                <span className="name">Tim Zahang</span>
                {' '}
                left
                {' '}
                <span className="name">funeralOne</span>
                {' '}
                and
                {' '}
                <span className="discription">started a new job.</span>
                {' '}
                To learn
                more about your contact’s new role, upgrade your plan and
                subscribe to our Job Change Alert features.
                {' '}
              </p>
              <div className="list-btn-row">
                <button type="button" className="primary-btns">
                  <span>Subscribe</span>
                </button>
                <button type="button" className="secondary-btns">
                  <span>Upgrade</span>
                </button>
              </div>
            </div>
          </div>
          <div className="activity-date">Mar 26</div>
        </div>
        <div className="list-item">
          <div className="activity-details activity-detail-img">
            <img src={Belle} alt="" />
            <div>
              <ul>
                <li className="designation">Product designer</li>
                <li className="designation-sub">Designation</li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Faucibus ultrices enim odio vestibulum mi volutpat bibendum
                amet, risus. Vestibulum tellus lacinia gravida sed.
              </p>
              <div>
                <button type="button" className="secondary-btns">
                  <span>Add to Bookmark</span>
                </button>
              </div>
            </div>
          </div>
          <div className="activity-date">Mar 26</div>
        </div> */}
      </div>
    </div>
  )
}
export default Activity
