import React from 'react'
import AuthLogo from '../../images/auth-logo.svg'

const SideBarPage = () => {
  return (
    <div className="auth-sidebar h-100">
      <div className="d-flex align-items-center justify-content-center h-100 inline-padding">
        <img src={AuthLogo} alt="" />
      </div>
    </div>
  )
}
export default SideBarPage
