import React from 'react'
import PollsComponent from './PollsComponent'

const AddPolls = () => {
  return (
    <div className="polls-sec">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <PollsComponent showCount={1} />
      </div>
    </div>
  )
}

export default AddPolls
