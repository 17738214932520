import React from 'react'
import './Dashboard.css'

const Campaigns = () => {
  // const CampaignsStyle = {
  //   background: 'url("./images/campaings.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }

  return (
    <div className="campaigns">
      <div className="campaigns-sub">
        {/* <div className="campaings-item">
          <div className="campaings-banner" style={CampaignsStyle}>
            <p className="campaings-title">Find Way to Burn Fates</p>
            <p className="campaings-status">Published</p>
          </div>
          <div className="campaingns-details">
            <ul>
              <li>Reach</li>
              <li>0</li>
              <li className="line-yellow" />
            </ul>
            <ul>
              <li>Engagement</li>
              <li>0</li>
              <li className="line-blue" />
            </ul>
          </div>
        </div>
        <div className="campaings-item">
          <div className="campaings-banner" style={CampaignsStyle}>
            <p className="campaings-title">Find Way to Burn Fates</p>
            <p className="campaings-status">Published</p>
          </div>
          <div className="campaingns-details">
            <ul>
              <li>Reach</li>
              <li>0</li>
              <li className="line-yellow" />
            </ul>
            <ul>
              <li>Engagement</li>
              <li>0</li>
              <li className="line-blue" />
            </ul>
          </div>
        </div>
        <div className="campaings-item">
          <div className="campaings-banner" style={CampaignsStyle}>
            <p className="campaings-title">Find Way to Burn Fates</p>
            <p className="campaings-status">Published</p>
          </div>
          <div className="campaingns-details">
            <ul>
              <li>Reach</li>
              <li>0</li>
              <li className="line-yellow" />
            </ul>
            <ul>
              <li>Engagement</li>
              <li>0</li>
              <li className="line-blue" />
            </ul>
          </div>
        </div>
        <div className="campaings-item">
          <div className="campaings-banner" style={CampaignsStyle}>
            <p className="campaings-title">Find Way to Burn Fates</p>
            <p className="campaings-status">Published</p>
          </div>
          <div className="campaingns-details">
            <ul>
              <li>Reach</li>
              <li>0</li>
              <li className="line-yellow" />
            </ul>
            <ul>
              <li>Engagement</li>
              <li>0</li>
              <li className="line-blue" />
            </ul>
          </div>
        </div>
        <div className="campaings-item">
          <div className="campaings-banner" style={CampaignsStyle}>
            <p className="campaings-title">Find Way to Burn Fates</p>
            <p className="campaings-status">Published</p>
          </div>
          <div className="campaingns-details">
            <ul>
              <li>Reach</li>
              <li>0</li>
              <li className="line-yellow" />
            </ul>
            <ul>
              <li>Engagement</li>
              <li>0</li>
              <li className="line-blue" />
            </ul>
          </div>
        </div>
        <div className="campaings-item">
          <div className="campaings-banner" style={CampaignsStyle}>
            <p className="campaings-title">Find Way to Burn Fates</p>
            <p className="campaings-status">Published</p>
          </div>
          <div className="campaingns-details">
            <ul>
              <li>Reach</li>
              <li>24k</li>
              <li className="line-yellow" />
            </ul>
            <ul>
              <li>Engagement</li>
              <li>166</li>
              <li className="line-blue" />
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default Campaigns
