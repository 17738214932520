import React from 'react'
import SliderLocal from '../LocalProfile/Slider'
import Breakfast from './Breakfast'
import Lunch from './Lunch'
import Dinner from './Dinner'

const MenuMain = () => {
  const generateStars = (totalRating) => {
    const totalStars = 5 // Total number of stars to display
    const count = Math.min(Math.floor(parseFloat(totalRating)), totalStars)
    const remainder = parseFloat(totalRating) - count // Decimal part to represent partial star
    const stars = []
    for (let i = 0; i < count; i++) {
      stars.push(<img alt="" key={i} src="./images/star.svg" />)
    }
    if (remainder > 0) {
      stars.push(<img alt="" key={count} src="./images/star.svg" />)
    }
    for (let i = stars.length; i < totalStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
    }

    return stars
  }
  return (
    <div>
      <div
        className="market-restaurant"
        style={{
          background: 'url(./images/Restaurant/4.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="position-relative z-1 text-white">
          <h4>One Market Restaurant</h4>
          <span>2nd street, clubs road</span>
          <div className="reting-div">{generateStars(5)}</div>
          <p>€350 to €400</p>
        </div>
      </div>
      <div className="mt-30 common-card-body recent-video-component">
        <h3>Popular Meals</h3>
        <SliderLocal />
        <div className="mt-30">
          <Breakfast />
        </div>
        <div className="mt-30">
          <Lunch />
        </div>
        <div className="mt-30">
          <Dinner />
        </div>
      </div>
    </div>
  )
}
export default MenuMain
