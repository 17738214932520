import React, { useState } from 'react'
import CloseIco from '../../images/CloseIco.svg'
import PlusIco from '../../images/PlusIco.svg'

const TeamSettings = () => {
  const [isHidden, setIsHidden] = useState(true)

  const handleButtonClick = () => {
    setIsHidden(false)
  }
  const handleButtonClickHide = () => {
    setIsHidden(true)
  }

  return (
    <div className="settings-overview">
      <div className="item-wrapper">
        <h2>Content Manager Settings</h2>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Team Graph Setting</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all Projects by week, month and Year</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleGraph">
                <input id="titleGraph" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all Teams by Week,month and year </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesGraph">
                <input id="categroiesGraph" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Projects Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show project name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleproject">
                <input id="titleproject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show project progress </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesproject">
                <input id="categroiesproject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show team members</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionproject">
                <input id="descriptionproject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show project status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredproject">
                <input id="featuredproject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Single Project Detail Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show tasks name </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleSingleProject">
                <input id="titleSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Chat on every task</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesSingleProject">
                <input id="categroiesSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Task progress</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionSingleProject">
                <input id="descriptionSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Task discription</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredSingleProject">
                <input id="featuredSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Members on every task</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="PriceSingleProject">
                <input id="PriceSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show whole project progress bar</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="quantitySingleProject">
                <input id="quantitySingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show files added on every task</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="everytaskSingleProject">
                <input id="everytaskSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show comments on every task </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="commentsSingleProject">
                <input id="commentsSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show activities on every task </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="activitiesSingleProject">
                <input id="activitiesSingleProject" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Single Team Member Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show project in progress</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleprojects">
                <input id="titleprojects" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show project completed</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesprojects">
                <input id="categroiesprojects" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show introduction of team member</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionprojects">
                <input id="descriptionprojects" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Skills </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredprojects">
                <input id="featuredprojects" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Skills of Team Member</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="common-select-option">
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Languages</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>blogging </label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Arts</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Podcasting</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Travel</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>yoga</label>
            </div>
          </div>
        </div>
      </div>
      {!isHidden && (
        <div className="add-more-settings-model">
          <div className="model-content">
            <div className="model-header">
              <h4>Add more video setting</h4>
              <button type="button" onClick={handleButtonClickHide}>
                <img className="close-btns" alt="" src={CloseIco} />
              </button>
            </div>
            <div className="model-filled">
              <label>Option name</label>
              <input placeholder="Name here" />
              <label>Decription</label>
              <textarea placeholder="Type here..." />
            </div>
            <div className="model-footer">
              <button type="button" className="save-button">Add option</button>
              <button type="button" className="cancel-button" onClick={handleButtonClickHide}>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default TeamSettings
