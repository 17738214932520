import React from 'react'
import '../AddAwards/AddAwards.css'
import Plus from '../../images/plus-rounded.svg'
import './style.css'

const AddLocalHome = () => {
  return (
    <div className="add-local-home">
      <div className="common-card-body">
        <div className="add-local-home-banner">
          <button type="button" className="add-item-btn">
            <img alt="" src={Plus} />
          </button>
        </div>
        <div className="add-local-home-detail">
          <ul>
            <li className="add-value" />
            <li className="add-value" />
            <li className="add-value" />
          </ul>
        </div>
      </div>
    </div>
  )
}
export default AddLocalHome
