import React from 'react'
import DisscussionComponent from './DisscussionComponent'

const AddDiscussions = () => {
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <DisscussionComponent showCount={2} />
      </div>
    </div>
  )
}
export default AddDiscussions
