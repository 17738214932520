import React from 'react'
import './ServiceCenter.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const items = [
  {
    question: 'Lorem ipsum dolor sit ame dolor sit.',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.'
  },
  {
    question: 'Lorem ipsum dolor sit ame dolor sit.',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.'
  },
  {
    question: 'Lorem ipsum dolor sit ame dolor sit.',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.'
  },
  {
    question: 'Lorem ipsum dolor sit ame dolor sit.',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque nullam sit amet, lorem.'
  }
]

const Faq = () => {
  return (
    <div className="accordion-faq">
      <Accordion>
        {items.map((item) => (
          <AccordionItem key={item}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.question}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>{item.answer}</p>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
export default Faq
