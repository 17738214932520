import React, { useEffect } from 'react'
import './CreatorProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import Stories from '../Stories/AddStories'
import Feeds from '../Feeds/Feeds'
// import AddGiveway from '../AddGiveway/AddGiveway'
// import AddPolls from '../AddPolls/AddPolls'
// import AddDiscussions from '../AddDiscussions/AddDiscussions'
// import AddAwards from '../AddAwards/AddAwards'
import GivewayComponent from '../AddGiveway/GivewayComponent'
import PollsComponent from '../AddPolls/PollsComponent'
import DisscussionComponent from '../AddDiscussions/DisscussionComponent'
import AwardsComponent from '../AddAwards/AwardsComponent'
import HomeIco from '../../images/HomeIco.svg'
import FeedIco from '../../images/FeedIco.svg'
import GivewayIco from '../../images/GivewayIco.svg'
import PollsIco from '../../images/PollsIco.svg'
import DiscussionIco from '../../images/DiscussionIco.svg'
// import AwardsIco from '../../images/AwardsIco.svg'
// import ProfileHeadCreator from '../ProfileBuilder/ProfileHeadCreator'
import ProfileHomePage from './profileHomePage'

const CommunityBuilder = () => {
  useEffect(() => {
  window.scroll(0, 0)
  }, [])
  const { state } = useLocation()
  const TabsComponents = () => {
    return (
      <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
        <TabList>
          <Tab>
            <img src={HomeIco} alt="home icon" />
            Home
          </Tab>
          <Tab>
            <img src={FeedIco} alt="feed icon" />
            Feed
          </Tab>
          <Tab>
            <img src={GivewayIco} alt="giveawy icon" />
            Giveaways
          </Tab>
          <Tab>
            <img src={PollsIco} alt="poll icon" />
            Polls
          </Tab>
          <Tab>
            <img src={DiscussionIco} alt="discussions icon" />
            Discussions
          </Tab>
          {/* <Tab>
            <img src={AwardsIco} alt="award icon" />
            Awards
          </Tab> */}
        </TabList>

        <TabPanel>
          {/* <div>
            <Stories />
          </div>
          <div className="mt-40">
            <AddGiveway />
          </div>
          <div className="mt-40">
            <AddPolls />
          </div>
          <div className="mt-40">
            <AddDiscussions />
          </div> */}
          {/* <div className="mt-40">
            <AddAwards />
          </div> */}
          <ProfileHomePage />
        </TabPanel>
        <TabPanel>
          <div>
            <Feeds />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <GivewayComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <PollsComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <DisscussionComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <AwardsComponent />
          </div>
        </TabPanel>
      </Tabs>
    )
  }
  const navigate = useNavigate()
  const { id } = useParams()
  console.log('params', id)
  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(`/community-builder/${id}`)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  return (
    <div className="creator-profile-main creator-profile-create">
      {/* <ProfileHeadCreator /> */}
      <div className="details-tab-view pt-0 mt-15">
        <div className="tabs-for-details-view">
          <TabsComponents />
        </div>
      </div>
    </div>
  )
}
export default CommunityBuilder
