import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import './Settings.css'
import { Link } from 'react-router-dom'
import ContentManagerSettings from './ContentManagerSettings'
import StoreSettings from './StoreSettings'
import LocalSettings from './LocalSettings'
import TeamSettings from './TeamSettings'
import HomeIco from '../../images/HomeIco.svg'
import ProfileIco from '../../images/ProfileIco.svg'
import StoreIco from '../../images/StoreIco.svg'
import LocalIco from '../../images/LocalIco.svg'
import CommunityIco from '../../images/CommunityIco.svg'

const Setings = () => {
  const items = [
    {
      name: 'Profile',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Content Manager',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Store',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Local',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Team',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Ads',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    }
  ]

  return (
    <div className="settings">
      <div className="settings-overview">
        <div className="tab-dashboard">
          <Tabs>
            <TabList>
              <Tab>
                <img alt="" src={ProfileIco} />
                Profile
              </Tab>
              <Tab>
                <img alt="" src={HomeIco} />
                Content Manager
              </Tab>
              <Tab>
                <img alt="" src={StoreIco} />
                Store
              </Tab>
              <Tab>
                <img alt="" src={LocalIco} />
                Local
              </Tab>
              <Tab>
                <img alt="" src={CommunityIco} />
                Team
              </Tab>
              <Tab>
                <img alt="" src={CommunityIco} />
                Ads
              </Tab>
            </TabList>

            <TabPanel>
              <div className="item-wrapper">
                <h2>Settings Overview</h2>
                {items.map((item) => (
                  <div key={item.name} className="profile-overview-item">
                    <div className="profile-item-detail">
                      <h3>{item.name}</h3>
                      <p>{item.text}</p>
                    </div>
                    <Link to={item.link}>View Settings</Link>
                  </div>
                ))}
              </div>
            </TabPanel>
            <TabPanel>
              <ContentManagerSettings />
            </TabPanel>
            <TabPanel>
              <StoreSettings />
            </TabPanel>
            <TabPanel>
              <LocalSettings />
            </TabPanel>
            <TabPanel>
              <TeamSettings />
            </TabPanel>
            <TabPanel />
          </Tabs>
        </div>
      </div>
    </div>
  )
}
export default Setings
