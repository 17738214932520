import React from 'react'
import './PodcastVideo.css'
import play from '../../images/play.svg'

const PodcastVideo = () => {
  return (
    <div
      className="podcast-video-container"
      style={{
        background: 'url(./images/podcast-video-cover.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className="podcast-video">
        <div className="podcast-video-title">
          <h4>Podcast/Video Title</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nibh
            viverra nullam ut eget. Ipsum lacus, eu facilisi amet enim. Egestas
            facilisis condimentum aliquam dictumst fames in at libero. Erat
            egestas augue phasellus magnis.
          </p>
          <button type="button" className="light-button">
            <img src={play} alt="" />
            <span>Watch</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export default PodcastVideo
