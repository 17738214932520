import React from 'react'
import GivewayComponent from './GivewayComponent'

const AddGiveway = () => {
  return (
    <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
      <GivewayComponent showCount={1} />
    </div>
  )
}
export default AddGiveway
