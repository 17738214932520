import React, { useEffect, useState } from 'react'
import './photos.css'
import PropTypes from 'prop-types'
import DownIco from '../../images/DownIco.svg'

const photos = [
  {
    image: './images/photos/1.png'
  },
  {
    image: './images/photos/2.png'
  },
  {
    image: './images/photos/3.png'
  },
  {
    image: './images/photos/4.png'
  },
  {
    image: './images/photos/5.png'
  },
  {
    image: './images/photos/3.png'
  },
  {
    image: './images/photos/4.png'
  },
  {
    image: './images/photos/5.png'
  },
  {
    image: './images/photos/3.png'
  }
]

const Photos = ({ propsitems }) => {
  const [items, setItems] = useState(photos)
  console.log(propsitems)
  useEffect(() => {
    if (propsitems && propsitems.length > 0) {
      setItems(propsitems)
    }
  }, [propsitems])

  const [visibleItems, setVisibleItems] = useState(6)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3)
  }

  return (
    <div className="stories-component common-card-body">
      <h3>Photos</h3>
      <div className="photos-grid">
        {items.slice(0, visibleItems).map((item) => (
          <div className="photos-grid-item" key={item}>
            <img src={item.image} alt="" />
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img src={DownIco} alt="" />
          </button>
        </div>
      )}
    </div>
  )
}
Photos.propTypes = {
  propsitems: PropTypes.func.isRequired
}
export default Photos

