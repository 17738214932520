import React from 'react'
import { Link } from 'react-router-dom'

const RecentProject = () => {
  const items = [
    {
      img: './images/project/1.jpg',
      heading: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    },
    {
      img: './images/project/2.jpg',
      heading: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    },
    {
      img: './images/project/3.jpg',
      heading: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component ">
      <h3 className="d-flex align-items-center justify-content-between">
        Recent Project
        {' '}
        <Link>View All</Link>
      </h3>
      <div className="recent-project-grid">
        {items.map((item) => (
          <div key={item.heading} className="job--project-item">
            <div className="project-item-cover">
              <img alt="" src={item.img} />
            </div>
            <div className="project-item-detail">
              <h4>{item.heading}</h4>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RecentProject
