import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

import Pagination from 'react-js-pagination'
import IconsMore from '../../images/akar-icons_more-horizontal-fill.svg'
import RatingStar from '../../images/rating-start.svg'

const Clients = () => {
  const location = useLocation()
  const { state } = location || {}
  const client = state || []

  const itemsPerPage = 6
  const [activePage, setActivePage] = useState(1)
  const startIndex = (activePage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const displayedClients = client.slice(startIndex, endIndex)
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
  }
  return (
    <div className="creator-profile-main">
      <div className="common-card-body  recent-video-component">
        <div className="d-flex align-items-center justify-content-between mb-30">
          <h3 className="m-0">Clients</h3>
          <div className="items-filter">
            <button type="button" className="btn-gredient">
              <span>Active</span>
            </button>
            <button type="button" className="btn-light-blue">
              <span>Inactive</span>
            </button>
            <div className="position-relative input-position">
              <input placeholder="Search videos..." />
            </div>
          </div>
        </div>
        <div className="order-table">
          <table className="w-100">
            <tr>
              <th>Client Name</th>
              <th>Review</th>
              <th>Rating</th>
              <th>Orders</th>
              <th />
            </tr>

            {displayedClients.map((item) => (
              <tr key={item.productId} className="data-row">
                <td className="d-flex col-gap-20">
                  <div className="client-img">
                    <img alt="" src={item.profile} />
                  </div>
                  <div className="client-detail">
                    <p className="m-0">{item.name}</p>
                    <span>{item.designation}</span>
                  </div>
                </td>
                <td>{item.review}</td>
                <td className="d-flex align-items-center">
                  <img alt="" className="me-1" src={RatingStar} />
                  {item.rating}
                </td>
                <td>
                  <span id="order">
                    {' '}
                    {item.order}
                    {' '}
                    Orders
                  </span>
                </td>
                <td>
                  <Link>
                    <img alt="" src={IconsMore} />
                  </Link>
                </td>
              </tr>
            ))}
          </table>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={client.length}
            pageRangeDisplayed={1}
            hideFirstLastPages
            onChange={handlePageChange}
            innerClass="pagination"
            itemClass="page-item"
            linkClass="page-link"
            itemClassPrev="page-link-visible"
            itemClassNext="page-link-visible"
            prevPageText={
              <svg
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1.75L1 8.49804L8 15.25"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
              >
                <path
                  d="M0.999999 1.75L8 8.49804L1 15.25"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Clients
