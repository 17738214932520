import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import eyeoff from '../../images/eye-off.svg'
import eyeon from '../../images/eye-on.svg'
import { ForgotPassword, ResetPassword } from '../../services/Service'
import '../../App.css'

const ResetPasswordPage = () => {
  const navigate = useNavigate()

  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [inputFields, setInputFields] = useState({
    email: localStorage.getItem('forgotPassword'),
    otp: '',
    password: '',
    confirm_password: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [countdown, setCountdown] = useState(59)
  const [canResend, setCanResend] = useState(false)
  const [firstTime, setFirstTime] = useState(true)
  const [loading, setLoading] = useState(false)

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.otp.length !== 6) {
      errors.otp = 'Please enter 6 digit otp'
    }
    const regexPass =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    if (!inputValues.password.match(regexPass)) {
      errors.password =
        'Minimum eight characters, at least one letter, one number and one special character'
    }
    if (inputValues.password !== inputValues.confirm_password) {
      errors.confirm_password = 'Password miss match'
    }
    return errors
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputFields((prevFields) => {
      const updatedFields = { ...prevFields, [name]: value }

      const updatedErrors = { ...errors }

      if (name === 'password') {
        if (
          updatedFields.password.match(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          )
        ) {
          delete updatedErrors.password
        }
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        }
      }

      if (name === 'confirm_password') {
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        } else {
          updatedErrors.confirm_password = 'Password mismatch'
        }
      }

      setErrors(updatedErrors)
      return updatedFields
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    ResetPassword(inputFields).then((res) => {
      console.log(res)
      localStorage.removeItem('forgotPassword')
      if (res.data.success) {
        navigate('/login')
        setLoading(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2)
  }

  const ResendOtp = () => {
    ForgotPassword({
      email: inputFields.email,
      app_id: inputFields.app_id
    }).then((res) => {
      if (res.data.success) {
        toast.success('Otp Send Successfully.')
        setCountdown(59)
        setCanResend(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
    } else {
      if (canResend) {
        setCountdown(59)
      }
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer)
            setCanResend(true)
          }
          return Math.max(prevCountdown - 1, 0)
        })
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [firstTime, canResend])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  useEffect(() => {
    setIsButtonEnabled(
      inputFields.confirm_password.length > 0 &&
        inputFields.password.length > 0 &&
        inputFields.otp.length > 5,
    )
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6">
          <div className="inline-padding mt-5 pt-5">
            <h2 className="mb-5 mt-5">SET NEW APP WORLD ONE PASSWORD</h2>
            <form onSubmit={handleSubmit}>
              <p>
                <label className="clip-text">One Time Password *</label>
                <div className="password-input">
                  <input
                    name="otp"
                    placeholder="Please Enter Otp"
                    onChange={handleChange}
                    required
                    type="number"
                  />
                </div>
                {errors.otp ? <p className="error-text">{errors.otp}</p> : null}
              </p>
              <p>
                <label className="clip-text">New Password *</label>
                <div className="password-input">
                  <input
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder="*********************"
                    required
                    onChange={(e) => {
                      const updatedErrors = { ...errors }
                      const passwordValue = e.target.value
                      if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(passwordValue)) {
                        updatedErrors.password =
                          'Minimum eight characters, at least one letter, one number and one special character'
                      } else {
                        delete updatedErrors.password
                      }
                      setInputFields({
                        ...inputFields,
                        password: passwordValue
                      })
                      setErrors(updatedErrors)
                    }}
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={eyeon} />
                    ) : (
                      <img alt="" src={eyeoff} />
                    )}
                  </button>
                </div>
                {errors.password ? (
                  <p className="error-text">{errors.password}</p>
                ) : null}
              </p>
              <p>
                <label className="clip-text">Re-enter Password *</label>
                <div className="password-input">
                  <input
                    type={showPassword2 ? 'text' : 'password'}
                    name="confirm_password"
                    placeholder="*********************"
                    required
                    onChange={handleChange}
                  />
                  <button onClick={togglePasswordVisibility2} type="button">
                    {showPassword2 ? (
                      <img alt="" src={eyeon} />
                    ) : (
                      <img alt="" src={eyeoff} />
                    )}
                  </button>
                </div>
                {errors.confirm_password ? (
                  <p className="error-text">{errors.confirm_password}</p>
                ) : null}
              </p>
              <div className="reset_password_btn">
                <p>
                  <button
                    id="resend_btn"
                    className="primary-button m-auto"
                    type="button"
                    onClick={ResendOtp}
                    disabled={!canResend}
                  >
                    <span>
                      {canResend ? 'Resend Otp' : `Resend Otp in ${countdown}s`}
                    </span>
                  </button>
                </p>
                <p>
                  <button
                    id="submit_btn"
                    className="primary-button m-auto"
                    type="submit"
                    disabled={!isButtonEnabled || loading}
                  >
                    <span>Set Password</span>
                    {loading && <span className="spinner" />}
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResetPasswordPage
