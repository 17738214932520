/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import RatingStar from '../../images/rating-start.svg'
import Likes from '../../images/like-fill.svg'
import UnLikes from '../../images/like-empty.svg'
import ClockIco from '../../images/ClockIco.svg'
import HealthIco from '../../images/HealthIco.svg'
import { getVideosLibrary, s3ImagePathCreator } from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import { timeDifference } from '../../helpers/Utility'
import Plus from '../../images/plus-rounded.svg'

const Video = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [videoData, setVideoData] = useState([])

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 0 } })
  }

  const getVideosLibraryData = () => {
    setIsLoading(true)
    getVideosLibrary({}).then((res) => {
      if (res.data.success) {
        setVideoData(dataDecrypt(res.data.data).data)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    getVideosLibraryData()
  }, [])

  return (
    <div className="content-manager-video-wrapper common-card-body">
      <div className="video-wrapper-header common-contnt-wrapper">
        <h2>Videos</h2>
        <div className="" onClick={viewAll}>
          <Link>View All</Link>
        </div>
      </div>
      <div className="video-items">
        {videoData.length === 0 && !isLoading ? (
          <div className="awards-item-video">
            <div className="add-profile-video-cover">
              <button type="button">
                <img src={Plus} alt="add library" />
              </button>
            </div>
            <div className="add-profile-video-detail">
              <p />
              <div className="d-flex align-items-center justify-content-between">
                <span />
                <span />
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
        videoData.slice(0, 4).map((item) => (
          <div className="video-item-detail" key={item.name}>
            <div
              className="video-cover"
              style={{
                background: `url(${s3ImagePathCreator + item.cover_attachment})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            >
              <div className="video_like_bg">
                <button
                  type="button"
                  className="video_like"
                >
                  {item.is_like ? (
                    <img src={Likes} alt="Unlike" />
                        ) : (
                          <img src={UnLikes} alt="Like" />
                        )}
                </button>
              </div>
              <p className="time-duration">
                {item.duration}
              </p>
            </div>
            <div className="video-details video-1">
              <div className="d-flex justify-content-between">
                <h6>
                  <img className="health_icon" src={HealthIco} alt="" />
                  <span className="content-video-title">{item.title}</span>
                </h6>
                <div className="rating">
                  <img src={RatingStar} alt="" />
                  <span>
                    4.9
                  </span>
                </div>
              </div>
              <p>{item.description}</p>
              <div className="other-info">
                <span>
                  <img src={ClockIco} alt="" />
                  {timeDifference(item.created_at)}
                </span>
                <span>{item.other}</span>
              </div>
            </div>
          </div>
        ))
      )}
      </div>
    </div>
  )
}
export default Video
