import React from 'react'
import './Ads.css'
import { Link } from 'react-router-dom'
import AdsHome from './AdsHome'
import ChartContainer from './ChartContainer'
import AdvertisementOption from './AdvertisementOption'

const Ads = () => {
  return (
    <div className="content-manager">
      <div className="ads-home">
        <AdsHome />
      </div>
      <div className="chart-container common-card-body mt-40 recent-video-component services-container">
        <h3 className="d-flex align-items-center justify-content-between">
          Advertisements Summary
          <Link className="border-0">Export Data</Link>
        </h3>
        <p>Here is an overview of your activities for your advertisement.</p>
        <ChartContainer />
      </div>
      <div className="common-card-body mt-40">
        <AdvertisementOption />
      </div>
    </div>
  )
}
export default Ads
