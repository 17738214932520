import React from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../Dropdown/Dropdown'
import Discussions from './Discussions'
import Polls from './Polls'
import AddIco from '../../images/AddIco.svg'
import Giveaway from './Giveaways'

const CommunityDetails = () => {
  return (
    <div>
      <div className="add-video-row ">
        <Link className="disabledDropdown">
          <img src={AddIco} alt="add new" />
          Add New Product
        </Link>
        <div className="items-filter">
          <div className="position-relative input-position">
            <input placeholder="Search Product" className="disabledDropdown" />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="content-filter disabledDropdown"
          />
        </div>
      </div>
      <div className="community-detail-manager">
        <Discussions />
        <Polls />
        <Giveaway />
        {/* <AwardsDetails /> */}
      </div>
    </div>
  )
}
export default CommunityDetails
