import React, { useEffect, useRef, useState } from 'react'
import '../AddAwards/AddAwards.css'
import Plus from '../../images/plus-rounded.svg'
import UploadImg from '../../images/upload.svg'
import './style.css'
import Dropdown from '../Dropdown/Dropdown'

 const AddRooms = () => {
  const [ShowRoomModel, setShowRoomModel] = useState(false)
  const AddRoom = () => {
    setShowRoomModel(true)
  }
  const HandleCancel = () => {
    setShowRoomModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowRoomModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Rooms</h2>
        </div>
        <div className="awards-row">
          <div className="add-deal-item">
            <div className="add-awards-cover add-value">
              <button type="button" className="add-item-btn" onClick={AddRoom}>
                <img alt="" src={Plus} />
              </button>
            </div>
            <div className="add-awards-detail add-rooms-detail">
              <div className="add-rooms-title">
                <p className="add-value" />
                <p className="add-value" />
              </div>
              <ul>
                <li className="add-value" />
                <li className="add-value" />
                <li className="add-value" />
                <li className="add-value" />
                <li className="add-value" />
                <li className="add-value" />
              </ul>
            </div>
          </div>
        </div>
      </div>
      {ShowRoomModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={UploadImg} />
                  </div>
                  <h4>Upload a Photo</h4>
                  <p>
                    Drag and drop your file or click the button below to upload
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>Upload a Photo</span>
                  </button>
                </label>
              </div>
              <div>
                <label>Room Name</label>
                <input placeholder="Enter room name" />
              </div>
              <div className="dropdown-full">
                <label>Room Services</label>
                <Dropdown
                  title="Select services"
                  Data={[
                    { title: 'Please select' },
                    { title: 'Please select' },
                    { title: 'Please select' },
                    { title: 'Please select' },
                    { title: 'Please select' }
                  ]}
                />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button type="button" className="secondary-btn-model">Add</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddRooms
