import React from 'react'
import { Link } from 'react-router-dom'
import RatingStar from '../../images/rating-start.svg'
import RoomService from '../../images/room-service.svg'
import FrreWifi from '../../images/wifi.svg'
import Shower from '../../images/shower.svg'
import Towel from '../../images/towel.svg'
import Iron from '../../images/iron.svg'
import Tv from '../../images/tv.svg'

const Rooms = () => {
  const items = [
    {
      cover: './images/rooms/1.jpg',
      name: 'Room Name',
      review: '424',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    },
    {
      cover: './images/rooms/2.jpg',
      name: 'Room Name',
      review: '424',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    },
    {
      cover: './images/rooms/3.jpg',
      name: 'Room Name',
      review: '424',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    },
    {
      cover: './images/rooms/4.jpg',
      name: 'Room Name',
      review: '424',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component ">
      <h3 className="d-flex align-items-center justify-content-between">
        Rooms
        {' '}
        <Link>View All</Link>
      </h3>
      <div className="recent-hotel-grid">
        {items.map((item) => (
          <div key={item.name} className="hotel--grid-item">
            <div
              className="recent-hotel-cover rooms--cover"
              style={{
                background: `url(${item.cover})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="rating">
                <img alt="" src={RatingStar} />
                <span>{item.rating}</span>
              </div>
            </div>
            <div className="recent-hotel-detail rooms-detail">
              <div className="price--type">
                <div className="name--detail">{item.name}</div>
                <div className="review--detail">
                  {item.review}
                  {' '}
                  Reviews
                </div>
              </div>
              <div className="d-flex justify-content-between pt-3">
                <ul>
                  <li>
                    <img alt="" src={RoomService} />
                    Room Service
                  </li>
                  <li>
                    <img alt="" src={FrreWifi} />
                    Free WiFi
                  </li>
                  <li>
                    <img alt="" src={Shower} />
                    Shower
                  </li>
                </ul>
                <ul>
                  <li>
                    <img alt="" src={Towel} />
                    Towel
                  </li>
                  <li>
                    <img alt="" src={Iron} />
                    Iron
                  </li>
                  <li>
                    <img alt="" src={Tv} />
                    Tv
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Rooms
