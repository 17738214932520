/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const RouteGuards = ({ component }) => {
  const [status, setStatus] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const user = localStorage.getItem('token')
    let userData = localStorage.getItem('userData')
    if (user) {
      userData = JSON.parse(userData)
      if (userData.email_verified === 0) {
        navigate('/otp-verification')
      } else if (userData.business_profile === false) {
        navigate('/user-information')
      } else if (userData.user_type_id === 0) {
        navigate('/create-profile')
      } else {
        navigate('/dashboard')
      }
    }
    setStatus(true)
  }, [component])

  return status ? component : null
}

export default RouteGuards
