import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css/pagination'

const EventCetegory = () => {
  const items = [
    {
      name: 'Workshops',
      image: './images/cetegory/Workshop.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Comedy',
      image: './images/cetegory/Comedy.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Performance',
      image: './images/cetegory/Performance.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Music',
      image: './images/cetegory/Music.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Workshops',
      image: './images/cetegory/Workshop.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Comedy',
      image: './images/cetegory/Comedy.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Performance',
      image: './images/cetegory/Performance.svg',
      text: 'Darmgesundheit'
    },
    {
      name: 'Music',
      image: './images/cetegory/Music.svg',
      text: 'Darmgesundheit'
    }
  ]
  return (
    <div className="event-cetegory common-card-body recent-video-component">
      <h3>Categories</h3>
      <div className="position-relative">
        <Swiper
          pagination={{
            type: 'progressbar'
          }}
          slidesPerView="auto"
          spaceBetween={19}
          navigation
          modules={[Pagination, Navigation]}
          className="event-cetegory position-static"
        >
          {items.map((item) => (
            <SwiperSlide key={item.name}>
              <div className="cetegory-swiper">
                <div>
                  <h2>{item.name}</h2>
                  <p>{item.text}</p>
                </div>
                <img alt="" src={item.image} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
export default EventCetegory
