/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import GroupDiscussion from '../../images/Group-discussion.svg'
import {
  getGiveawaysLibrary,
  giveawayParticipateListData,
  s3ImagePath,
  s3ImagePathCreator
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import CloseIco from '../../images/CloseIco.svg'
import FillStar from '../../images/fill-star.svg'
import EmptyStar from '../../images/EmptyStar.svg'
import Clock from '../../images/clock-blue.svg'
import Dyson from '../../images/dyson.png'
import Pagination from '../Pagination/Pagination'
import { formatTimeLeft } from '../../helpers/Utility'
import Plus from '../../images/plus-rounded.svg'

const GiveawayDetails = () => {
  const navigate = useNavigate()
  const componentRef = useRef()

  const [giveawayProduct, setGiveawayProduct] = useState([])
  const [giveawayList, setGiveawayList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [ShowProfileModel, setShowProfileModel] = useState(false)

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 2 } })
  }

  const close = () => {
    setShowProfileModel(false)
  }

  const showList = () => {
    setShowProfileModel(true)
  }

  const getGiveawayLibraryData = () => {
    setIsLoading(true)
    getGiveawaysLibrary({}).then((res) => {
      if (res.data.success) {
        setGiveawayProduct(dataDecrypt(res.data.data).data)
        const { data } = dataDecrypt(res.data.data)
        if (data.length !== 0) {
          // data.forEach((giveaway) => {
          //   giveawayParticipate(giveaway.giveaway_id)
          // })
          giveawayParticipate(data[0].giveaway_id)
        }
        setIsLoading(false)
      }
    })
  }

  const giveawayParticipate = (giveaway_id) => {
    const platFormData = {
      giveaway_id
    }
    giveawayParticipateListData(platFormData).then((res) => {
      if (res.data.success) {
        setGiveawayList(dataDecrypt(res.data.data))
      }
    })
  }

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const giveawayListData = giveawayList.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = giveawayList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    getGiveawayLibraryData()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowProfileModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="content-manager-awards-wrapper common-card-body community-margin">
      <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
        <h2>Giveaways</h2>
        <div className="" onClick={viewAll}>
          <Link>View All</Link>
        </div>

      </div>

      {giveawayProduct.length === 0 && !isLoading ? (
        <div className="add-giveways-item mb-30">
          <div className="add-giveways-cover">
            <button
              type="button"
              className="add-item-btn"
            >
              <img src={Plus} alt="add giveaway" />
            </button>
          </div>
          <div className="add-giveways-details">
            <h4 className="add-value"> </h4>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li className="add-value">List Item 1</li>
                <li className="add-value">List Item 2</li>
              </ul>
              <span className="add-value">Span Content</span>
            </div>
            <p className="add-value">Paragraph Content</p>
            <h5 className="add-value"> </h5>
          </div>
        </div>
      ) : isLoading ? (
        <div className="add-giveways-item mb-30">
          <div className="add-giveways-cover">
            {/* <Skeleton className="add-giveways-cover" width={502} height={390} /> */}
          </div>
          <div className="add-giveways-details">
            <h4 className="add-value">
              <Skeleton className="add-value" width={155} height={29} />
            </h4>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li className="add-value">
                  <Skeleton className="add-value" width={110} height={29} />
                </li>
                <li className="add-value">
                  <Skeleton className="add-value" width={110} height={29} />
                </li>
              </ul>
              <span className="add-value"><Skeleton className="add-value" width={121} height={29} /></span>
            </div>
            <p className="add-value"><Skeleton className="add-value" width={580} height={110} /></p>
            <h5 className="add-value">
              <Skeleton className="add-value" width={149} height={48} />
            </h5>
          </div>
        </div>

      ) : (
        <div className="add-giveways-container">
          {giveawayProduct.slice(0, 1).map((item, index) => {
           const timeLeftText = formatTimeLeft(item.time_left)
            const isTimeUp = timeLeftText === 'Time is up'
          return (

            <div key={index} className="added-giveways-item position-relative">
              <div className="giveways-item-cover position-relative">
                {item.app_data.length > 0 && (
                <span
                  className={`platform-badge ${
                    item.app_data.length === 2 ? 'platform-badge-alt' : ''
                  }`}
                >
                  {item.app_data.map((data, i) => (
                    <div
                      key={i}
                      className={`badge-cover ${
                        item.app_data.length === 2 ? 'badge-cover-alt' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${s3ImagePath + data.icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '90%'
                      }}
                    />
                  ))}
                </span>
              )}
                <img src={s3ImagePathCreator + item.images[0]} alt="" />
              </div>
              <div className="giveways-item-details">
                <h4>{item.title}</h4>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="giveways-profile-detail">
                    <img className="profile-img-giveway" src={Dyson} alt="" />
                    <span className="giveway-profile-name">
                      {item.name}
                      Jacob Jones
                    </span>
                    <div className="vertical-devider" />
                    <div className="rating-block-container">
                      <img src={FillStar} alt="" />
                      <img src={FillStar} alt="" />
                      <img src={FillStar} alt="" />
                      <img src={FillStar} alt="" />
                      <img src={EmptyStar} alt="" />
                    </div>
                    <span>
                      {item.rating}
                      {' '}
                      4.5 rating
                    </span>
                  </div>
                  <div className="giveway-time-left">
                    <img src={Clock} alt="" />
                    <span>{formatTimeLeft(item.time_left)}</span>
                  </div>
                </div>
                <p>{item.description}</p>
                <div className="d-flex align-items-center gap-2">
                  <button type="button" className="primary-btn" disabled={isTimeUp}>
                    <span>Enter to Win</span>
                  </button>
                  <div className="discussion-group ml-1">
                    {item?.total_participates > 0 &&
                    giveawayList
                      .slice(0, 3)
                      .map((list, index) => (
                        <img
                          key={index}
                          src={s3ImagePath + list.icon}
                          alt={list.user_name}
                        />
                      ))}
                    <img
                      src={GroupDiscussion}
                      alt="Group Discussion"
                      onClick={item.total_participates > 0 ? showList : undefined}
                    />
                  </div>
                  {ShowProfileModel && (
                  <div className="add-item-model edit-profile">
                    <div className="add-item-content" ref={componentRef}>
                      <div className="d-flex justify-content-between header">
                        <h3>Giveaway Participate List</h3>
                        <button
                          type="button"
                          className="border-0 bg-none p-0"
                          onClick={close}
                        >
                          <span>
                            <img className="close-btns" src={CloseIco} alt="" />
                          </span>
                        </button>
                      </div>

                      <div className="scroll-bars">
                        <div className="item-details-input pt-0">
                          <div className="list_data">
                            {giveawayListData.map((list, index) => {
                              const calculatedIndex =
                                (currentPage - 1) * videosPerPage + index + 1
                              return (
                                <div
                                  className="d-flex justify-content-start list_table"
                                  key={index}
                                >
                                  <span className="number_index">
                                    {calculatedIndex}
                                  </span>
                                  <img
                                    src={s3ImagePath + list.icon}
                                    className="profile-pic"
                                    alt="profile"
                                  />
                                  <span className="user-name">
                                    {list.user_name}
                                  </span>
                                  <span className="time-date">
                                    {list.created_at}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      {totalPages > 1 && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                  )}
                    </div>
                  </div>
                )}
                </div>
              </div>
            </div>
          )
        })}
        </div>
      )}

    </div>
  )
}
export default GiveawayDetails
