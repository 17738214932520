import React, { useState } from 'react'
import './Community.css'
import Dropdown from '../Dropdown/Dropdown'
import DownIco from '../../images/DownIco.svg'

const CommunityProfile = () => {
  const items = [
    {
      heading: 'Discussions',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/1.jpg'
    },
    {
      heading: 'Poll',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/2.jpg'
    },
    {
      heading: 'Giveaway',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/3.jpg'
    },
    {
      heading: 'Awards',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/4.jpg'
    },
    {
      heading: 'Discussions',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/1.jpg'
    },
    {
      heading: 'Poll',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/2.jpg'
    },
    {
      heading: 'Giveaway',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/3.jpg'
    },
    {
      heading: 'Awards',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sociis quam eget id sagittis eu proin. Lacus ac eget sed sem diam. Neque at aliquet sem egestas ut. Habitant nec malesuada bibendum tincidunt commodo. In tellus.',
      cover: './images/community/4.jpg'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(4)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  return (
    <div className="Community-profile">
      <div className="drop-row">
        <div className="drop-sub-row">
          <Dropdown
            title="Categories"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
          <Dropdown
            title="Ratings"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
          <Dropdown
            title="Duration"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
        <div className="drop-search">
          <input placeholder="Search here..." />
        </div>
      </div>
      <div className="community-profile-container">
        {items.slice(0, visibleItems).map((item) => (
          <div
            className="community-profile-item mt-40"
            key={item.heading}
            style={{
              background: `url(${item.cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="community-profile-detail">
              <h4>{item.heading}</h4>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={DownIco} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default CommunityProfile
