import React from 'react'
import { Link } from 'react-router-dom'
import RatingStar from '../../images/rating-start.svg'

const Lunch = () => {
  const items = [
    {
      img: './images/lunch/1.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/2.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/3.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/4.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/1.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/2.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/3.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/4.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/1.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/2.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/3.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/4.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/1.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/2.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/3.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    },
    {
      img: './images/lunch/4.jpg',
      rating: '4.9',
      name: 'Eggs and kidney Pie',
      price: '€25'
    }
  ]
  return (
    <div className=" services-container menu-wrapper common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Lunch
        {' '}
        <Link to="/menu" state={items}>
          View All
        </Link>
      </h3>
      <div className="breackfast-grid--item">
        {items
          .filter((item, index) => index < 4)
          .map((item, index) => (
            <div className="menu-grid-item" key={index}>
              <div
                className="menu-item-cover"
                style={{
                  background: `url(${item.img})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="rating">
                  <img alt="" src={RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="menu-item-details d-flex align-items-center justify-content-between">
                <span>{item.name}</span>
                <h4>{item.price}</h4>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
export default Lunch
