import React from 'react'
import './Style.css'
import { Link } from 'react-router-dom'
import call from '../../images/call.svg'
import messenger from '../../images/messanger.svg'
import email from '../../images/email.svg'

const ContactInfo = () => {
  return (
    <div className="services-container common-card-body recent-video-component">
      <h3>Contact Info</h3>
      <div className="contact-info-grid">
        <div className="contact-info-item map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Contact info"
          />
        </div>
        <div className="contact-info-item information common-card-body">
          <ul>
            <li>
              <img alt="" src={call} />
              <Link>+358 25 466 5896</Link>
            </li>
            <li>
              <img alt="" src={messenger} />
              <Link>Send message</Link>
            </li>
            <li className="e-mail">
              <img alt="" src={email} />
              <Link>kenzi.lawson@example.com</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default ContactInfo
