/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState, createRef } from 'react'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Building from '../../images/building.svg'
import ProfileLogo from '../../images/gbn-one.svg'
import Redirect from '../../images/redirect.svg'
// import Desk from '../../images/desk.svg'
// import Tab from '../../images/tab.svg'
// import Mob from '../../images/mob.svg'
// import View from '../../images/view.svg'
import Image from '../../images/image.svg'
import MenuIcon from '../../images/MenuIcon.svg'
import LeftIco from '../../images/LeftIco.svg'
import plusrounded from '../../images/plus-rounded.svg'
import CloseIco from '../../images/CloseIco.svg'
import './style.css'
import {
  editCreatorProfile,
  imageUpload,
  creatorProfileDetails,
  s3ImagePath,
  followerListData,
  followCreator
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import { formatViews } from '../../helpers/Utility'

const ProfileHeadCreator = () => {
  const [profile, setProfile] = useState([])
  const [profileImg, setProfileImg] = useState(null)
  const [profileImgObj, setProfileImgObj] = useState(null)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [profileImgUrl, setProfileImgUrl] = useState(null)
  const [inputFields, setInputFields] = useState({
    icon: '',
    description: '',
    social_media: []
  })
  const [socialMediaLinks, setSocialMediaLinks] = useState([])
  const [allFollowerListData, setAllFollowerListData] = useState([])
  const [showFollowerList, setShowFollowList] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isFollowerListLoading, setIsFollowerListLoading] =
    useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const componentRef = useRef()

  const profileGet = async () => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id
    }
    await creatorProfileDetails(platFormData).then((res) => {
      if (res?.data?.success) {
        const data = dataDecrypt(res.data.data)
        localStorage.setItem(
          'profileData',
          JSON.stringify(data[0])
        )
        allFollowerList(data[0]?.user_id)
        if (data[0]) {
          const socialMediaLinks = Array.isArray(data[0]?.social_media_links)
            ? data[0].social_media_links
            : []

          setProfile(data)
          setInputFields({
            icon: data[0]?.profile_icon,
            description: data[0]?.description,
            social_media: socialMediaLinks
          })
          setSocialMediaLinks(socialMediaLinks)
          setProfileImg(data[0]?.profile_icon)
        }
        setIsLoading(false)
      }
    })
  }

  const clearValue = () => {
    getStoreLink('')
    setProfileImgUrl()
  }

  const handleInputChange = (index, value) => {
    const updatedLinks = [...socialMediaLinks]
    updatedLinks[index] = value
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
  }

  const EditProfile = (profileData) => {
    setShowProfileModel(true)
    setInputFields({
      ...inputFields,
      description: profileData.description,
      store_link: profileData.store_link,
      icon: profileData.profile_icon
    })
    setProfileImg(profileData.profile_icon)
  }

  const base64ToBlob = (base64, contentType = '') => {
    const byteCharacters = atob(base64)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  // Convert Blob to File
  const blobToFile = (blob, fileName) => {
      return new File([blob], fileName, { type: blob.type })
  }

  const handleConvert = (base64String) => {
    // Assume the base64 string is a data URL, so we need to remove the prefix
    const base64 = base64String.split(',')[1]
    const contentType = 'image/jpeg' // Adjust this based on the image type

    const blob = base64ToBlob(base64, contentType)
    const file = blobToFile(blob, 'image.jpg') // Use a proper file name=
    return file
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0]

    if (file) {
      const imgUrl = URL.createObjectURL(file)
      setProfileImg(file)
      setProfileImgUrl(imgUrl)
      setIsEdited(true)
      setCropData(null)
    }
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsEdited(true)
  }

  const HandleCancel = () => {
    setShowProfileModel(false)
    setIsEdited(false)
    clearValue()
  }

  const addInputField = () => {
    const updatedLinks = [...socialMediaLinks, '']
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
    setIsEdited(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    finishSubmit()
    setShowProfileModel(false)
    clearValue()
  }

  const finishSubmit = () => {
    if (profileImgObj) {
      imageUpload(profileImgObj).then((res) => {
        if (res.data.success) {
          inputFields.icon = dataDecrypt(res.data.data)
          creatorProfile()
        } else {
          toast.error(res?.data?.message)
        }
      })
    } else {
      creatorProfile()
    }
  }

  const creatorProfile = () => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      icon: inputFields.icon,
      description: inputFields?.description,
      social_media: inputFields?.social_media
    }
    editCreatorProfile(platFormData, inputFields).then((res) => {
      if (res.data.success) {
        profileGet()
        toast.success(res?.data?.message)
        setIsEdited(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }


  const handleDeleteLink = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index)
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
  }

  const allFollowerList = (user_id) => {
    setIsFollowerListLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platform = {
      app_id: parsedData.id,
      creator_user_id: user_id
    }
    followerListData(platform).then((res) => {
      if (res.data.success) {
        setAllFollowerListData(dataDecrypt(res.data.data))
        setIsFollowerListLoading(false)
      }
    })
  }
  const followCreatorData = (user_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platform = {
      app_id: parsedData.id,
      creator_user_id: user_id
    }
    followCreator(platform).then((res) => {
      if (res.data.success) {
        setIsFollowing(!isFollowing)
        toast.success(res?.data?.message)
      }
    })
  }

  const followOpen = () => {
    setShowFollowList(true)
  }

  const handleCancel = () => {
    setShowFollowList(false)
  }

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const followerList = allFollowerListData.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = allFollowerListData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const displayedFollowers = allFollowerListData.slice(0, 4)
  const displayedNames = allFollowerListData.slice(0, 2)

  useEffect(() => {
    profileGet()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowProfileModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const cropperRef = createRef(null)
  const [cropData, setCropData] = useState(null)

  const getCropData = () => {
    if (cropData) {
      setCropData(null)
    } else if (typeof cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
      const file = handleConvert(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
      setProfileImgObj(file)
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="profile-head-builder">
          <div className="profile-builder-header mb-20">
            <button type="button">
              <Skeleton width={25} height={25} />
            </button>
            <div className="profile-heading">
              <Skeleton width={35} height={35} />
              <span>
                <Skeleton width={150} height={25} />
              </span>
            </div>
            <button type="button">
              <Skeleton width={25} height={25} />
            </button>
          </div>

          <div className="profile-builder-main">
            <div className="profile-personal-detail">
              <Skeleton className="profile_ske_icon" />
              <div className="about-personal-profile">
                <h2><Skeleton width={150} height={20} /></h2>
                <h3><Skeleton width={180} height={20} /></h3>
                <p><Skeleton width={150} height={20} /></p>
                <span><Skeleton width={130} height={20} /></span>
              </div>
            </div>
            <div className="profile-account-details">
              <ul className="about-account-insignt">
                <li>
                  <h4><Skeleton width={20} /></h4>
                  <p><Skeleton width={50} height={20} /></p>
                </li>
                <li>
                  <h4><Skeleton width={20} /></h4>
                  <p
                    className="Follower_text"
                  >
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
                <li>
                  <h4><Skeleton width={20} /></h4>
                  <p><Skeleton width={50} height={20} /></p>
                </li>
              </ul>
              <div className="follow-connenct">
                <div className="buttons-group">
                  <Skeleton className="rounded-5 profile-skeleton-btn" height={40} />
                  <Skeleton className="rounded-5 profile-skeleton-btn" height={40} />
                </div>
                <div>
                  <Skeleton className="rounded-5" width={60} height={60} />
                </div>
              </div>
              <div className="follwer-shows">
                <div className="follower-img-group d-flex">
                  <Skeleton className="rounded-5 skeleton-img-group" width={30} height={30} />
                  <Skeleton className="rounded-5 skeleton-img-group" width={30} height={30} />
                  <Skeleton className="rounded-5 skeleton-img-group" width={30} height={30} />
                  <Skeleton className="rounded-5 skeleton-img-group" width={30} height={30} />
                </div>
                <p>
                  <span className="gredient-txt">
                    <Skeleton className="follow-text" height={20} />
                  </span>
                </p>
              </div>
              <div className="profile-redirect">
                <Skeleton width={25} height={25} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            <div className="profile-builder-header mb-20">
              <button type="button">
                <img alt="" src={LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={MenuIcon} />
              </button>
            </div>

            <div className="profile-builder-main">
              <div className="profile-personal-detail">
                <div className="profile-build-image">
                  <img alt="" src={s3ImagePath + data.profile_icon} />
                </div>
                <div className="about-personal-profile">
                  <h2>{data.user_name}</h2>
                  <h3>FITNESS & GESUNDHEIT</h3>
                  <p>{data.description}</p>
                  <span>Your Personal Trainer</span>
                </div>
              </div>
              <div className="profile-account-details">
                <ul className="about-account-insignt">
                  <li>
                    <h4>{formatViews(data.total_post)}</h4>
                    <p>Posts</p>
                  </li>
                  <li>
                    <h4>{formatViews(data.total_followers)}</h4>
                    {/* <p className="Follower_text" onClick={() => allFollowerList(data.user_id)}>Followers</p> */}
                    <p
                      className="Follower_text"
                      onClick={() => followOpen(data.user_id)}
                    >
                      Followers
                    </p>
                  </li>
                  <li>
                    <h4>{formatViews(data.total_likes)}</h4>
                    <p>Likes</p>
                  </li>
                </ul>
                {showFollowerList && (
                  <div className="add-item-model edit-profile">
                    <div className="add-item-content">
                      <div className="d-flex justify-content-between header">
                        <h3>Follower List</h3>
                        {/* <div className="items-filter">
                        <div className="position-relative input-position">
                          <input
                            placeholder="Search Follower"
                              onChange={(e) => {
                              handleSearch(e.target.value)
                            }}
                          />
                        </div>
                      </div> */}
                        <button
                          type="button"
                          className="border-0 bg-none p-0"
                          onClick={handleCancel}
                        >
                          <span>
                            <img className="close-btns" src={CloseIco} alt="" />
                          </span>
                        </button>
                      </div>

                      <div className="scroll-bars feed-scroll">
                        <div className="item-details-input pt-0">
                          <div className="list_data">
                            {isFollowerListLoading ? (
                               Array(6)
                                .fill(0)
                                .map((_, index) => (
                                  <div className="d-flex justify-content-start list_table" key={index}>
                                    <span className="number_index">
                                      <Skeleton
                                        className="rounded-3"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    <Skeleton
                                      circle
                                      width={45}
                                      height={45}
                                    />
                                    <span className="user-name">
                                      <Skeleton
                                        className="rounded-3"
                                        width={200}
                                        height={25}
                                      />
                                    </span>
                                  </div>
                                ))
                          ) : (
                            followerList.length > 0 ? (
                              followerList.map((item, index) => {
                                const calculatedIndex =
                                (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImagePath + item.user_profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {item.user_name}
                                    </span>
                                  </div>
                                )
                            })
                            ) : (
                              <div className="no-data-message">
                                No one follows you
                              </div>
                            )
                          ) }
                          </div>
                        </div>
                      </div>
                      {totalPages > 1 && (
                        <div className="pagination-containers-list">
                          <button
                            type="button"
                            className={`pagination-button ${
                              currentPage === 1 ? 'disabled' : ''
                            }`}
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                          >
                            Prev
                          </button>
                          {renderPaginationButtons()}
                          <button
                            type="button"
                            className={`pagination-button ${
                              currentPage === totalPages ? 'disabled' : ''
                            }`}
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="follow-connenct">
                  <div className="buttons-group">
                    <button
                      type="button"
                      className="success-btn"
                      onClick={() => followCreatorData(data.user_id)}
                    >
                      {isFollowing ? 'Follow +' : 'Unfollow'}
                    </button>
                    <button type="button" className="primary-btn p-10">
                      Contact
                    </button>
                  </div>
                  <div>
                    <img alt="" src={ProfileLogo} />
                  </div>
                </div>
                <div className="follwer-shows">
                  <div className="follower-img-group">
                    {displayedFollowers.map((follower, index) => (
                      <img
                        key={index}
                        alt={`Follower ${index + 1}`}
                        src={s3ImagePath + follower.user_profile_icon}
                      />
                    ))}
                  </div>
                  <p>
                    <span className="gredient-txt">
                      {displayedNames.map((follower, index) => (
                        <React.Fragment key={index}>
                          <b>{follower.user_name}</b>
                          {index < displayedNames.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                      {' und '}
                      <b>
                        {allFollowerListData.length - 2}
                        {' '}
                        weitere Personen
                      </b>
                      {' '}
                      sind Follower
                    </span>
                  </p>
                </div>
                <div className="profile-redirect">
                  <a
                    className="gredient-txt"
                    target="_blank"
                    href={getStoreLink(data?.social_media_links?.[0])}
                    rel="noreferrer"
                  >
                    <img alt="" src={Redirect} />
                    {data?.social_media_links?.[0]
                      ? data.social_media_links?.[0]
                      : 'No link'}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {ShowProfileModel && (
        <div className="add-item-model edit-profile">
          <div className="add-item-content edit-creator px-0 pb-0" ref={componentRef}>
            <h3 className="text-center">Edit Creator Profile</h3>
            <form onSubmit={handleSubmit}>
              <div className="scroll-bars position-relative pb-2">
                <div
                  className="profile-image "
                  style={{
                    backgroundImage: `url(${
                      cropData || s3ImagePath + profileImg
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <label className="camera_icon" htmlFor={`profile${1}`}>
                    <img alt="" src={Image} />
                  </label>
                  <input
                    type="file"
                    id={`profile${1}`}
                    name="icon"
                    onChange={handleImageUpload}
                  />
                </div>
                {profileImgUrl && (
                  <>
                    {!cropData && (
                      <Cropper
                        ref={cropperRef}
                        style={{ height: 400, width: '100%' }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        src={profileImgUrl}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive
                        autoCropArea={1}
                        checkOrientation={false}
                        guides
                      />
                    )}
                    <button type="button" className="crop-btn" onClick={getCropData}>
                      {!cropData ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-crop" viewBox="0 0 16 16">
                          <path d="M3.5.5A.5.5 0 0 1 4 1v13h13a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2H3.5a.5.5 0 0 1-.5-.5V4H1a.5.5 0 0 1 0-1h2V1a.5.5 0 0 1 .5-.5m2.5 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4H6.5a.5.5 0 0 1-.5-.5" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      )}
                    </button>
                  </>
                )}
                <div className="item-details-input pt-0">
                  <div>
                    <label>Decription</label>
                    <textarea
                      placeholder="Type here"
                      onChange={handleChange}
                      name="description"
                      value={inputFields.description}
                    />
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Social Media Link</label>
                      {socialMediaLinks && socialMediaLinks.length > 0 ? (
                        socialMediaLinks.map((link, index) => (
                          <div
                            key={index}
                            className="item-details-input p-0 d-flex gap-3"
                          >
                            <input
                              className="mb-2"
                              placeholder="Paste here"
                              value={link}
                              onChange={(e) => handleInputChange(index, e.target.value)}
                            />
                            <button
                              className="delete_icon"
                              type="button"
                              onClick={() => handleDeleteLink(index)}
                            />
                          </div>
                        ))
                      ) : (
                        <p>No Link</p>
                      )}
                      <button
                        className="secondary-button mt-2"
                        onClick={addInputField}
                        type="button"
                      >
                        <img alt="" src={plusrounded} />
                        <span>Add another</span>
                      </button>
                    </div>
                  </div>
                  <div className="button-row edit-model">
                    <button
                      className="secondary-btn-model-store"
                      type="button"
                      onClick={HandleCancel}
                    >
                      <span>Cancel</span>
                    </button>
                    <button
                      className="primary-btn-model-store"
                      type="submit"
                      disabled={!isEdited}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
export default ProfileHeadCreator
