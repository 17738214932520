import React, { useState } from 'react'
import EventHome from './EventHome'
import DownIco from '../../images/DownIco.svg'

const Tickets = () => {
  const [visibleItems, setVisibleItems] = useState(4)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2)
  }
  const items = [
    {
      cover: './images/event/t1.jpg',
      qr: './images/qr.png',
      name: 'MAYDAY Dortmund',
      price: '$345.00',
      text: 'Unter den Linden 2',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t2.jpg',
      qr: './images/qr.png',
      name: 'Time Warp DE',
      price: '$205.00',
      text: 'Kantstraße 75',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t1.jpg',
      qr: './images/qr.png',
      name: 'MAYDAY Dortmund',
      price: '$345.00',
      text: 'Unter den Linden 2',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t2.jpg',
      qr: './images/qr.png',
      name: 'Time Warp DE',
      price: '$205.00',
      text: 'Kantstraße 75',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t1.jpg',
      qr: './images/qr.png',
      name: 'MAYDAY Dortmund',
      price: '$345.00',
      text: 'Unter den Linden 2',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t2.jpg',
      qr: './images/qr.png',
      name: 'Time Warp DE',
      price: '$205.00',
      text: 'Kantstraße 75',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t1.jpg',
      qr: './images/qr.png',
      name: 'MAYDAY Dortmund',
      price: '$345.00',
      text: 'Unter den Linden 2',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    },
    {
      cover: './images/event/t2.jpg',
      qr: './images/qr.png',
      name: 'Time Warp DE',
      price: '$205.00',
      text: 'Kantstraße 75',
      location: '32756 Detmold Innenstadt ',
      date: '5 march, 2021',
      time: '10:00 PM'
    }
  ]
  return (
    <div className="tickets-event">
      <EventHome />
      <div className="mt-30 common-card-body recent-video-component">
        <div className="d-flex align-items-center justify-content-between mb-30">
          <h3>Tickets</h3>
          <div className="drop-search">
            <input placeholder="Search here..." />
          </div>
        </div>
        <div className="ticket-grid ">
          {items.slice(0, visibleItems).map((item) => (
            <div key={item.name} className="ticket-item">
              <div
                className="ticket-details"
                style={{
                  background: `url(${item.cover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="position-relative z-1 ticket--details">
                  <h3>{item.name}</h3>
                  <h4>{item.price}</h4>
                  <h5>{item.text}</h5>
                  <span>Location</span>
                  <h6>{item.location}</h6>
                  <div className="d-flex align-items-center ticket-footer">
                    <div className="w-50">
                      <span>Date</span>
                      <h6>{item.date}</h6>
                    </div>
                    <div className="w-50">
                      <span>Time</span>
                      <h6>{item.time}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ticket-qr">
                QR Code
                <img alt="" src={item.qr} />
              </div>
            </div>
          ))}
        </div>
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={DownIco} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Tickets
