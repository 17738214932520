/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import Comment from '../../images/ls_comments.svg'
import IconsMore from '../../images/akar-icons_more-horizontal-fill.svg'
import GroupDiscussion from '../../images/Group-discussion.svg'
import PlusTag from '../../images/Plus-tag.svg'
import AddIco from '../../images/AddIco.svg'
import Remove from '../../images/remove.svg'
import Delete from '../../images/delete.svg'
import Likes from '../../images/like-fill.svg'
import UnLikes from '../../images/like-empty.svg'
import {
  addDiscussionData,
  deleteDiscussionData,
  discussionAddReplyData,
  discussionDeleteReplyData,
  discussionEditReplyData,
  discussionLikeData,
  discussionReplyData,
  editDiscussionData,
  getDiscussionLibrary,
  s3ImagePath
} from '../../services/Service'
import PlatformTag from './PlatformTag'
import { dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import { capitalizeFirstLetter, timeDifference } from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import Plus from '../../images/plus-rounded.svg'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'

const Discussions = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  const componentRef = useRef()
  const [ShowDiscussionModel, setShowDiscussionModel] = useState(false)
  const [ShowPreviewDiscussionModel, setShowPreviewDiscussionModel] =
    useState(false)
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    description: '',
    tags: []
  })
  const [isEditing, setIsEditing] = useState(false)
  const [discussionData, setDiscussionData] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [delDiscussionDataId, setDelDiscussionId] = useState()
  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [tags, setTags] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const HandleCancel = () => {
    clearValue()
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
    setIsEditing(false)
  }
  const HandleDiscussionPereview = () => {
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(true)
  }
  const HandlePublish = () => {
    if (isEditing) {
      editDiscussionDataApi()
    } else {
      addDiscussionDataSubmit()
    }
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
  }

  const ShowCommunityGiveaway = () => {
    setShowDiscussionModel(true)
  }

  const ShowConfirm = (discussion_id) => {
    setDelDiscussionId(discussion_id)
    setShowConfirmModel(true)
  }

  const updateInputFieldsTags = (tagsArray) => {
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      tags: tagsArray
    }))
  }

  const handleAddTag = () => {
    if (tags.length < 4) {
      const newTag = `tag ${tags.length + 1}`
      setTags([...tags, newTag])
      updateInputFieldsTags([...tags, newTag])
    } else {
      alert('Maximum 4 tags allowed.')
    }
  }

  const handleRemoveTag = (tagIndex) => {
    const updatedTags = [...tags]
    updatedTags.splice(tagIndex, 1)
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  const handleEditTag = (tagIndex) => {
    setEditingIndex(tagIndex)
  }

  const handleTagChange = (e, tagIndex) => {
    const updatedTags = [...tags]
    updatedTags[tagIndex] = e.target.value
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  const handleTagBlur = () => {
    setEditingIndex(null)
  }

  const handleCancel = () => {
    setShowConfirmModel()
    clearValue()
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      topic: '',
      description: '',
      app_id: [],
      tags: []
    })
    setTags([])
    setPlatformTags([])
  }

  const getDiscussionLibraryData = () => {
    setIsLoading(true)
    getDiscussionLibrary({}).then((res) => {
      if (res.data.success) {
        setDiscussionData(dataDecrypt(res.data.data).data)
        setIsLoading(false)
      }
    })
  }

  const addDiscussionDataSubmit = () => {
    setLoading(true)
    addDiscussionData(inputFields).then((res) => {
      if (res.data.success) {
        setShowPreviewDiscussionModel(false)
        getDiscussionLibraryData()
        setIsEditing(false)
        setLoading(false)
        clearValue()
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const deleteDiscussion = () => {
    const data = {
      discussion_id: delDiscussionDataId
    }
    setLoading(true)
    deleteDiscussionData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getDiscussionLibraryData()
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const editDiscussionDataApi = () => {
    const data = {
      topic: inputFields.topic,
      description: inputFields.description,
      app_id: Platformtags.map((d) => d.id),
      discussion_id: inputFields.discussion_id,
      tags: inputFields.tags
    }
    editDiscussionData(data).then((res) => {
      if (res.data.success) {
        setIsEditing(false)
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
        getDiscussionLibraryData()
      }
    })
  }

  const onClickEditDiscussion = (videoData) => {
    setShowDiscussionModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      discussion_id: videoData.discussion_id,
      tags: videoData.tags
    })
    setInitialValues({
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      discussion_id: videoData.discussion_id,
      tags: videoData.tags
    })
    setPlatformTags(videoData.app_data)
    setTags(videoData.tags)
  }

  const validateForm = () => {
    const { topic, description, tags } = inputFields
    if (topic && description && Array.isArray(tags) && tags.length > 0) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  const handleViewAll = () => {
    setShowAll(!showAll)
  }
  const displayedDiscussion = showAll
    ? discussionData
    : discussionData.slice(0, 3)


  // discussions comment

  const onClickComment = (discussion_id) => {
    setSelectedId(discussion_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      discussionReply(discussion_id)
      setCommentSection(true)
    }
  }

  const discussionReply = (discussion_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: [parsedData.id],
      discussion_id
    }
    discussionReplyData(platFormData).then((res) => {
      if (res.data.success) {
        const dataComment = dataDecrypt(res.data.data).data.map((d, i) => ({
          id: i + 1,
          text: d.reply,
          discussion_id: d.discussion_id,
          is_like: d.is_like,
          reply_id: d.reply_id,
          total_like: d.total_like,
          replies: d.reply_data.map((subData, subIndex) => ({
            id: subIndex + 1,
            text: subData.reply,
            reply_id: subData.reply_id,
            total_like: subData.total_like,
            is_like: subData.is_like,
            user: {
              name: subData.user_name,
              profilePic: s3ImagePath + subData.profile_icon
            }
          })),
          user: {
            name: d.user_name,
            profilePic: s3ImagePath + d.profile_icon
          }
        }))
        setComments(dataComment)
        setIsLoadingComments(false)
      }
    })
  }

  const discussionAddComment = (reply) => {
    const platFormData = {
      discussion_id: selectedId,
      reply
    }
    discussionAddReplyData(platFormData).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
      }
    })
  }

  const discussionAddReply = (reply_id, replyText) => {
    const platFormData = {
      discussion_id: selectedId,
      reply_id,
      reply: replyText
    }
    discussionAddReplyData(platFormData).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
      }
    })
  }
  const discussionEditComment = (reply_id, reply) => {
    const platFormData = {
      reply_id,
      reply
    }
    discussionEditReplyData(platFormData).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
      }
    })
  }

  const discussionEditReply = (reply_id, replyId) => {
    const platFormData = {
      reply_id,
      reply: replyId
    }
    discussionEditReplyData(platFormData).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
      }
    })
  }

  const deleteDiscussionComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      reply_id
    }
    discussionDeleteReplyData(data).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }
  const deleteDiscussionReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      reply_id: replyId
    }
    discussionDeleteReplyData(data).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const discussionCommentLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      reply_id
    }
    discussionLikeData(platFormData).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const discussionReplyLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      reply_id
    }
    discussionLikeData(platFormData).then((res) => {
      if (res.data.success) {
        discussionReply(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const handleAddComment = (reply, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        discussion_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    discussionAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    discussionCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    discussionReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImagePath + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    discussionAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deleteDiscussionReply(replyId)
  }

  const toggleReplies = (commentId) => {
    setShowReplies((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId]
    }))
  }

  const handleCommentCancel = () => {
    getDiscussionLibraryData()
    setCommentSection(false)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    discussionEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    discussionEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  // discussion comments

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const commentsList = comments.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = comments.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    getDiscussionLibraryData()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowDiscussionModel(false)
        setShowPreviewDiscussionModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  return (
    <div className="content-manager-discussions-wrapper common-card-body community-margin">
      <div className="discussions-wrapper-header common-contnt-wrapper">
        <h2>Discussions</h2>
        <Link className="link" onClick={ShowCommunityGiveaway}>
          <img alt="" src={AddIco} />
          New Discussions
        </Link>
      </div>

      {displayedDiscussion.length === 0 && !isLoading ? (
        <div className="add-discussions-container">
          <div className="add-discussions-item">
            {/* <h5 className="giveaways_text">No Discussion Available</h5> */}
            <div className="disscussion-item-profile-detail">
              <div className="disscussion-profile-img add-value" />
              <div className="disscussion-profile-txt">
                <h2 className="add-value">Title</h2>
                <h3 className="add-value">-</h3>
                <p className="add-value">Decription</p>
              </div>
            </div>
            <div className="disscussion-item-other">
              <div className="disscussion-group-member add-value" />
              <div className="disscussion-chat add-value" />
              <div className="position-relative">
                <button
                  type="button"
                  className="add-item-btn"
                  onClick={ShowCommunityGiveaway}
                >
                  <img src={Plus} alt="add discussion" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="discussions-item position-relative">
          <div className="discussion-profile">
            <Skeleton className="discssion-profile-image border-0" />
            <div className="discssion-detail">
              <h5>
                <Skeleton max-width={700} height={20} />
              </h5>
              <span className="mb-3">
                <Skeleton max-width={200} height={10} />
              </span>
              <p>
                <Skeleton max-width={700} height={30} />
              </p>
            </div>
          </div>
          <div className="discussion-group">
            <Skeleton circle width={30} height={30} />
          </div>
          <div className="discussion-comment">
            <Skeleton width={25} height={25} />
            <Skeleton width={25} />
          </div>
          <div className="discussion-button">
            <button type="button">
              <Skeleton width={10} height={35} />
            </button>
          </div>
        </div>
      ) : (
        displayedDiscussion.map((item, index) => (
          <div className="discussions-item position-relative" key={item.Title}>
            <div className="discussion-profile">
              <div
                className="discssion-profile-image"
                style={{
                  background: `url(${s3ImagePath + userData.profile_image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
              <div className="discssion-detail">
                <h5>{item.topic}</h5>
                <span>
                  <b>{userData.user_name}</b>
                  {' '}
                  {timeDifference(item.created_at)}
                </span>
                <p>{item.description}</p>
              </div>
            </div>
            <div className="discussion-group">
              {/* {item?.groupImg.map((img, index) => (
              <img alt=""key={index} src={img} />
            ))} */}
              <img alt="" src={GroupDiscussion} />
            </div>
            <div
              className="discussion-comment"
              onClick={() => onClickComment(item.discussion_id)}
            >
              <img alt="" src={Comment} />
              {item.total_replies}
            </div>
            <div className="discussion-button">
              <button type="button">
                <img alt="" src={IconsMore} />
              </button>
            </div>
            {item.app_data.length > 0 && (
              <span
                className={`platform-badge ${
                  item.app_data.length === 2 ? 'platform-badge-alts' : ''
                }`}
              >
                {item.app_data.map((data, i) => (
                  <div
                    key={i}
                    className={`badge-cover ${
                      item.app_data.length === 2 ? 'badge-cover-alts' : ''
                    }`}
                    style={{
                      backgroundImage: `url(${s3ImagePath + data.icon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '90%'
                    }}
                  />
                ))}
              </span>
            )}
            <div className="image-buttons z-1">
              <label>
                <button
                  type="button"
                  onClick={() => onClickEditDiscussion(item)}
                  className="d-none"
                />
              </label>
              <button
                type="button"
                className="p-0"
                onClick={() => {
                  setUpdatingIndex(index)
                  ShowConfirm(item.discussion_id)
                }}
              />
            </div>
            <ConfirmationModal
              isVisible={ShowConfirmModel && updatingIndex === index}
              onConfirm={deleteDiscussion}
              onCancel={handleCancel}
              message="Do you want to delete this?"
              isLoading={loading}
            />
            {loading && updatingIndex === index && <Loader />}
          </div>
        ))
      )}

      {commentSection && (
        <div className="add-item-modal mh-100">
          <div className="add-item-contents">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">Discussions Replays</h4>
              <button
                type="button"
                className="secondary-btn-modal"
                onClick={handleCommentCancel}
              >
                <img className="close-btns" src={CloseIco} alt="clock icon" />
              </button>
            </div>
            <div className="comment-section">
              {isLoadingComments ? (
                <div>
                  <div className="comments-container">
                    <ul className="comments-list">
                      <li className="comment-item">
                        <button type="button" className="comment-delete">
                          <Skeleton
                            className="rounded-3"
                            width={20}
                            height={20}
                          />
                        </button>
                        <div className="comment-header mb-0 gap-2">
                          <Skeleton circle width={45} height={45} />
                          <span className="user-name">
                            <Skeleton width={150} height={20} />
                          </span>
                        </div>
                        <div className="comment-btns">
                          <button type="button">
                            <Skeleton width={20} height={20} />
                          </button>
                          <Skeleton width={20} height={20} />
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder="Add a comment..."
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      onClick={() => handleAddComment(newComment)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="comments-container">
                    {commentsList.length === 0 ? (
                      <div className="no-comments-message">No Comments Yet</div>
                    ) : (
                      <ul className="comments-list">
                        {commentsList.map((comment, index) => (
                          <li key={comment.id} className="comment-item">
                            {isDeleting && (
                              <div className="loader-overlay">
                                {commentIndex === index && <Loader />}
                              </div>
                            )}
                            {comment.user.name === userData.user_name && (
                              <button
                                type="button"
                                className="comment-delete"
                                onClick={() => {
                                  setCommentIndex(index)
                                  deleteDiscussionComment(comment.reply_id)
                                }}
                              >
                                <img src={Delete} alt="delete icon" />
                              </button>
                            )}
                            <div className="comment-header mb-0">
                              <img
                                src={comment.user.profilePic}
                                alt={`${comment.user.name}'s profile`}
                                className="profile-pic"
                              />
                              <span className="user-name">
                                {comment.user.name}
                              </span>
                            </div>
                            {editCommentId === comment.id ? (
                              <div className="edit-reply">
                                <input
                                  type="text"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSaveComment(
                                      comment.reply_id,
                                      editedCommentText
                                    )}
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              <div className="comment-text">{comment.text}</div>
                            )}
                            <div className="comment-btns">
                              <button
                                type="button"
                                onClick={() => handleLikeComment(comment.reply_id)}
                              >
                                {comment.is_like ? (
                                  <img src={Likes} alt="Unlike" />
                                ) : (
                                  <img src={UnLikes} alt="Like" />
                                )}
                                {' '}
                                {comment.total_like}
                              </button>
                              {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  onClick={() => handleEditComment(comment.id, comment.text)}
                                >
                                  Edit
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => toggleReplies(comment.id)}
                              >
                                {showReplies[comment.id] ? 'Hide' : 'Show'}
                                {' '}
                                Replies
                              </button>
                            </div>
                            {showReplies[comment.id] && (
                              <ul className="replies-list">
                                {comment.replies.map((reply) => (
                                  <li key={reply.id} className="reply-item">
                                    <div className="reply-header mb-0">
                                      <img
                                        src={reply.user.profilePic}
                                        alt={`${reply.user.name}'s profile`}
                                        className="profile-pic"
                                      />
                                      <span className="user-name">
                                        {reply.user.name}
                                      </span>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setCommentIndex(index)
                                            handleDeleteReply(
                                              comment.reply_id,
                                              reply.reply_id
                                            )
                                          }}
                                          className="comment-delete"
                                        >
                                          <img src={Delete} alt="delete icon" />
                                        </button>
                                      )}
                                    </div>
                                    {editReplyId === reply.id ? (
                                      <div className="edit-reply">
                                        <input
                                          type="text"
                                          value={editedReplyText}
                                          onChange={(e) => setEditedReplyText(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleSaveReply(
                                              reply.reply_id,
                                              editedReplyText
                                            )}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="reply-text">
                                        {reply.text}
                                      </div>
                                    )}
                                    <div className="comment-btns">
                                      <button
                                        type="button"
                                        onClick={() => handleLikeReply(reply.reply_id)}
                                      >
                                        {reply.is_like ? (
                                          <img src={Likes} alt="Unlike" />
                                        ) : (
                                          <img src={UnLikes} alt="Like" />
                                        )}
                                        {' '}
                                        {reply.total_like}
                                      </button>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => handleEditReply(
                                              reply.id,
                                              reply.text
                                            )}
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                                <li className="add-reply">
                                  <input
                                    type="text"
                                    placeholder="Reply..."
                                    value={Replied}
                                    onChange={(e) => setShowReplied(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleAddReply(comment.reply_id, Replied)}
                                  >
                                    Reply
                                  </button>
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder="Add a comment..."
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    {commentsList.length === 0 && (
                      <button
                        type="button"
                        onClick={() => handleAddComment(newComment, '', '')}
                      >
                        Add
                      </button>
                    )}
                    {commentsList.map((comment, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleAddComment(
                            newComment,
                            comment.user.profilePic,
                            comment.user.name
                          )}
                      >
                        Add
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {ShowDiscussionModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Enter Discussions Detail</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img alt="" className="close-btns" src={CloseIco} />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                <div>
                  <label>Topic Statement</label>
                  <input
                    placeholder="Type here"
                    name="topic"
                    maxLength={70}
                    onChange={handleChange}
                    value={capitalizeFirstLetter(inputFields.topic)}
                  />
                </div>

                <div>
                  <label>Decription</label>
                  <textarea
                    placeholder="Type here"
                    name="description"
                    onChange={handleChange}
                    maxLength={150}
                    value={capitalizeFirstLetter(inputFields.description)}
                  />
                </div>
                <div className="add-tag">
                  <span>Add tag</span>
                  <div className="d-flex flex-wrap column-gap-20">
                    <ul>
                      {tags.map((tag, index) => (
                        <li
                          key={index}
                          className={`tag-item ${
                            editingIndex === index ? 'editing' : ''
                          }`}
                        >
                          {editingIndex === index ? (
                            <div className="tag-edit-container">
                              <input
                                type="text"
                                value={tag}
                                onChange={(e) => handleTagChange(e, index)}
                                onBlur={handleTagBlur}
                                ref={(input) => input && input.focus()}
                                className="edit-input"
                              />
                            </div>
                          ) : (
                            <>
                              {tag}
                              <button
                                type="button"
                                className="remove-tag"
                                onClick={() => handleRemoveTag(index)}
                              >
                                <img src={Remove} alt="Remove" />
                              </button>
                              <button
                                type="button"
                                className="edit-tag"
                                onClick={() => handleEditTag(index)}
                              />
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                    {tags.length < 4 && (
                      <button
                        type="button"
                        className="add-tag-btns"
                        onClick={handleAddTag}
                      >
                        <img src={PlusTag} alt="Add Tag" />
                        Add Tag
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="item-details-input mt-3">
                <div className="button-row">
                  <button
                    type="button"
                    className="secondary-btn-model"
                    onClick={HandleCancel}
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="primary-btn-model"
                    disabled={!isFormComplete}
                    onClick={HandleDiscussionPereview}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowPreviewDiscussionModel && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="item-details-input pt-0">
              <div className="d-flex justify-content-between header">
                <h3>Enter Discussions Detail</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleCancel}
                >
                  <span>
                    <img alt="" className="close-btns" src={CloseIco} />
                  </span>
                </button>
              </div>
              <div className="scroll-bars pb-5">
                <div className="discussion-preview">
                  <div className="discussion-img">
                    <img alt="" src={s3ImagePath + userData.profile_image} />
                  </div>
                  <div className="discussion-preview-detail">
                    <h6>{inputFields.topic}</h6>
                    <p>{inputFields.description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-bars pb-5">
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />
              <div className="item-details-input">
                <div className="button-row">
                  <button
                    type="button"
                    className="secondary-btn-model"
                    onClick={HandleCancel}
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="primary-btn-model"
                    onClick={HandlePublish}
                  >
                    {isAppsSelected ? 'Publish' : 'Upload'}
                    {loading && <span className="spinner" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="link_view">
        <Link onClick={handleViewAll}>
          {showAll ? 'Show Less' : 'View All'}
        </Link>
      </div>
    </div>
  )
}
export default Discussions
