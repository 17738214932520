import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const ApexChart = () => {
  const [chartData] = useState({
    series: [
      {
        name: 'series1',
        data: [
          0, 0, 0, 0, 0, 0,
          0
        ]
      }
    ],
    options: {
      legend: {
        show: false,
        markers: {
          fillColors: ['#001C93', '#ffffff']
        }
      },
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: ['#001C93', '#ffffff'],
        opacity: [0.9, 0],
        type: 'gradient'
      },
      stroke: {
        curve: 'smooth',
        colors: ['#001597', '#ece9f1']
      },
      xaxis: {
        type: 'category',
        categories: ['1', '5', '10', '15', '20', '25', '30']
      },
      yaxis: {
        tickAmount: 4, // Set the number of ticks on the y-axis
        labels: {
          formatter(value) {
            // Convert large numbers to "k" format and remove trailing zeros
            const absValue = Math.abs(value)
            if (absValue >= 1.0e9) {
              return `${(absValue / 1.0e9).toFixed(1).replace(/\.0$/, '')}k`
            } if (absValue >= 1.0e6) {
              return `${(absValue / 1.0e6).toFixed(1).replace(/\.0$/, '')}k`
            } if (absValue >= 1.0e3) {
              return `${(absValue / 1.0e3).toFixed(1).replace(/\.0$/, '')}k`
            }
              return value.toFixed(1).replace(/\.0$/, '')
          }
        }
      },
      tooltip: {
        enabled: true,
        fixed: {
          enabled: false,
          position: 'top',
          offsetX: 0,
          offsetY: 0
        },
        x: {
          show: false,
          format: 'dd/MM/yy HH:mm'
        },
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex === 1) {
            return ''
          }
          const series1Data = w.globals.series[seriesIndex][dataPointIndex]
          return `<div class='chart-tooltip-data'><div class='this-month'>This Month</div><div class='chart-data'>${series1Data}</div> <div class='month-name'>Nov</div></div>`
        }
      }
    }
  })
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
      />
    </div>
  )
}

export default ApexChart
