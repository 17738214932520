import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import Tickets from '../../images/tickets.svg'

const EventHome = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const items = [
    {
      date: '05',
      month: 'May',
      lineup: 'Festival lineup (2022)',
      name: 'Rock am Ring & Rock im Park',
      detail:
        'Combined, these twin rock festivals draw some of the biggest crowds in the world. The allure is no secret, the festivals offer nothing short of legendary rock acts and seriously revved up fans who eat, sleep and breath rock and roll. ',
      bookedTicket: '1,456',
      cover: './images/event/3.jpg'
    },
    {
      date: '13',
      month: 'May',
      lineup: 'Festival lineup (2022)',
      name: 'Rock am Ring & Rock im Park',
      detail:
        'Combined, these twin rock festivals draw some of the biggest crowds in the world. The allure is no secret, the festivals offer nothing short of legendary rock acts and seriously revved up fans who eat, sleep and breath rock and roll. ',
      bookedTicket: '1,456',
      cover: './images/event/4.jpg'
    },
    {
      date: '24',
      month: 'May',
      lineup: 'Festival lineup (2022)',
      name: 'Rock am Ring & Rock im Park',
      detail:
        'Combined, these twin rock festivals draw some of the biggest crowds in the world. The allure is no secret, the festivals offer nothing short of legendary rock acts and seriously revved up fans who eat, sleep and breath rock and roll. ',
      bookedTicket: '1,456',
      cover: './images/event/5.jpg'
    },
    {
      date: '30',
      month: 'May',
      lineup: 'Festival lineup (2022)',
      name: 'Rock am Ring & Rock im Park',
      detail:
        'Combined, these twin rock festivals draw some of the biggest crowds in the world. The allure is no secret, the festivals offer nothing short of legendary rock acts and seriously revved up fans who eat, sleep and breath rock and roll. ',
      bookedTicket: '1,456',
      cover: './images/event/6.jpg'
    }
  ]
  return (
    <div className="position-relative overflow-hidden">
      <Swiper
        spaceBetween={10}
        navigation
        thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
          }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {items.map((item) => (
          <SwiperSlide key={item.date}>
            <div
              className="event-slide"
              style={{
                  background: `url(${item.cover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
            >
              <div className="event-silde--header">
                <div className="event-slide--date">
                  <h3>{item.date}</h3>
                  <span>{item.month}</span>
                </div>
                <div className="event-slide--name">
                  <h6>{item.lineup}</h6>
                  <p>{item.name}</p>
                </div>
              </div>
              <div className="event-slide--detail">
                <p>{item.detail}</p>
                <div className="event-slide--book">
                  <button type="button" className="light-button d-flex align-items-center col-gap-10">
                    <img alt="" src={Tickets} />
                    <span>Book Ticket Now</span>
                  </button>
                  <p>
                    Booked Ticket
                    {item.bookedTicket}
                  </p>
                </div>
              </div>
              <span className="event-slide--recomendation">
                Event Recommendations
              </span>
            </div>
          </SwiperSlide>
          ))}
      </Swiper>
      <div className="position-absolute thumb-swiper-position">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={26}
          slidesPerView="auto"
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {items.map((item) => (
            <SwiperSlide key={item.date}>
              <div
                className="event-slide--thumb"
                style={{
                    background: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
              >
                <div className="event-thumb--date">
                  <h3>{item.date}</h3>
                  <span>{item.month}</span>
                </div>
              </div>
            </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}
export default EventHome
