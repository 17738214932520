import React, { useState } from 'react'
import CloseIco from '../../images/CloseIco.svg'
import PlusIco from '../../images/PlusIco.svg'

const ContentManagerSettings = () => {
  const [isHidden, setIsHidden] = useState(true)

  const handleButtonClick = () => {
    setIsHidden(false)
  }
  const handleButtonClickHide = () => {
    setIsHidden(true)
  }

  return (
    <div className="settings-overview">
      <div className="item-wrapper">
        <h2>Content Manager Settings</h2>
        <div className="content-manager-item">
          <h3>Videos Setting</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add title of the video</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="title">
                <input id="title" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add categories of the video</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroies">
                <input id="categroies" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add description about video</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="description">
                <input id="description" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Featured videos for returning subscribers</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featured">
                <input id="featured" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status of video</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="status">
                <input id="status" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <h3>Podcast Setting</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add title of the Podcast</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titlePodcast">
                <input id="titlePodcast" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Allow Live Description</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesPodcast">
                <input id="categroiesPodcast" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Allow Live Streaming</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionPodcast">
                <input id="descriptionPodcast" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredPodcast">
                <input id="featuredPodcast" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <h3>Courses Setting</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add title of the Course</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleCourses">
                <input id="titleCourses" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Category</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesCourses">
                <input id="categroiesCourses" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Description of Course</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionCourses">
                <input id="descriptionCourses" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredCourses">
                <input id="featuredCourses" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <h3>Recipies Setting</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add title of the Recipies</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleRecipies">
                <input id="titleRecipies" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Category</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesRecipies">
                <input id="categroiesRecipies" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Description of Recipies</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionRecipies">
                <input id="descriptionRecipies" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredRecipies">
                <input id="featuredRecipies" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add chef name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredRecipies">
                <input id="featuredRecipies" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <h3>Magazines Setting</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add title of the Magzine</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleMagazines">
                <input id="titleMagazines" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Description of Magzine</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesMagazines">
                <input id="categroiesMagazines" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionMagazines">
                <input id="descriptionMagazines" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add chef name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredMagazines">
                <input id="featuredMagazines" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Community Giveaway Setting</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add name of the product</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleCommunityGiveaway">
                <input id="titleCommunityGiveaway" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Category of product</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesCommunityGiveaway">
                <input id="categroiesCommunityGiveaway" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionCommunityGiveaway">
                <input id="descriptionCommunityGiveaway" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Price</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredCommunityGiveaway">
                <input id="featuredCommunityGiveaway" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Shots Setting</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add title of the shot</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleShots">
                <input id="titleShots" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Description of the product</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesShots">
                <input id="categroiesShots" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Name of person</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionShots">
                <input id="descriptionShots" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Add Status</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredShots">
                <input id="featuredShots" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
      </div>
      {!isHidden && (
        <div className="add-more-settings-model">
          <div className="model-content">
            <div className="model-header">
              <h4>Add more video setting</h4>
              <button type="button" onClick={handleButtonClickHide}>
                <img className="close-btns" alt="" src={CloseIco} />
              </button>
            </div>
            <div className="model-filled">
              <label>Option name</label>
              <input placeholder="Name here" />
              <label>Decription</label>
              <textarea placeholder="Type here..." />
            </div>
            <div className="model-footer">
              <button type="button" className="save-button">Add option</button>
              <button type="button" className="cancel-button" onClick={handleButtonClickHide}>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default ContentManagerSettings
