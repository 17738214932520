import React, { useState } from 'react'
import './ContentManager.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ReactDatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../Dropdown/Dropdown'
import Video from './video'
import Podcasts from './Podcasts'
import Courses from './Courses'
import Recipies from './Recipies'
import Megazines from './Megazines'
import CommunityMegazines from './CommunityMegazines'
import Shots from './Shots'
import Views from './views'
import WatchTime from './WatchTime'
import Subscriber from './Subscribers'
import PlusIco from '../../images/PlusIco.svg'
// import Awards from './Awards'
import DiscussionsDetails from './DiscussionsDetails'
import PollsDetails from './PollsDetails'
import GiveawayDetails from './GiveawayDetails'

const ContentManager = () => {
  const [startDate, setStartDate] = useState(new Date())
  const navigate = useNavigate()
  const items = [
    { text: 'Videos', disabled: false },
    { text: 'Podcast', disabled: false },
    { text: 'Community', disabled: false },
    { text: 'Shots', disabled: false },
    { text: 'Courses', disabled: true },
    { text: 'Recipies', disabled: true },
    { text: 'Magzines', disabled: true }
  ]

  const handleNavigation = (index) => {
    navigate('/add-component', { state: { defaultIndex: index } })
  }
  return (
    <div className="content-manager">
      <div className="content-manager-head">
        {/* <button type="button" className="disabledDropdown">
          <img src={PlayIco} alt="play icon" />
          Start Live Stream
        </button> */}
        <Dropdown
          title="All Platforms"
          Data={[
            { title: 'Scaloo', img: './images/scalco-table.svg' },
            { title: 'Spacure', img: './images/spacure-table.svg' },
            { title: 'PETZINO', img: './images/petzino-table.svg' },
            { title: 'A-Z FIT', img: './images/a-z-fit-table.svg' },
            { title: 'TESTIEE', img: './images/testiee-table.svg' },
            { title: 'LUXURY ELEVEN', img: './images/luxury-eleven-table.svg' },
            { title: 'GO ATHLETE', img: './images/go-athelete-table.svg' },
            {
              title: 'THE TASTE CLUB',
              img: './images/the-teste-club-table.svg'
            },
            { title: 'FYERDATES', img: './images/fyredates-table.svg' },
            {
              title: 'CREATOR TALENT',
              img: './images/creator-telents-table.svg'
            }
          ]}
          customClass="disabledDropdown"
        />
      </div>
      <div className="content-manager-Statistics">
        <div className="content-manager-main-heading mb-40">
          <h2>Content Manager</h2>
          <button
            type="button"
            onClick={() => {
              navigate('/add-component')
            }}
          >
            <img src={PlusIco} alt="Add icon" />
            Add Content Type
          </button>
        </div>
        <div className="content-manager-Statistics-chart common-card-body mb-40">
          <h3>Statistics</h3>
          <div className="Statistics-tab mb-40">
            {items.map((item) => (
              <button
                type="button"
                key={item.text}
                disabled={item.disabled}
                className="items_button"
                onClick={() => {
                  if (item.text === 'Videos') {
                    handleNavigation(0)
                  } else if (item.text === 'Podcast') {
                    handleNavigation(1)
                  } else if (item.text === 'Community') {
                    handleNavigation(2)
                  } else if (item.text === 'Shots') {
                    handleNavigation(3)
                  }
                }}
              >
                {item.text}
              </button>
            ))}
          </div>
          <div className="statistic-chart-container position-relative common-card-body">
            <h4>Videos Statistics</h4>
            <div>
              <Tabs>
                <div className="d-flex view-date">
                  <TabList className="view_watch_sub">
                    <Tab>Views</Tab>
                    <Tab>Watch Time</Tab>
                    <Tab>Subscribers</Tab>
                  </TabList>
                  <div className="drop-and-date">
                    <Dropdown
                      title="Yearly"
                      Data={[
                        { title: 'Yearly' },
                        { title: 'Monthly' },
                        { title: 'Weekly' }
                      ]}
                      customClass="content-manager-yearly disabledDropdown"
                    />
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      calendarClassName="content-manager-year disabledDropdown"
                    />
                  </div>
                </div>

                <TabPanel>
                  <Views />
                </TabPanel>
                <TabPanel>
                  <WatchTime />
                </TabPanel>
                <TabPanel>
                  <Subscriber />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="content-manager-video mb-40">
          <Video />
        </div>
        <div className="content-manager-codcasts mb-40">
          <Podcasts />
        </div>
        <div className="content-manager-courses mb-40">
          <Courses />
        </div>
        <div className="content-manager-recipies mb-40">
          <Recipies />
        </div>
        <div className="content-manager-megazines mb-60">
          <Megazines />
        </div>
        <div className="content-manager-community common-card-body mb-60">
          <h5 className="content-manager-community-head">Community</h5>
          <div className="community-megenize mb-40">
            <CommunityMegazines />
          </div>
          <div className="content-manager-Discussions mb-40">
            <DiscussionsDetails />
          </div>
          <div className="content-manager-polls mb-40">
            <PollsDetails />
          </div>
          <div className="content-manager-polls mb-40">
            <GiveawayDetails />
          </div>
          <div className="content-manager-awards">
            {/* <Awards /> */}
          </div>
        </div>
        <div className="content-manager-shots">
          <Shots />
        </div>
      </div>
    </div>
  )
}
export default ContentManager
