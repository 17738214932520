/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, createRef } from 'react'
import './createprofile.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import Cropper from 'react-cropper'
import info from '../../images/info.svg'
import cancel from '../../images/ic_baseline-cancel.svg'
import SelectImg from '../../images/selectimg.svg'
import 'cropperjs/dist/cropper.css'
import plusgredient from '../../images/plus-gredient.svg'
import {
  getUserTypes,
  getApplications,
  getAddOns,
  s3ImagePath,
  imageUpload,
  getOrganizationTypes,
  createProfile
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Dropdown from '../Dropdown/Dropdown'
import plusrounded from '../../images/plus-white.svg'

const CreateProfile = () => {
  const navigate = useNavigate()
  const [appData, setAppData] = useState([])
  const [certificateUrl, setCertificateUrl] = useState([])
  const [addOnsData, setAddOnsData] = useState([])
  const [userTypesData, setUserTypesData] = useState([])
  const [icon, setIcon] = useState([])
  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
  const [userTypeId, setUserTypesId] = useState([])
  const [organizationData, setOrganizationData] = useState([])
  const [verificationData, setVerificationData] = useState({
    social_media: [],
    description: '',
    website_link: '',
    certificate: '',
    local_address: '',
    local_address_image: [],
    store_link: '',
    organization_type: '',
    title: ''
  })
  const [iconImg, setIconImg] = useState(null)
  const [iconObj, setIconObj] = useState(null)
  const [errors, setErrors] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [isAddonsLoading, setIsAddonsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [selctedItem, setSelctedItem] = useState()
  const [socialMediaLinks, setSocialMediaLinks] = useState([''])
  const [selectedImages, setSelectedImages] = useState([])

  const getOrganizationData = async () => {
    getOrganizationTypes().then((res) => {
      if (res.data.success) {
        setOrganizationData(dataDecrypt(res.data.data))
      }
    })
  }

  const handleChange = (e) => {
    console.log(e.target.value)
    setVerificationData({
      ...verificationData,
      [e.target.name]: e.target.value
    })
  }

  const getUserTypeData = async () => {
    setIsLoading(true)
    getUserTypes().then((res) => {
      if (res.data.success) {
        console.log(dataDecrypt(res.data.data))
        if (
          JSON.parse(localStorage.getItem('userData')).user_type_id &&
          JSON.parse(localStorage.getItem('userData')).user_type_id !== 0
        ) {
          const UserTypeName = dataDecrypt(res.data.data).filter(
            (item) => item.id ==
              JSON.parse(localStorage.getItem('userData')).user_type_id,
          )[0].name
          setSelctedItem(UserTypeName)
          getAddonsData(UserTypeName)
        }
        setUserTypesData(dataDecrypt(res.data.data))
        setIsLoading(false)
      }
    })
  }

  const getAddonsData = async (name) => {
    setIsAddonsLoading(true)
    getAddOns().then((res) => {
      if (res.data.success) {
        const data = dataDecrypt(res.data.data)
        if (name == 'Creator') {
          data.find((o) => o.name == 'Entertainment').checked = true
          data.find((o) => o.name == 'Entertainment').disabled = true
        } else if (name == 'Store') {
          data.find((o) => o.name == 'Store').checked = true
          data.find((o) => o.name == 'Store').disabled = true
        } else if (name == 'Local') {
          data.find((o) => o.name == 'Local').checked = true
          data.find((o) => o.name == 'Local').disabled = true
        } else if (name == 'Expert') {
          data.find((o) => o.name == 'Guide').checked = true
          data.find((o) => o.name == 'Guide').disabled = true
        }
        console.log(data)
        setAddOnsData(data)
        setIsAddonsLoading(false)
      }
    })
  }

  const getApplicationsData = async () => {
    setIsAppLoading(true)
    getApplications().then((res) => {
      if (res.data.success) {
        setAppData(dataDecrypt(res.data.data))
        setIsAppLoading(false)
      }
    })
  }

  const handleSelection = (data) => {
    let select = []
    if (!select.includes(data.name)) {
      select.push(data.name)
    } else {
      select = select.filter((item) => item !== data.name)
    }
    setSelctedItem(select)
    setUserTypesId(data.id)
    getAddonsData(select)
  }

  const nextStep = () => {
    console.log(verificationData)
    setErrors('')
    if (verificationData.store_link !== '') {
      if (!urlRegex.test(verificationData.store_link)) {
        setErrors('URL format is not valid')
        return false
      }
    } else if (verificationData.social_media.length > 0) {
      const allTrue = verificationData.social_media.every((obj) => urlRegex.test(obj),)
      if (!allTrue) {
        setErrors('URL format is not valid')
        return false
      }
    }
    setCurrentStep(currentStep + 1)
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleInputChange = (index, value) => {
    const updatedLinks = [...socialMediaLinks]
    updatedLinks[index] = value
    setSocialMediaLinks(updatedLinks)
    setVerificationData({
      ...verificationData,
      social_media: updatedLinks
    })
  }

  const addInputField = () => {
    setSocialMediaLinks([...socialMediaLinks, ''])
  }

  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']

  const handleImageUpload = (e) => {
    const file = e.target.files[0]

    if (file) {
      if (allowedTypes.includes(file.type)) {
        const imgUrl = URL.createObjectURL(file)
        setIconImg(imgUrl)
        setIconObj(file)

        imageUpload(file).then((res) => {
          if (res.data.success) {
            const decryptedUrl = dataDecrypt(res.data.data)
            setIcon(decryptedUrl)
          } else {
            console.error('Image upload failed.')
          }
        })
      } else {
        toast.error('Please upload a valid image file (JPEG/JPG/PNG)')
        e.target.value = null
      }
    }
  }

  const handleCertificateUpload = (e) => {
    const file = e.target.files[0]

    if (file) {
      imageUpload(file).then((res) => {
        if (res.data.success) {
          setCertificateUrl(s3ImagePath + dataDecrypt(res.data.data))
        }
      })
    }
  }

  const handleImageChange = (e) => {
    const { files } = e.target

    setSelectedImages([...selectedImages, ...Array.from(files)])
    imageUpload(files[0]).then((res) => {
      if (res.data.success) {
        verificationData.local_address_image.push(dataDecrypt(res.data.data))
        setVerificationData({
          ...verificationData,
          local_address_image: verificationData.local_address_image
        })
        console.log(verificationData.local_address_image)
      }
    })
  }

  const createProfileClick = () => {
    const app_id = appData.filter(({ checked }) => checked).map((e) => e.id)
    const add_ons = addOnsData
      .filter(({ checked }) => checked)
      .map((e) => e.id)
    const data = {
      user_type_id: userTypeId,
      verification_data: verificationData,
      app_id,
      icon,
      add_ons
    }
    createProfile(data).then((res) => {
      if (res.data.success) {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (!userData.user_type_id || userData.user_type_id == 0) {
          userData.user_type_id = userTypeId
          localStorage.setItem('userData', JSON.stringify(userData))
        }
        toast.success(res?.data?.message)
        navigate('/dashboard')
      }
    })
  }

  const handleCheckboxChange = (index) => (e) => {
    const newCheckboxes = [...addOnsData]
    newCheckboxes[index].checked = e.target.checked
    setAddOnsData(newCheckboxes)
  }

  const handleCancelClick = (id) => {
    const newCheckboxes = addOnsData.map((addOn) => {
      if (addOn.id == id) {
        return { ...addOn, checked: false }
      }
      return addOn
    })
    setAddOnsData(newCheckboxes)
  }

  const getSelectedAddOns = () => {
    return addOnsData.filter((addOn) => addOn.checked)
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('userData')).user_type_id &&
      JSON.parse(localStorage.getItem('userData')).user_type_id !== 0
    ) {
      nextStep()
      setUserTypesId(JSON.parse(localStorage.getItem('userData')).user_type_id)
    }
    getUserTypeData()
    getApplicationsData()
    getOrganizationData()
  }, [])

  const base64ToBlob = (base64, contentType = '') => {
    const byteCharacters = atob(base64)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type })
  }

  const handleConvert = (base64String) => {
    const base64 = base64String.split(',')[1]
    const contentType = 'image/jpeg'

    const blob = base64ToBlob(base64, contentType)
    const file = blobToFile(blob, 'image.jpg')
    return file
  }

  const cropperRef = createRef(null)
  const [cropData, setCropData] = useState(null)

  const getCropData = () => {
    if (cropperRef.current?.cropper) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas()
      setCropData(croppedCanvas.toDataURL())
      const file = handleConvert(croppedCanvas.toDataURL())
      setIconObj(file)
    }
  }

  useEffect(() => {
    if (iconImg) {
      cropperRef.current?.cropper.replace(iconImg)
    }
  }, [iconImg])

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h1>Create Profile</h1>
            <p className="sub-text-1">
              What kind of a profile you want to create:
            </p>
            <div className="step-1">
              {isLoading ? (
                <div
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <h3><Skeleton width={100} /></h3>
                    <p><Skeleton className="mb-2" width={150} height={20} /></p>
                    <p><Skeleton width={175} height={20} /></p>
                  </div>
                  <Skeleton width={80} height={80} />
                </div>
            ) : (
              userTypesData.map((i) => (
                <div
                  key={i.name}
                  className={`d-flex justify-content-between align-items-center ${
                    selctedItem?.includes(i.name) ? 'selected' : 'selectable'
                  }`}
                  onClick={() => handleSelection(i)}
                >
                  <div>
                    <h3>{i.name}</h3>
                    <p>{i.description}</p>
                  </div>
                  <img src={s3ImagePath + i.icon} alt="" />
                </div>
              ))
            )}
            </div>
            <div className="steps-button">
              <button
                type="button"
                disabled={!selctedItem}
                className="primary-button btn-next"
                onClick={nextStep}
              >
                <span>Next</span>
              </button>
            </div>
          </div>
        )
      case 2:
        return (
          <div>
            <h1>Create Profile</h1>
            <p className="sub-text-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi
              ornare turpis duis mi. At vel auctor vivamus morbi elit. Sit risus
              consequat duis sit ultricies at eu volutpat. Sed at lobortis orci
              sem accumsan, lacus tellus feugiat. Odio tempor adipiscing eu sed
              cursus ultricies netus orci.
            </p>
            <div className="step-2">
              {isAppLoading ? (
                <div className="profile-checkbox">
                  <Skeleton width={65} height={65} />
                  <p><Skeleton width={150} height={20} /></p>
                  <div className="custom-check-box">
                    <Skeleton type="checkbox" width={20} height={20} />
                  </div>
                  <div className="info-button">
                    <Skeleton width={20} height={20} />
                  </div>
                </div>
            ) : (

              appData.map((i, index) => (
                <div className="profile-checkbox" key={index}>
                  <img src={s3ImagePath + i.icon} alt="" />
                  <p>{i.description}</p>
                  <div className="custom-check-box">
                    <input
                      type="checkbox"
                      value={i.id}
                      checked={i.checked}
                      onChange={(e) => {
                        const newCheckboxes = [...appData]
                        newCheckboxes[index].checked = e.target.checked
                        setAppData(newCheckboxes)
                      }}
                    />
                  </div>
                  <div className="info-button">
                    <img src={info} alt="" />
                  </div>
                </div>
              ))
            ) }
            </div>
            <div className="steps-button">
              {!JSON.parse(localStorage.getItem('userData')).user_type_id &&
                JSON.parse(localStorage.getItem('userData')).user_type_id ==
                  0 && (
                  <button type="button" className="btn-prev secondary-btn" onClick={prevStep}>
                    <span>Back</span>
                  </button>
                )}
              <button
                type="button"
                disabled={appData.filter(({ checked }) => checked).length == 0}
                className="primary-button btn-next"
                onClick={nextStep}
              >
                <span>Next</span>
              </button>
            </div>
          </div>
        )
      case 3:
        return (
          <div>
            <h1>
              How I use my profile?
              {' '}
              <img src={info} alt="" />
            </h1>
            <p className="sub-text-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi
              ornare turpis duis mi. At vel auctor vivamus morbi elit. Sit risus
              consequat duis sit ultricies at eu volutpat. Sed at lobortis orci
              sem accumsan, lacus tellus feugiat. Odio tempor adipiscing eu sed
              cursus ultricies netus orci. Erat arcu viverra rhoncus.
            </p>
            <div className="profile-pics w-100 h-100 mt-4">
              <div className="profile-pic-image">
                {/* <img src={cropData || iconImg} alt="" /> */}
                <div className="profile-select-label">
                  <div className="d-flex">
                    <label htmlFor="profile">
                      <img src={SelectImg} alt="" />
                    </label>
                    <input
                      className="d-none"
                      type="file"
                      id="profile"
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="d-flex user-profile-crop">
                  <div>
                    <img src={cropData || iconImg} alt="" />
                  </div>
                  <div>
                    {iconObj && (
                    <>
                      {!cropData && (
                      <Cropper
                        ref={cropperRef}
                        style={{ height: 200, width: '100%' }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        src={iconImg}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive
                        autoCropArea={1}
                        checkOrientation={false}
                        guides
                      />
                  )}
                      <button
                        type="button"
                        className="crop-btn"
                        onClick={getCropData}
                      >
                        {!cropData ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-crop"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5.5A.5.5 0 0 1 4 1v13h13a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2H3.5a.5.5 0 0 1-.5-.5V4H1a.5.5 0 0 1 0-1h2V1a.5.5 0 0 1 .5-.5m2.5 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4H6.5a.5.5 0 0 1-.5-.5" />
                          </svg>
                        ) : null}
                      </button>
                    </>
                  )}
                  </div>
                </div>
                {/* <div className="">
                  {iconObj && (
                    <>
                      {!cropData && (
                      <Cropper
                        ref={cropperRef}
                        style={{ height: 200, width: '100%' }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        src={iconImg}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive
                        autoCropArea={1}
                        checkOrientation={false}
                        guides
                      />
                  )}
                      <button
                        type="button"
                        className="crop-btn"
                        onClick={getCropData}
                      >
                        {!cropData ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-crop"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5.5A.5.5 0 0 1 4 1v13h13a.5.5 0 0 1 0 1h-2v2a.5.5
                            0 0 1-1 0v-2H3.5a.5.5 0 0 1-.5-.

                            5V4H1a.5.5 0 0 1 0-1h2V1a.5.5 0 0 1 .5-.5m2.5 3a.5.5 0 0 1 .5-
                            .5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1

                            0V4H6.5a.5.5 0 0 1-.5-.5" />
                          </svg>
                        ) : null}
                      </button>
                    </>
                  )}
                </div> */}
              </div>
            </div>
            <div className="item-details-input">
              <div>
                <label>User Type</label>
                <input value={selctedItem} className="user-type" readOnly />
              </div>
              <div>
                <label>Decription</label>
                <textarea
                  onChange={handleChange}
                  name="description"
                  placeholder="Type here"
                  value={verificationData.description}
                />
              </div>
            </div>

            {selctedItem == 'Creator' && (
              <div className="social-media-link-input">
                <label>Add Social Media Link</label>
                {socialMediaLinks.map((link, index) => (
                  <div
                    key={index}
                    className="item-details-input p-0 socialmedia-select"
                  >
                    <Dropdown
                      title=""
                      img="./images/socialicon/youtube.png"
                      Data={[
                        { img: './images/socialicon/youtube.png' },
                        { img: './images/socialicon/twiter.png' },
                        { img: './images/socialicon/facebook.svg' },
                        { img: './images/socialicon/instagram.png' },
                        { img: './images/socialicon/twitch.png' },
                        { img: './images/socialicon/tiktok.png' },
                        { img: './images/socialicon/linkedin.png' }
                      ]}
                      onChanged={() => {}}
                    />
                    <input
                      className="mb-2"
                      placeholder="Paste here"
                      value={link}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  </div>
                ))}
                {errors !== '' && <p className="error-message">{errors}</p>}

                <button
                  type="button"
                  className="secondary-button mt-2"
                  onClick={addInputField}
                >
                  <img src={plusrounded} alt="" />
                  <span>Add another</span>
                </button>
              </div>
            )}

            {selctedItem == 'Expert' && (
              <div className="item-details-input expert-verification">
                <label>Add Website Link</label>
                <input
                  type="text"
                  placeholder="Paste here"
                  name="website_link"
                  value={verificationData.website_link}
                  onChange={handleChange}
                  className="mb-2"
                />
                <label>Add Certificate</label>
                <div className="input-with-button mb-2 d-flex">
                  <input
                    value={certificateUrl}
                    placeholder="Enter image URL or click upload..."
                  />
                  <label htmlFor="upload" className="upload-btn primary-btn">
                    <span>Upload</span>
                    <input
                      type="file"
                      id="upload"
                      onChange={handleCertificateUpload}
                      className="primary-button d-none"
                    />
                  </label>
                </div>
              </div>
            )}

            {selctedItem == 'Local' && (
              <div className="item-details-input expert-verification local-verification">
                <label>Add Website Link</label>
                <input
                  type="text"
                  placeholder="Paste here"
                  name="website_link"
                  value={verificationData.website_link}
                  onChange={handleChange}
                  className="mb-2"
                />
                <label>Add Local Address</label>
                <input
                  placeholder="Enter here"
                  name="local_address"
                  value={verificationData.local_address}
                  onChange={handleChange}
                  className="mb-2"
                />
                <div>
                  <p className="sub-text">Add images for verification</p>
                  <div className="input-image-show">
                    {selectedImages.map((image, index) => (
                      <div
                        className="image"
                        key={index}
                        style={{
                          backgroundImage: `url(${URL.createObjectURL(image)})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                        alt={`image-${index}`}
                      />
                    ))}

                    <div className="input-img-label">
                      <label htmlFor="input-img">
                        <img src={plusgredient} alt="" />
                        <p className="m-0">Add Image</p>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="input-img"
                        multiple
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selctedItem == 'Store' && (
              <div className="item-details-input social-media-link-input store-verification">
                <label>Add your store link</label>
                <input
                  className="mb-2"
                  placeholder="Paste here"
                  name="store_link"
                  value={verificationData.store_link}
                  onChange={handleChange}
                />
                {errors !== '' && <p className="error-message">{errors}</p>}
              </div>
            )}

            {selctedItem == 'Organization' && (
              <div className="item-details-input expert-verification organization-verification">
                <label>Organization Type</label>
                <div className="custome-select">
                  <Dropdown
                    title={
                      verificationData.organization_type == ''
                        ? organizationData[0].name
                        : verificationData.organization_type
                    }
                    onChanged={(d) => {
                      setVerificationData({
                        ...verificationData,
                        organization_type: d.title
                      })
                    }}
                    name="organization_type"
                    Data={organizationData.map((d) => ({
                      title: d.name,
                      id: d.id
                    }))}
                  />
                </div>
                <label>Add Website Link</label>
                <input
                  type="text"
                  placeholder="Paste here"
                  name="website_link"
                  value={verificationData.website_link}
                  onChange={handleChange}
                  className="mb-2"
                />
              </div>
            )}

            {selctedItem == 'Custom' && (
              <div className="item-details-input expert-verification custome-verification">
                <label>Add Title</label>
                <input placeholder="type here" className="mb-2" />
              </div>
            )}

            <div className="steps-button">
              <button
                type="button"
                className="secondary-btn btn-prev-case3"
                onClick={prevStep}
              >
                <span>Back</span>
              </button>
              <button
                type="button"
                disabled={!iconObj || !verificationData.description}
                className="primary-button btn-next-case3"
                onClick={nextStep}
              >
                <span>Join</span>
              </button>
            </div>
          </div>
        )
      case 4:
        return (
          <div>
            <h1>Create Profile</h1>
            <div className="d-flex align-items-center justify-content-between">
              <p className="sub-text">Choose Add ons:</p>
              <div className="add-ons-label">
                {getSelectedAddOns().map((addOn) => (
                  <p key={addOn.id}>
                    {addOn.name}
                    <button
                      type="button"
                      onClick={() => handleCancelClick(addOn.id)}
                      disabled={addOn.disabled}
                    >
                      <img src={cancel} alt="" />
                    </button>
                  </p>
                ))}
              </div>
            </div>
            <div className="step-4">
              {isAddonsLoading ? (
                <div className="add-ons">
                  <div className="add-ons-main-ing">
                    <Skeleton width={100} height={100} />
                  </div>
                  <div className="footer-row">
                    <div className="images-block">
                      <Skeleton width={100} height={100} />
                    </div>
                    <div className="add-ons-detail">
                      <h4>
                        <Skeleton max-width={175} />
                      </h4>
                      <p>
                        <Skeleton max-width={175} height={50} />
                      </p>
                    </div>
                  </div>
                  <div className="custome-checkbox">
                    <Skeleton type="checkbox" width={20} height={20} />
                  </div>
                </div>
          ) : (
              addOnsData.map((i, index) => (
                <div key={index} className="add-ons">
                  <div className="add-ons-main-ing">
                    <img alt="" src={s3ImagePath + i.image} />
                  </div>
                  <div className="footer-row">
                    <div className="images-block">
                      <img alt="" src={s3ImagePath + i.icon} />
                    </div>
                    <div className="add-ons-detail">
                      <h4>{i.name}</h4>
                      <p>{i.description}</p>
                    </div>
                  </div>
                  <div className="custome-checkbox">
                    <input
                      type="checkbox"
                      value={i.id}
                      checked={i.checked}
                      disabled={i.disabled}
                      onChange={handleCheckboxChange(index)}
                    />
                  </div>
                </div>
              ))
            )}
            </div>
            <div className="steps-button case">
              <button
                type="button"
                className="secondary-btn btn-prev-case4"
                onClick={prevStep}
              >
                <span>Back</span>
              </button>
              <button
                type="button"
                disabled={
                  addOnsData.filter(({ checked }) => checked).length == 0
                }
                className="primary-button btn-next-case4"
                onClick={createProfileClick}
              >
                <span>Create</span>
              </button>
            </div>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="profile-left-wrapper">
      <div className="create-profile">{renderContent()}</div>
    </div>
  )
}
export default CreateProfile
