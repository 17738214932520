import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import usergroup from '../../images/usergroup.svg'
import location from '../../images/Locations.svg'
import gym from '../../images/gym.svg'

const HomeAbout = () => {
  return (
    <div className="services-container common-card-body recent-video-component">
      <h3>About</h3>
      <div className="contact-info-grid">
        <div className="contact-info-item information common-card-body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra
            luctus vestibulum sed lectus imperdiet proin. Sed mi pellentes
            nullam sit amet, lorem. Mauris feugiat tincidunt nunc, quam mauris,
            massa diam faucibus.
          </p>
          <ul>
            <li>
              <img alt="" src={usergroup} />
              <Link>6,245 people follow this</Link>
            </li>
            <li>
              <img alt="" src={location} />
              <Link>1,95 people checked in here</Link>
            </li>
            <li className="e-mail">
              <img alt="" src={gym} />
              <Link>Gym/Physical Fitness Center </Link>
            </li>
          </ul>
        </div>
        <div className="contact-info-item map common-card-body">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{ borderRadius: '15px' }}
            title="gym"
          />
        </div>
      </div>
    </div>
  )
}
export default HomeAbout
