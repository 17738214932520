import React, { useEffect, useRef, useState } from 'react'
import '../AddAwards/AddAwards.css'
import './style.css'
import ReactDatePicker from 'react-datepicker'
import AddTicketImg from '../../images/ticketcard.png'
import UploadImg from '../../images/upload.svg'

const AddTickets = () => {
  const [ShowTicketModel, setShowTicketModel] = useState(false)
  const AddTicket = () => {
    setShowTicketModel(true)
  }
  const HandleCancel = () => {
    setShowTicketModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowTicketModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [startDate, setStartDate] = useState(new Date())
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Tickets</h2>
        </div>
        <div className="add-tickets">
          <div
            className="add-tickets-item position-relative"
            onClick={AddTicket}
          >
            <img alt="" src={AddTicketImg} />
          </div>
        </div>
      </div>
      {ShowTicketModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={UploadImg} />
                  </div>
                  <h4>Upload a Photo</h4>
                  <p>
                    Drag and drop your file or click the button below to upload
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>Upload a Photo</span>
                  </button>
                </label>
              </div>
              <div>
                <label>Title</label>
                <input placeholder="Title" />
              </div>
              <div>
                <label>Price</label>
                <input placeholder="Enter Price" />
              </div>
              <div className="location-input">
                <label>Add Location</label>
                <input placeholder="Enter location" />
              </div>
              <div className="input-grid-three ">
                <div>
                  <label>City</label>
                  <input placeholder="Enter city" />
                </div>
                <div>
                  <label>State</label>
                  <input placeholder="Enter state" />
                </div>
                <div>
                  <label>Zip code</label>
                  <input placeholder="Enter zip code" />
                </div>
              </div>
              <div className="celender-input">
                <label>Date</label>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                <label>Time</label>
                <input placeholder="Enter Time" />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button type="button" className="secondary-btn-model">Add</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddTickets
