import React from 'react'
import { Link } from 'react-router-dom'
// import LikeEmpty from '../../images/like-empty.svg'
// import Book from '../../images/book.svg'
// import RatingStar from '../../images/rating-start.svg'
// import chef from '../../images/Chef.svg'
// import ClockFillIco from '../../images/ClockFillIco.svg'

const Megazines = () => {
  // const items = [
  //   {
  //     CoverImage: './images/megezines.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit elit Ipsum duis ac ..',
  //     days: '10 days'
  //   },
  //   {
  //     CoverImage: './images/megezines.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit elit Ipsum duis ac ..',
  //     days: '10 days'
  //   },
  //   {
  //     CoverImage: './images/megezines.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit elit Ipsum duis ac ..',
  //     days: '10 days'
  //   },
  //   {
  //     CoverImage: './images/megezines.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit elit Ipsum duis ac ..',
  //     days: '10 days'
  //   }
  // ]

  return (
    <div className="content-manager-megazines-wrapper common-card-body">
      <div className="megazines-wrapper-header common-contnt-wrapper">
        <h2>Megazines</h2>
        <Link>View All</Link>
      </div>
      <div className="megazines-cetegory">
        {/* <div className="megazines-items">
          {items.map((item) => (
            <div className="magazines-box" key={item.name}>
              <div
                className="magazines-cover"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="like">
                  <img alt="" src={LikeEmpty} />
                </div>
              </div>
              <div className="magazines-detail">
                <div className="magezines-title">
                  <h6>
                    <img alt="" src={chef} />
                    {item.Title}
                  </h6>
                  <div className="rating">
                    <img alt="" src={RatingStar} />
                    <span>{item.Rating}</span>
                  </div>
                </div>
                <p>{item.text}</p>
                <div className="other-info">
                  <span>
                    <img alt="" src={ClockFillIco} />
                    {item.days}
                  </span>
                  <span>
                    <img alt="" src={Book} />
                    <Link>Read</Link>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  )
}
export default Megazines
