import React, { useRef, useState } from 'react'
import MultiRangeSlider from 'multi-range-slider-react'
import { useLocation } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProgressBar from '@ramonak/react-progress-bar'
import RatingStar from '../../images/rating-start.svg'
import LikeFill from '../../images/like-empty.svg'
import Remove from '../../images/remove.svg'
import plusimg from '../../images/plusimg.svg'
import upload from '../../images/upload.svg'
import Dropdown from '../Dropdown/Dropdown'
import FeedIco from '../../images/ServiceIco.svg'
import ShotsIco from '../../images/FeedIco.svg'

const Products = () => {
  const location = useLocation()
  const { state } = location || {}
  const product = state || []
  const category = [
    'All options',
    'Pills',
    'Shampoo',
    'Thermometer',
    'Hand Sanitizer',
    'Hand Lotion',
    'Sun Milk',
    'Body Lotion',
    'Hair conditioner',
    'Nail polish',
    'T-Shirt',
    'Pullover'
  ]
  const size = [
    'All sizes',
    'X-Small',
    'Small',
    'Medium',
    'Large',
    'X-Large',
    'XX-Large',
    'Option'
  ]
  const [minValue, set_minValue] = useState(25)
  const [maxValue, set_maxValue] = useState(75)
  const handleInput = (e) => {
    set_minValue(e.minValue)
    set_maxValue(e.maxValue)
  }
  const [ShowPlatform, setShowPlatform] = useState(false)
  const [ShowProduct, setShowProduct] = useState(false)
  const [ShowPreviewProduct, setShowPreviewProduct] = useState(false)
  const HandleCancel = () => {
    setShowPlatform(false)
    setShowProduct(false)
    setShowPreviewProduct(false)
  }
  const PlatformPreview = () => {
    setShowPlatform(true)
    setShowProduct(false)
    setShowPreviewProduct(false)
  }
  const ProductPreview = () => {
    setShowPreviewProduct(true)
    setShowProduct(false)
    setShowPlatform(false)
  }
  const HandleProduct = () => {
    setShowProduct(true)
    setShowPlatform(false)
    setShowPreviewProduct(false)
  }
  const Platform = [
    './images/platform/a-z-fit.svg',
    './images/platform/fyerdates.svg',
    './images/platform/spacure.svg',
    './images/platform/the-teste-club.svg',
    './images/platform/go-athlete.svg',
    './images/platform/luxury-eleven.svg',
    './images/platform/testiee.svg',
    './images/platform/petzino.svg',
    './images/platform/creator-telents.png',
    './images/platform/scaloo.svg',
    './images/platform/family-trends.svg',
    './images/platform/filiyo.svg'
  ]
  const componentRef = useRef()
  const [selectedImg, setSelectedImg] = useState(0)
  const [selectedImages, setSelectedImages] = useState([])

  const handleImageChange = (e, indexToReplace) => {
    const { files } = e.target

    if (files.length > 0) {
      const updatedImages = [...selectedImages]
      if (
        indexToReplace === undefined &&
        updatedImages.length + files.length > 4
      ) {
        alert('Please select a maximum of 4 images.')
        e.target.value = null
        return
      }
      if (indexToReplace !== undefined && indexToReplace !== null) {
        // Replace the image at the specified index
        updatedImages.splice(indexToReplace, 1, files[0])
      } else {
        // Add new images
        updatedImages.push(...files)
      }

      setSelectedImages(updatedImages)
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
  }

  const replaceImage = (index) => {
    const input = document.getElementById('input-img')

    const handleChange = (e) => {
      handleImageChange(e, index)
      input.removeEventListener('change', handleChange)
    }

    if (input) {
      input.value = ''
      input.removeEventListener('change', handleChange) // Remove existing event listener
      input.click()
      input.addEventListener('change', handleChange, { once: true })
    }
  }

  const generateStars = (rating) => {
    const totalStars = 5 // Total number of stars to display
    const count = Math.min(Math.floor(parseFloat(rating)), totalStars)
    const remainder = parseFloat(rating) - count // Decimal part to represent partial star
    const stars = []
    for (let i = 0; i < count; i++) {
      stars.push(<img alt="" key={i} src="./images/star.svg" />)
    }
    if (remainder > 0) {
      stars.push(<img alt="" key={count} src="./images/star.svg" />)
    }
    for (let i = stars.length; i < totalStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
    }

    return stars
  }
  const [count, setCount] = useState(1)
  function incrementCount() {
    setCount(count + 1)
  }
  function decrementCount() {
    if (count > 1) {
      setCount(count - 1)
    }
  }
  const percentage = ['1', '3', '5', '23', '88']
  return (
    <div className="creator-profile-main">
      <div className="d-flex justify-content-between product-filter-main">
        <div className="common-card-body filter-products recent-video-component">
          <h3 className="">Pricing</h3>
          <p>
            $
            {minValue}
            {' '}
            - $
            {maxValue}
          </p>
          <div className="price-range">
            <MultiRangeSlider
              min={0}
              max={100}
              step={5}
              ruler={false}
              label={false}
              barInnerColor="linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%)"
              minValue={minValue}
              maxValue={maxValue}
              onInput={(e) => {
                handleInput(e)
              }}
            />
          </div>
          <div className="vertical-divider mb-2 mt-4" />
          <h3 className="mb-3 mt-4">Category</h3>
          <div className="category-filter">
            <div className="custom-check-box position-static">
              {category.map((item) => (
                <label
                  className="d-flex align-items-center col-gap-20"
                  key={item}
                >
                  <input type="checkbox" id={item} />
                  {' '}
                  {item}
                </label>
              ))}
            </div>
          </div>
          <h3 className="mb-3 mt-3">Size</h3>
          <div className="size-filter">
            <div className="custom-check-box position-static">
              {size.map((item) => (
                <label
                  className="d-flex align-items-center col-gap-20"
                  key={item}
                >
                  <input type="checkbox" id={item} />
                  {' '}
                  {item}
                </label>
              ))}
            </div>
          </div>
          <div className="size-filter" />
        </div>
        <div className="feed-container d-block">
          <div className="details-tab-view common-card-body">
            <div className="tabs-for-details-view">
              <Tabs>
                <TabList>
                  <Tab>
                    <img alt="" src={FeedIco} />
                    Trending
                  </Tab>
                  <Tab>
                    <img alt="" src={FeedIco} />
                    Popular
                  </Tab>
                  <Tab>
                    <img alt="" src={ShotsIco} />
                    Top Selection
                  </Tab>
                </TabList>

                <TabPanel className="recent-video-component ">
                  <h3 className="d-flex align-items-center justify-content-between mt-40">
                    Trending Health Products
                    {' '}
                    <button type="button" className="light-button" onClick={PlatformPreview}>
                      <span>Add New</span>
                    </button>
                  </h3>
                  <div className="store-container mt-30">
                    {product
                      .filter((item) => item.id === 'overview')
                      .map((item, index) => (
                        <div className="product-grid-item" key={index}>
                          <div
                            className="product-item-cover"
                            style={{
                              background: `url(${item.img})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }}
                          >
                            <div className="like">
                              <img alt="" src={LikeFill} />
                            </div>
                            <div className="rating">
                              <img alt="" src={RatingStar} />
                              <span>{item.rating}</span>
                            </div>
                          </div>
                          <div className="product-item-details d-flex  justify-content-between">
                            <span>{item.name}</span>
                            <div>
                              <h4>{item.price}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
                <TabPanel className="recent-video-component ">
                  <h3 className="d-flex align-items-center justify-content-between mt-40">
                    Trending Health Products
                    {' '}
                    <button type="button" className="light-button" onClick={PlatformPreview}>
                      <span>Add New</span>
                    </button>
                  </h3>
                  <div className="store-container mt-30">
                    {product
                      .filter((item) => item.id === 'trending')
                      .map((item, index) => (
                        <div className="product-grid-item" key={index}>
                          <div
                            className="product-item-cover"
                            style={{
                              background: `url(${item.img})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }}
                          >
                            <div className="like">
                              <img alt="" src={LikeFill} />
                            </div>
                            <div className="rating">
                              <img alt="" src={RatingStar} />
                              <span>{item.rating}</span>
                            </div>
                          </div>
                          <div className="product-item-details d-flex  justify-content-between">
                            <span>{item.name}</span>
                            <div>
                              <h4>{item.price}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
                <TabPanel className="recent-video-component">
                  <h3 className="d-flex align-items-center justify-content-between mt-40">
                    Trending Health Products
                    {' '}
                    <button type="button" className="light-button" onClick={PlatformPreview}>
                      <span>Add New</span>
                    </button>
                  </h3>
                  <div className="store-container mt-30">
                    {product
                      .filter((item) => item.id === 'popular')
                      .map((item, index) => (
                        <div className="product-grid-item" key={index}>
                          <div
                            className="product-item-cover"
                            style={{
                              background: `url(${item.img})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }}
                          >
                            <div className="like">
                              <img alt="" src={LikeFill} />
                            </div>
                            <div className="rating">
                              <img alt="" src={RatingStar} />
                              <span>{item.rating}</span>
                            </div>
                          </div>
                          <div className="product-item-details d-flex  justify-content-between">
                            <span>{item.name}</span>
                            <div>
                              <h4>{item.price}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {ShowPlatform && (
        <div className="add-item-model platform-select-model">
          <div className="add-item-content">
            <div className="platform-grid">
              {Platform.map((item, index) => (
                <div className="platform-item" key={index}>
                  <input type="radio" name="platform" id={index} />
                  <label htmlFor={index}>
                    <img alt="" src={item} />
                  </label>
                </div>
              ))}
            </div>
            <div className="button-row">
              <button
                type="button"
                className="secondary-btn-model me-4"
                onClick={HandleCancel}
              >
                <span>Cancel</span>
              </button>
              <button type="button" className="secondary-btn-model" onClick={HandleProduct}>
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      {ShowProduct && (
        <div className="add-item-model add-product-content">
          <div className="add-item-content add-item-content-store">
            <div className="product-add-image" ref={componentRef}>
              <h3 className="text-center">Add New Product</h3>
              <div>
                <div className="input-image-show align-items-end">
                  <div className="input-img-label">
                    <label htmlFor="input-img">
                      <img alt="" src={upload} />
                      <p className="m-0 gredient-txt">Upload Image</p>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="input-img"
                      className="d-none"
                      multiple
                      onChange={handleImageChange}
                    />
                  </div>
                  {selectedImages.map((image, index) => (
                    <div
                      className="image position-relative"
                      key={index}
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(image)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                      alt={`image-${index}`}
                    >
                      <button type="button" onClick={() => removeImage(index)}>
                        <img alt="" src={Remove} />
                      </button>
                      <button type="button" className="plus-img">
                        <img
                          alt=""
                          src={plusimg}
                          onClick={() => replaceImage(index)}
                        />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="vertical-divider mt-4" />
                <form className="item-details-input pt-3">
                  <div className="input-grid">
                    <div>
                      <label>Product Name</label>
                      <input placeholder="Name" />
                    </div>
                    <div>
                      <label>Brand Name</label>
                      <input placeholder="Name" />
                    </div>
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Product Price</label>
                      <input placeholder="price" />
                    </div>
                    <div>
                      <label>Return & Warranty</label>
                      <input placeholder="Return Date" />
                    </div>
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Skin Type</label>
                      <input placeholder="All" />
                    </div>
                    <div>
                      <label>Scent</label>
                      <input placeholder="Coconut" />
                    </div>
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Product Benefits</label>
                      <input placeholder="Benefits" />
                    </div>
                    <div>
                      <label>Item Form</label>
                      <input placeholder="Lotion" />
                    </div>
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Title of the Podcast</label>
                      <Dropdown
                        title="All"
                        Data={[
                          { title: 'M' },
                          { title: 'L' },
                          { title: 'XL' },
                          { title: 'XXL' }
                        ]}
                      />
                    </div>
                    <div>
                      <label>Quantities</label>
                      <input placeholder="Quantities" />
                    </div>
                  </div>
                  <div>
                    <label>Decription</label>
                    <textarea placeholder="Type here" />
                  </div>
                </form>
              </div>
            </div>
            <div className="button-row d-flex align-items-center justify-content-between">
              <div>
                <button
                  type="button"
                  className="secondary-btn-model me-4"
                  onClick={PlatformPreview}
                >
                  <span>Back</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={ProductPreview}
                >
                  Preview
                </button>
              </div>
              <button type="button" className="secondary-btn-model" onClick={HandleProduct}>
                Publish
              </button>
            </div>
          </div>
        </div>
      )}
      {ShowPreviewProduct && (
        <div className="add-item-model add-product-content ">
          <div
            className="add-item-content  add-item-content-store d-flex justify-content-between flex-column"
            ref={componentRef}
          >
            <div className="product-preview">
              <div className="row">
                <div className="col-md-6">
                  <div className="product-img-preview">
                    <div className="product-img-table">
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedImg(index)
                          }}
                          className={`product-img-item ${
                            selectedImg === index && 'product-img-blur'
                          }`}
                        >
                          <img
                            alt=""
                            className="w-100 h-100 object-fit-cover"
                            src={`${URL.createObjectURL(image)}`}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="product-img-selected" key="123">
                      <img
                        alt=""
                        src={`${URL.createObjectURL(
                          selectedImages[selectedImg],
                        )}`}
                        className="w-100 h-100 object-fit-cover"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 product-detail">
                  <h2>
                    SheaMoisture 100% coconut oil daily hydration body lotion
                    moisturizer
                  </h2>
                  <div className="ticket-overview--profile d-flex align-items-center col-gap-10 mt-0 mb-2">
                    Brand: Made in China
                    <span className="divider mx-3" />
                    <div className="rating-star justify-content-start">
                      {generateStars(4.5)}
                    </div>
                  </div>
                  <div className="vertical-divider" />
                  <div className="product-detail--price mb-3 mt-3">
                    <h4 className="linier-text m-0">$700</h4>
                    <h2 className="actual-price m-0">$800</h2>
                  </div>
                  <div className="vertical-divider" />
                  <div className="product-detail--warranty mt-3 mb-3">
                    <span>Return & Warranty</span>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <p>7 days Returns</p>
                      <p>Warranty not available</p>
                    </div>
                    <h6>Change of mind is not applicable</h6>
                  </div>
                  <div className="vertical-divider" />
                  <div className="price-ticket-counter--wrapper mb-3 mt-3">
                    <span>Quantity</span>
                    <div className="price--ticket-counter">
                      <button type="button" onClick={decrementCount}>-</button>
                      <div>{count}</div>
                      <button type="button" onClick={incrementCount}>+</button>
                    </div>
                  </div>
                  <div className="vertical-divider" />
                  <div className="product-detail--charges mt-3 mb-3">
                    Delivery charges: $100
                  </div>
                  <div className="d-flex col-gap-20">
                    <button type="button" className="primary-btn">Buy Now</button>
                    <button type="button" className="light-button">
                      <span>Add to Cart</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="tabs-for-details-view details-tab-view">
                <Tabs>
                  <TabList>
                    <Tab>
                      <img alt="" src={FeedIco} />
                      Product Detail
                    </Tab>
                    <Tab>
                      <img alt="" src={FeedIco} />
                      Reviews
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="product--detail mt-30">
                      <div className="recent-video-component common-card-body">
                        <h3>Specifications</h3>
                        <div className="product--detail-item">
                          <ul>
                            <li>
                              <span>Product Name</span>
                              <p>Product Name</p>
                            </li>
                            <li>
                              <span>Brand</span>
                              <p>Brand Name</p>
                            </li>
                            <li>
                              <span>Skin Type</span>
                              <p>All</p>
                            </li>
                            <li>
                              <span>Scent</span>
                              <p>Coconut</p>
                            </li>
                            <li>
                              <span>No of Handles</span>
                              <p>Package</p>
                            </li>
                            <li>
                              <span>Style</span>
                              <p>Package</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>Product Benefits</span>
                              <p>Soothe,Bright,Hydrate</p>
                            </li>
                            <li>
                              <span>Item Form </span>
                              <p>Lotion</p>
                            </li>
                            <li>
                              <span>Shape</span>
                              <p>Brand Name</p>
                            </li>
                            <li>
                              <span>Brand Name:</span>
                              <p>Brand Name</p>
                            </li>
                            <li>
                              <span>Brand Name:</span>
                              <p>Brand Name</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="discription-box recent-video-component mb-0 mt-30">
                      <h3>Decription</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Viverra quis lectus integer dictumst accumsan posuere in
                        imperdiet enim. Varius sed in blandit pharetra amet,
                        morbi risus. Velit aliquet neque purus est urna.
                        Pellentesque facilisi nunc quis pretium viverra tempor
                        urna, ornare aliquet. In tristique tincidunt arcu purus.
                        Mattis risus nulla lectus mi suspendisse lobortis tempus
                        enim. Phasellus amet, sollicitudin nibh facilisis
                        pellentesque bibendum varius massa.
                      </p>
                      <p>
                        Risus in nunc eget eget. Ipsum, tristique mauris aliquam
                        odio. Nibh volutpat, leo, morbi elementum. Tempus vel
                        molestie massa tortor id eros. Magna ac est ornare
                        lorem. Lectus turpis nisl at massa ac nec nascetur. Enim
                        leo nec non tristique. Neque, duis aliquet a feugiat et
                        vitae. Lobortis sed vel eros, diam in neque aenean
                        velit, porta. Pellentesque a viverra pretium condimentum
                        urna, urna, volutpat urna, lectus. Malesuada dolor
                        aenean tempus morbi eget est sit massa sed. Ut pharetra
                        libero, lorem mi. Pharetra, nunc, interdum maecenas
                        mauris quis nisl adipiscing sagittis facilisi.
                        Adipiscing pellentesque urna et ut nec nunc, semper mi.
                      </p>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="product-detail-card mt-20">
                      <div className="row">
                        <div className="product-img col-md-6">
                          <img alt="" src="./images/products/13.jpg" />
                        </div>
                        <div className="col-md-6">
                          <h3>Product Quality</h3>
                          <h2>4.5</h2>
                          <div className="rating-star mb-30 mt-1 justify-content-start">
                            {generateStars(4.5)}
                          </div>
                          <span>1987 ratings</span>
                        </div>
                      </div>
                      <div className="rating-column">
                        {percentage?.map((percentageValue, index) => (
                          <div
                            className="rating-progress-bar rating-progress-container"
                            key={index}
                          >
                            <span>{index + 1}</span>
                            <ProgressBar
                              className="profress-line"
                              baseBgColor="#E0E0E0"
                              height={20}
                              width={394}
                              completed={percentageValue}
                              borderRadius={25}
                              bgColor="linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%)"
                              isLabelVisible={false}
                            />
                            <div>
                              <span>
                                {percentageValue}
                                %
                              </span>
                              {' '}
                              <span>(1738)</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="button-row d-flex align-items-center justify-content-between mt-30">
              <div>
                <button
                  type="button"
                  className="secondary-btn-model me-4"
                  onClick={HandleProduct}
                >
                  <span>Back</span>
                </button>
                <button type="button" className="secondary-btn-model" onClick={HandleProduct}>
                  Change
                </button>
              </div>
              <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Products
