import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import plusBtn from '../../images/plus.svg'
import Remove from '../../images/remove.svg'

const Services = () => {
  const [ShowServiceModel, setShowServiceModel] = useState(false)
  const AddService = () => {
    setShowServiceModel(true)
  }
  const HandleCancel = () => {
    setShowServiceModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowServiceModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [ingredients, setIngredients] = useState([])
  const [newIngredient, setNewIngredient] = useState('')
  const [buttonText, setButtonText] = useState('Add another')

  const handleInputChange = (e) => {
    setNewIngredient(e.target.value)
  }

  const handleAddAnother = () => {
    if (newIngredient.trim() !== '') {
      setIngredients([...ingredients, newIngredient])
      setNewIngredient('')
      if (ingredients.length === 0) {
        setButtonText('Add more')
      }
    }
  }
  const handleRemoveIngredient = (index) => {
    const updatedIngredients = [...ingredients]
    updatedIngredients.splice(index, 1)
    setIngredients(updatedIngredients)

    // Reset button text when removing the last ingredient
    if (updatedIngredients.length === 0) {
      setButtonText('Add another')
    }
  }
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Services</h2>
          <button type="button" className="primary-btn" onClick={AddService}>
            Add New
          </button>
        </div>
        <div className="services-local-block-row">
          <div className="services-local-block add-value" />
          <div className="services-local-block add-value" />
          <div className="services-local-block add-value" />
        </div>
        <p className="service-txt add-value" />
      </div>
      {ShowServiceModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="ingredients-row">
              {ingredients.map((ingredient, index) => (
                <div key={index} className="badge-ingredients">
                  {ingredient}
                  <button type="button" onClick={() => handleRemoveIngredient(index)}>
                    <img alt="" src={Remove} />
                  </button>
                </div>
              ))}
            </div>
            <div className="item-details-input pt-0">
              <div className="mt-4">
                <label>Service Name</label>
                <input
                  placeholder="Title"
                  value={newIngredient}
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="button"
                className="primary-btn-model mb-3"
                onClick={handleAddAnother}
              >
                {buttonText === 'Add another' && (
                  <img alt="" src={plusBtn} className="me-2" />
                )}
                {buttonText}
              </button>
              <div>
                <label>Decription</label>
                <textarea placeholder="Type here" />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button type="button" className="secondary-btn-model">Add</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Services
