import React from 'react'
import './Dropdown.css'

class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      haveText: '',
      haveImg: ''
    }

    this.dropdownRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  handleOutsideClick = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }))
  }

  handleText = (ev, props) => {
    props.onChanged(ev)
    this.setState({
      haveText: ev.title,
      haveImg: ev.img
    })
  }

  itemList = (data, props) => {
    const list = data.map((item, index) => (
      <div
        onClick={() => this.handleText(item, props)}
        className="dropdown__item"
        key={item.title + index}
      >
        {item?.img && <img src={item?.img} alt="" />}
        {item?.title}
      </div>
    ))

    return <div className="dropdown__items">{list}</div>
  }

  render() {
    const { isOpen, haveText, haveImg } = this.state
    const { title, Data, img, customClass } = this?.props ?? {
      title: '',
      Data: [],
      img: '',
      customClass: ''
    }
    return (
      <div
        ref={this.dropdownRef}
        className={`${isOpen ? 'dropdown active' : 'dropdown'} ${customClass}`}
        onClick={this.handleClick}
      >
        <div className={`dropdown__text ${haveText ? 'selected' : ''}`}>
          {!haveText ? (title ?? 'Yearly') : haveText}
          {!haveImg ? <img src={img} alt="" /> : <img src={haveImg} alt="" />}
        </div>
        {isOpen &&
          this.itemList(
          Data ?? [
            { id: '', title: 'Azure Female', img: './scalco-table.svg' },
            { id: '', title: 'Azure Female', img: './scalco-table.svg' },
            { id: '', title: 'Azure Female', img: './scalco-table.svg' },
            { id: '', title: 'Azure Female', img: './scalco-table.svg' },
            { id: '', title: 'Azure Female', img: './scalco-table.svg' }
          ],
          this.props
        )}
      </div>
    )
  }
}
export default Dropdown
