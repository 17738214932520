import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../Dropdown/Dropdown'
import LikeFill from '../../images/like-empty.svg'
import Chief from '../../images/Chef.svg'
import RatingStar from '../../images/rating-start.svg'
import Remove from '../../images/remove.svg'
import plusimg from '../../images/plusimg.svg'
import plusBtn from '../../images/plus.svg'
import CookingTime from '../../images/cooking-time.svg'
import Servings from '../../images/serving.svg'
import Healthy from '../../images/healthy.svg'
import Calories from '../../images/calories.svg'
import Vegitarian from '../../images/vegitarien.svg'
import AddIco from '../../images/AddIco.svg'
import ClockFillIco from '../../images/ClockFillIco.svg'
import plusgredient from '../../images/plus-gredient.svg'

const RecipiesDetails = () => {
  const items = [
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    }
  ]
  const itemsRecipies = [
    {
      CoverImage: './images/recipiesgrid1.png'
    },
    {
      CoverImage: './images/recipiesgrid2.png'
    },
    {
      CoverImage: './images/recipiesgrid2.png'
    },
    {
      CoverImage: './images/recipiesgrid3.png'
    },
    {
      CoverImage: './images/recipiesgrid3.png'
    }
  ]
  const IngredientsOfSlowStep = [
    {
      title: 'Step 1',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lobortis suspendisse odio imperdiet nisl, dui non. Risus lorem nulla id mollis. Curabitur ipsum, volutpat nec et. Est nulla varius justo pellentesque.'
    },
    {
      title: 'Step 2',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lobortis suspendisse odio imperdiet nisl, dui non. Risus lorem nulla id mollis. Curabitur ipsum, volutpat nec et. Est nulla varius justo pellentesque.'
    },
    {
      title: 'Step 3',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lobortis suspendisse odio imperdiet nisl, dui non. Risus lorem nulla id mollis. Curabitur ipsum, volutpat nec et. Est nulla varius justo pellentesque.'
    },
    {
      title: 'Step 4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lobortis suspendisse odio imperdiet nisl, dui non. Risus lorem nulla id mollis. Curabitur ipsum, volutpat nec et. Est nulla varius justo pellentesque.'
    }
  ]
  const IngredientsOfSlow = [
    {
      CoverImage: './images/beefstew1.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew2.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew3.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew4.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew5.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew5.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew6.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew7.png',
      text: 'Pharetra montes at purus in cursus.'
    },
    {
      CoverImage: './images/beefstew8.png',
      text: 'Pharetra montes at purus in cursus.'
    }
  ]
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [ShowPodcastCoverModel, setShowPodcastCoverModel] = useState(false)
  const [ShowPodcastPreviewModel, setShowPodcastPreviewModel] = useState(false)
  const [ShowIngredientModel, setShowIngredientModel] = useState(false)
  const [ShowRecipeProcessModel, setShowRecipeProcessModel] = useState(false)
  const [ShowRecipePreview, setShowRecipePreview] = useState(false)
  const AddPodcast = () => {
    setShowPodcastModel(true)
  }
  const AddPodcastCover = () => {
    setShowPodcastCoverModel(true)
    setShowPodcastModel(false)
  }
  const AddPodcastPreview = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(true)
  }
  const HandleIndredient = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setShowIngredientModel(true)
  }
  const HandleRecipiesStep = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setShowIngredientModel(false)
    setShowRecipeProcessModel(true)
  }
  const HandleRecipiesPreview = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setShowIngredientModel(false)
    setShowRecipeProcessModel(false)
    setShowRecipePreview(true)
  }
  const HandleCancel = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setShowIngredientModel(false)
    setShowRecipeProcessModel(false)
    setShowRecipePreview(false)
  }

  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPodcastCoverModel(false)
        setShowPodcastModel(false)
        setShowPodcastPreviewModel(false)
        setShowIngredientModel(false)
        setShowRecipeProcessModel(false)
        setShowRecipePreview(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [selectedImages, setSelectedImages] = useState([])

  const handleImageChange = (e, indexToReplace) => {
    const { files } = e.target

    if (files.length > 0) {
      const updatedImages = [...selectedImages]

      if (indexToReplace !== undefined && indexToReplace !== null) {
        // Replace the image at the specified index
        updatedImages.splice(indexToReplace, 1, files[0])
      } else {
        // Add new images
        updatedImages.push(...files)
      }

      setSelectedImages(updatedImages)
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
  }

  const replaceImage = (index) => {
    const input = document.getElementById('input-img')

    const handleChange = (e) => {
      handleImageChange(e, index)
      input.removeEventListener('change', handleChange)
    }

    if (input) {
      input.value = ''
      input.removeEventListener('change', handleChange) // Remove existing event listener
      input.click()
      input.addEventListener('change', handleChange, { once: true })
    }
  }

  const [ingredients, setIngredients] = useState([])
  const [newIngredient, setNewIngredient] = useState('')
  const [buttonText, setButtonText] = useState('Add another')

  const handleInputChange = (e) => {
    setNewIngredient(e.target.value)
  }

  const handleAddAnother = () => {
    if (newIngredient.trim() !== '') {
      setIngredients([...ingredients, newIngredient])
      setNewIngredient('')
      if (ingredients.length === 0) {
        setButtonText('Add more')
      }
    }
  }
  const handleRemoveIngredient = (index) => {
    const updatedIngredients = [...ingredients]
    updatedIngredients.splice(index, 1)
    setIngredients(updatedIngredients)

    // Reset button text when removing the last ingredient
    if (updatedIngredients.length === 0) {
      setButtonText('Add another')
    }
  }

  const [step, setStep] = useState('')
  const [steps, setSteps] = useState([])
  const handleInputChangeStep = (e) => {
    setStep(e.target.value)
  }

  const handleAddStep = () => {
    if (step.trim() !== '') {
      setSteps([...steps, step])
      setStep('')
    }
  }
  return (
    <div className="podcast-details-manage">
      <div className="add-video-row">
        <Link onClick={AddPodcast}>
          <img src={AddIco} alt="" />
          Add New Recipie
        </Link>
        <div className="items-filter">
          <div className="position-relative input-position">
            <input placeholder="Search Project" />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
      </div>
      <div className="recipies-item-add">
        {items.map((item) => (
          <div className="recipies-item-detail" key={item.name}>
            <div className="video-item">
              <div
                className="video-cover"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="like">
                  <img src={LikeFill} alt="" />
                </div>
                <p className="time-duration">10:20</p>
              </div>
              <div className="video-details">
                <div className="d-flex justify-content-between">
                  <h6>
                    <img src={Chief} alt="" />
                    <span>{item.Title}</span>
                  </h6>
                  <div className="rating">
                    <img src={RatingStar} alt="" />
                    <span>{item.Rating}</span>
                  </div>
                </div>
                <p>{item.text}</p>
                <div className="other-info">
                  <span>
                    <img src={ClockFillIco} alt="" />
                    {item.days}
                  </span>
                  <span>{item.other}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {ShowPodcastModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <h3>Enter Recipie details</h3>
              <div>
                <label>Title of the Podcast</label>
                <input placeholder="Title" />
              </div>
              <div>
                <label>Decription</label>
                <textarea placeholder="Type here" />
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={AddPodcastCover}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastCoverModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div>
              <h3>Upload Images</h3>
              <div className="input-image-show">
                {selectedImages.map((image, index) => (
                  <div
                    className="image position-relative"
                    key={index}
                    style={{
                      backgroundImage: `url(${URL.createObjectURL(image)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                    alt={`image-${index}`}
                  >
                    <button type="button" onClick={() => removeImage(index)}>
                      <img src={Remove} alt="" />
                    </button>
                    <button type="button" className="plus-img">
                      <img src={plusimg} alt="" onClick={() => replaceImage(index)} />
                    </button>
                  </div>
                ))}

                <div className="input-img-label">
                  <label htmlFor="input-img">
                    <img src={plusgredient} alt="" />
                    <p className="m-0">Add Image</p>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id="input-img"
                    multiple
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            <div className="item-details-input">
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={AddPodcastPreview}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastPreviewModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <h3>Cooking Info</h3>

              <label>Select Cooking Time</label>
              <div className="cooking-time-filed position-relative">
                <input placeholder="Please select" />
              </div>
              <div className="input-grid">
                <div>
                  <label>Serving</label>
                  <Dropdown
                    title="4"
                    Data={[
                      { title: '1' },
                      { title: '2' },
                      { title: '3' },
                      { title: '4' },
                      { title: '5' }
                    ]}
                  />
                </div>
                <div>
                  <label>Type</label>
                  <Dropdown
                    title="Healthy"
                    Data={[
                      { title: 'Healthy' },
                      { title: 'Healthy' },
                      { title: 'Healthy' },
                      { title: 'Healthy' },
                      { title: 'Healthy' }
                    ]}
                  />
                </div>
              </div>
              <div>
                <label>Calories</label>
                <input placeholder="Please enter" />
              </div>
              <div>
                <label>Type of Recipe</label>
                <div className="custom-radio-input">
                  <div>
                    <input type="radio" id="vegitarian" name="diet" />
                    <label htmlFor="vegitarian">
                      <span />
                      Vegitarian
                    </label>
                  </div>
                  <div>
                    <input type="radio" id="nonVegitarian" name="diet" />
                    <label htmlFor="nonVegitarian">
                      <span />
                      Non-Vegitarian
                    </label>
                  </div>
                </div>
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={HandleIndredient}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowIngredientModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <h3>Ingredients</h3>
              <div className="ingredients-row">
                {ingredients.map((ingredient, index) => (
                  <div key={index} className="badge-ingredients">
                    {ingredient}
                    <button type="button" onClick={() => handleRemoveIngredient(index)}>
                      <img src={Remove} alt="" />
                    </button>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <label>Ingredient title</label>
                <input
                  placeholder="Title"
                  value={newIngredient}
                  onChange={handleInputChange}
                />
              </div>
              <button type="button" className="primary-btn-model" onClick={handleAddAnother}>
                {buttonText === 'Add another' && (
                  <img src={plusBtn} className="me-2" alt="" />
                )}
                {buttonText}
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={HandleRecipiesStep}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowRecipeProcessModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <h3>Recipe Process</h3>
              {steps.map((enteredStep, index) => (
                <div key={index} className="mt-4 recipies-step">
                  <label>
                    Step
                    {index + 1}
                    :
                  </label>
                  <span>{enteredStep}</span>
                </div>
              ))}
              <div className="mt-4">
                <label>
                  Step
                  {steps.length + 1}
                </label>
                <input
                  placeholder="Type here..."
                  value={step}
                  onChange={handleInputChangeStep}
                />
              </div>
              <button type="button" className="secondary-btn-model" onClick={handleAddStep}>
                Add
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={HandleRecipiesPreview}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowRecipePreview && (
        <div className="add-item-model preview-model-cources">
          <div className="add-item-content row-gap-30" ref={componentRef}>
            <div className="recipies-img-grid">
              {itemsRecipies.map((item) => (
                <div
                  className="recipies-img-item"
                  key={item.index}
                  style={{
                    background: `url(${item.CoverImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                />
              ))}
            </div>
            <div className="recipies-detail-text">
              <h5>SLOW COOKER BEEF STEW</h5>
              <p className="text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id
                arcu, quis enim, urna. Vitae pellentesque pellentesque feugiat
                mauris convallis lectus. Quam scelerisque et elementum in
                quisque nunc orci. Faucibus viverra vitae eros, egestas
                fringilla dignissim amet a. Neque mattis turpis interdum elit.
                Venenatis, scelerisque id adipiscing id blandit et. In bibendum
                ligula duis orci id nibh risus vulputate. Nunc risus
                pellentesque sed duis adipiscing. Bibendum cras quis egestas id
                sed.
              </p>
            </div>
            <div className="cooking-information">
              <h5>Cooking Info</h5>
              <ul>
                <li>
                  <img src={CookingTime} alt="" />
                  Cooking Time
                </li>
                <li>
                  <img src={Servings} alt="" />
                  4 Servings
                </li>
                <li>
                  <img src={Healthy} alt="" />
                  Healthy
                </li>
                <li>
                  <img src={Calories} alt="" />
                  200 Calories
                </li>
                <li>
                  <img src={Vegitarian} alt="" />
                  Vegitarian
                </li>
              </ul>
            </div>
            <div className="ingredients-of-slow">
              <h5>Ingredients of Slow Cooker Beef Stew</h5>
              <div className="ingredients-of-slow-row">
                {IngredientsOfSlow.map((item) => (
                  <div className="ingredients-of-slow-item" key={item.index}>
                    <div
                      className="ingredients-of-slow-image"
                      style={{
                        background: `url(${item.CoverImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundColor: 'rgb(141 141 141 / 20%)'
                      }}
                    />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="ingredients-of-slow-step">
              <h5>How to make Slow Cooker Beef Stew </h5>
              <div className="ingredients-of-slow-step-list">
                {IngredientsOfSlowStep.map((item) => (
                  <div
                    className="ingredients-of-slow-step-text"
                    key={item.title}
                  >
                    <h6>{item.title}</h6>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>Cancel</span>
                </button>
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default RecipiesDetails
