import React from 'react'
import VideoComponent from './VideoComponent'

const AddVideo = () => {
  return (
    <div className="content-manager-awards-wrapper common-card-body add-profile-video">
      <VideoComponent showCount={3} />
    </div>
  )
}
  export default AddVideo
