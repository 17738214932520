import React from 'react'
import { Link } from 'react-router-dom'
import SideBarPage from './SidebarPage'

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6 bg-secondary-gredient">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="w-100 inline-padding">
              <Link to="/login">
                <button type="button" className="primary-button m-auto mb-10 login-mb">
                  <span>Login</span>
                </button>
              </Link>
              <Link to="/register">
                <button type="button" className="primary-button m-auto">
                  <span>Create Account</span>
                </button>
              </Link>
              <p className="clip-text landing-text">
                Want to upgrade your existing APP WORLD ONE Account to an APP
                WORLD ONE Business Account?
                {' '}
                <Link to="/login" className="inline-button mt-3">
                  Click here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LandingPage
