import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Scaloo from '../../images/scaloo.svg'
import LuxuryEleven from '../../images/luxury-eleven.svg'
import Fyerdates from '../../images/fyerdates.svg'
import Spacure from '../../images/spacure.svg'
import FamilyTrends from '../../images/family-trends.svg'
import AZFit from '../../images/a-z-feet.svg'
import Testiee from '../../images/testiee.svg'
import GoAthlete from '../../images/go-athlete.svg'
import CreatorTelents from '../../images/creator-talents.svg'
import TheTestClub from '../../images/the-teste-club.svg'
import Filiyo from '../../images/filiyo.svg'
import Petzino from '../../images/petzino.svg'
import Logo from '../../images/logoicons.svg'


const AppIcons = () => {
  const navigate = useNavigate()

  const iconClick = () => {
    navigate('/create-profile')
  }

  return (
    <div className="app-icons">
      <div className="icons-row">
        <div className="icons-table">
          <img alt="" src={Scaloo} />
          <img alt="" src={LuxuryEleven} />
          <img onClick={() => iconClick()} alt="" src={Fyerdates} />
          <img alt="" src={Spacure} />
          <img alt="" src={FamilyTrends} />
          <img alt="" src={AZFit} />
        </div>
        <div className="logo-view">
          <Link to="/create-profile">
            <div className="logo-gredient">
              <img alt="" src={Logo} />
            </div>
          </Link>
        </div>
        <div className="icons-table">
          <img alt="" src={Testiee} />
          <img alt="" src={CreatorTelents} />
          <img alt="" src={TheTestClub} />
          <img alt="" src={GoAthlete} />
          <img alt="" src={Filiyo} />
          <img alt="" src={Petzino} />
        </div>
      </div>
    </div>
  )
}
export default AppIcons
