/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Comment from '../../images/ls_comments.svg'
import IconsMore from '../../images/akar-icons_more-horizontal-fill.svg'
import GroupDiscussion from '../../images/Group-discussion.svg'
import {
  getDiscussionLibrary,
  s3ImagePath
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import { timeDifference } from '../../helpers/Utility'
import Plus from '../../images/plus-rounded.svg'

const DiscussionsDetails = () => {
  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [discussionData, setDiscussionData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 2 } })
  }

  const getDiscussionLibraryData = () => {
    setIsLoading(true)
    getDiscussionLibrary({}).then((res) => {
      if (res.data.success) {
        setDiscussionData(dataDecrypt(res.data.data).data)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    getDiscussionLibraryData()
  }, [])

  return (
    <div className="content-manager-discussions-wrapper common-card-body community-margin">
      <div className="discussions-wrapper-header common-contnt-wrapper">
        <h2>Discussions</h2>
        <div className="" onClick={viewAll}>
          <Link>View All</Link>
        </div>

      </div>
      {discussionData.length === 0 && !isLoading ? (
        <div className="add-discussions-container">
          <div className="add-discussions-item">
            {/* <h5 className="giveaways_text">No Discussion Available</h5> */}
            <div className="disscussion-item-profile-detail">
              <div className="disscussion-profile-img add-value" />
              <div className="disscussion-profile-txt">
                <h2 className="add-value">Title</h2>
                <h3 className="add-value">-</h3>
                <p className="add-value">Decription</p>
              </div>
            </div>
            <div className="disscussion-item-other">
              <div className="disscussion-group-member add-value" />
              <div className="disscussion-chat add-value" />
              <div className="position-relative">
                <button
                  type="button"
                  className="add-item-btn"
                >
                  <img src={Plus} alt="add discussion" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="discussions-item position-relative">
          <div className="discussion-profile">

            <Skeleton className="discssion-profile-image border-0" />
            <div className="discssion-detail">
              <h5><Skeleton max-width={700} height={20} /></h5>
              <span className="mb-3">
                <Skeleton max-width={200} height={10} />
              </span>
              <p><Skeleton max-width={700} height={30} /></p>
            </div>
          </div>
          <div className="discussion-group">
            <Skeleton circle width={30} height={30} />
          </div>
          <div
            className="discussion-comment"
          >
            <Skeleton width={25} height={25} />
            <Skeleton width={25} />
          </div>
          <div className="discussion-button">
            <button type="button">
              <Skeleton width={10} height={35} />
            </button>
          </div>
        </div>
        ) : (
      discussionData.slice(0, 3).map((item, i) => (
        <div className="discussions-item position-relative" key={i}>
          <div className="discussion-profile">
            <div
              className="discssion-profile-image"
              style={{
                background: `url(${s3ImagePath + userData.profile_image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            />
            <div className="discssion-detail">
              <h5>{item.topic}</h5>
              <span>
                <b>{userData.user_name}</b>
                {' '}
                {timeDifference(item.created_at)}
              </span>
              <p>{item.description}</p>
            </div>
          </div>
          <div className="discussion-group">
            {/* {item?.groupImg.map((img, index) => (
              <img alt=""key={index} src={img} />
            ))} */}
            <img alt="" src={GroupDiscussion} />
          </div>
          <div className="discussion-comment">
            <img alt="" src={Comment} />
            {item.total_replies}
          </div>
          <div className="discussion-button">
            <button type="button">
              <img alt="" src={IconsMore} />
            </button>
          </div>
          {item.app_data.length > 0 && (
            <span
              className={`platform-badge ${
                item.app_data.length === 2 ? 'platform-badge-alts' : ''
              }`}
            >
              {item.app_data.map((data, i) => (
                <div
                  key={i}
                  className={`badge-cover ${
                    item.app_data.length === 2 ? 'badge-cover-alts' : ''
                  }`}
                  style={{
                    backgroundImage: `url(${s3ImagePath + data.icon})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '90%'
                  }}
                />
              ))}
            </span>
          )}
        </div>
      ))
      )}


    </div>
  )
}
export default DiscussionsDetails
