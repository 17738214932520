import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import call from '../../images/call.svg'
import messenger from '../../images/messanger.svg'
import email from '../../images/email.svg'
import usergroup from '../../images/usergroup.svg'
import location from '../../images/Locations.svg'
import gym from '../../images/gym.svg'

const About = () => {
  return (
    <div className="services-container common-card-body recent-video-component">
      <div className="contact-info-item map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
          width="100%"
          height="452"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps"
        />
      </div>
      <div className="discription-box">
        <h3>Description</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin iaculis
          morbi tempus volutpat tortor. Ipsum vitae enim sit suscipit in
          ridiculus. Morbi potenti eu facilisis vitae posuere suspendisse.
          Praesent sit lorem amet posuere vel diam scelerisque a. Nisl imperdiet
          egestas dui consequat, diam et, at tempor.
        </p>
      </div>
      <div className="contact-info-grid">
        <div className="contact-info-item information common-card-body">
          <h3>General</h3>
          <ul>
            <li>
              <img src={usergroup} alt="userGroup icon" />
              <Link>6,245 people follow this</Link>
            </li>
            <li>
              <img src={location} alt="location icon" />
              <Link>1,95 people checked in here</Link>
            </li>
            <li className="e-mail">
              <img src={gym} alt="gym icon" />
              <Link>Gym/Physical Fitness Center </Link>
            </li>
          </ul>
        </div>
        <div className="contact-info-item information common-card-body">
          <h3>Contact Info</h3>
          <ul>
            <li>
              <img src={call} alt="call icon" />
              <Link>+358 25 466 5896</Link>
            </li>
            <li>
              <img src={messenger} alt="message icon" />
              <Link>Send message</Link>
            </li>
            <li className="e-mail">
              <img src={email} alt="email icon" />
              <Link>kenzi.lawson@example.com</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default About
