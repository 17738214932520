const EndPoints = {
  register: '/api/register',
  login: '/api/login',
  otpVerification: '/api/otp-verification',
  forgotPassword: '/api/forgot-password',
  resetPassword: '/api/password-change',
  userUpdateProfile: '/api/update-profile',
  userProfile: '/api/profile',
  userTypes: '/api/user-types',
  applications: '/api/applications',
  applicationsAddons: '/api/application-addon',
  addons: '/api/add-ons',
  getOrganization: '/api/organization-types',
  createProfile: '/api/create-profile',
  editAddons: '/api/edit-addon',
  storeProfileDetails: '/api/store-profile-detials',
  creatorProfileDetails: '/api/creator-profile-detials',
  getBanner: '/api/banner',
  editBanner: '/api/banner/add-edit-banner',
  deleteBanner: '/api/banner/delete-banner',
  addProductLibrary: '/api/product-library/add-product-library',
  category: '/api/category',
  subCategory: '/api/sub-category',
  getProductLibrary: '/api/product-library',
  editProductLibrary: '/api/product-library/edit-product-library',
  deleteProductLibrary: '/api/product-library/delete-product-library',
  addTrendingProduct: '/api/trending-product/add-trending-product',
  getTrendingProduct: '/api/trending-product',
  deleteTrendingProduct: '/api/trending-product/delete-trending-product',
  getGeneralProduct: '/api/general-product',
  addGeneralProduct: '/api/general-product/add-general-product',
  deleteGeneralProduct: '/api/general-product/delete-general-product',
  editStoreProfile: '/api/edit-store-profile',
  editCreatorProfile: '/api/edit-creator-profile',
  productDetail: '/api/product-details',
  getFeed: '/api/feed',
  likeFeed: '/api/feed/like',
  saveFeed: '/api/feed/save',
  uploadImage: '/api/upload-file',
  getPost: '/api/post',
  addPost: '/api/post/add-post',
  editPost: '/api/post/edit-post',
  deletePost: '/api/post/delete-post',
  getStories: '/api/stories',
  deleteStories: '/api/stories/delete-stories',
  getVideoLibrary: '/api/video/video-library',
  addVideo: '/api/video/add-video',
  deleteVideo: '/api/video/delete-video',
  editVideo: '/api/video/edit-video',
  getPodcastLibrary: '/api/podcast/podcast-library',
  addPodcast: '/api/podcast/add-podcast',
  hostList: '/api/podcast/host',
  deletePodcast: '/api/podcast/delete-podcast',
  editPodcast: '/api/podcast/edit-podcast',
  getShotLibrary: '/api/shot/shot-library',
  addShot: '/api/shot/add-shot',
  deleteShot: '/api/shot/delete-shot',
  editShot: '/api/shot/edit-shot',
  getVideos: '/api/video',
  getShots: '/api/shot',
  getPodcasts: '/api/podcast',
  videoUnpublish: '/api/video/unpublish-video',
  podcastUnpublish: '/api/podcast/unpublish-podcast',
  shotUnpublish: '/api/shot/unpublish-shot',
  getBrand: '/api/brand',
  getType: '/api/type',
  addGiveaway: '/api/giveaway/add-giveaway',
  getGiveawayLibrary: '/api/giveaway/giveaway-library',
  editGiveaway: '/api/giveaway/edit-giveaway',
  deleteGiveaway: '/api/giveaway/delete-giveaway',
  giveawayParticipate: '/api/giveaway/participate',
  giveawayParticipateList: '/api/giveaway/participate-list',
  getGiveaways: '/api/giveaway',
  giveawayUnpublish: '/api/giveaway/unpublish-giveaway',
  getAwardsLibrary: '/api/award/award-library',
  addAward: '/api/award/add-award',
  editAward: '/api/award/edit-award',
  deleteAward: '/api/award/delete-award',
  getAwards: '/api/award',
  awardsUnpublish: '/api/award/unpublish-award',
  addPoll: '/api/poll/add-poll',
  editPoll: '/api/poll/edit-poll',
  deletePoll: '/api/poll/delete-poll',
  getPollsLibrary: '/api/poll/poll-library',
  getPolls: '/api/poll',
  pollsUnpublish: '/api/poll/unpublish-poll',
  getCategory: '/api/category',
  getSubCategory: '/api/sub-category',
  addVote: '/api/poll/vote',
  voterList: '/api/poll/voter-list',
  addDiscussion: '/api/discussion/add-discussion',
  editDiscussion: '/api/discussion/edit-discussion',
  deleteDiscussion: '/api/discussion/delete-discussion',
  getDiscussionLibrary: '/api/discussion/discussion-library',
  getDiscussion: '/api/discussion',
  discussionUnpublish: '/api/discussion/unpublish-discussion',
  discussionReply: '/api/discussion/reply',
  discussionAddReply: '/api/discussion/reply/add-reply',
  discussionEditReply: '/api/discussion/reply/edit-reply',
  discussionDeleteReply: '/api/discussion/reply/delete-reply',
  discussionReplyLike: '/api/discussion/reply/like',
  videoViewCount: '/api/video/add-view',
  shotViewCount: '/api/shot/add-view',
  shotComment: '/api/shot/comment',
  shotCommentLike: '/api/shot/comment/like',
  shotCommentAdd: '/api/shot/comment/add-comment',
  shotCommentEdit: '/api/shot/comment/edit-comment',
  shotCommentDelete: '/api/shot/comment/delete-comment',
  shotLike: '/api/shot/like',
  shotSave: '/api/shot/save',
  giveawayLike: '/api/giveaway/like',
  pollLike: '/api/poll/like',
  discussionLike: '/api/discussion/like',
  videoLike: '/api/video/like',
  podcastLike: '/api/podcast/like',
  videoComment: '/api/video/comment',
  videoCommentLike: '/api/video/comment/like',
  videoCommentAdd: '/api/video/comment/add-comment',
  videoCommentEdit: '/api/video/comment/edit-comment',
  videoCommentDelete: '/api/video/comment/delete-comment',
  feedComment: '/api/feed/comment',
  feedCommentLike: '/api/feed/comment/like',
  feedCommentAdd: '/api/feed/comment/add-comment',
  feedCommentEdit: '/api/feed/comment/edit-comment',
  feedCommentDelete: '/api/feed/comment/delete-comment',
  feedCommentReply: '/api/feed/comment/add-reply',
  getReportReasons: '/api/report-reasons',
  feedReport: '/api/feed/report-feed',
  followerList: '/api/followers',
  followCreator: '/api/followers/follow-creator',
  creatorList: '/api/creator-list'

}

export default EndPoints
