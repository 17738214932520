import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'
import Sidebar from '../sidebar/Sidebar'

const Layout = () => {
  return (
    <>
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="sidebar-wrapper">
        <Sidebar />
      </div>
      <Outlet />
    </>
  )
}
export default Layout
