/* eslint-disable import/no-duplicates */
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import Logo from '../../images/azfitlogo.png'
import 'react-accessible-accordion/dist/fancy-example.css'
import Delete from '../../images/delete.svg'
import Add from '../../images/Plus-tag.svg'
import ShotsIco from '../../images/Plus-tag.svg'
import MegazinesIco from '../../images/Plus-tag.svg'
import RecipiesIco from '../../images/Plus-tag.svg'
import CoursesIco from '../../images/Plus-tag.svg'
import PodcastIco from '../../images/Plus-tag.svg'
import VideoIco from '../../images/Plus-tag.svg'

const Categories = () => {
  return (
    <div className="categories-admin">
      <div className="common-card-body">
        <h3>Platform Logo</h3>
        <div className="add-delete-logo">
          <div className="logo-image">
            <img alt="" src={Logo} />
          </div>
          <div className="logo-button">
            <button type="button" className="primary-btn">
              <span>Change Logo</span>
            </button>
            <button type="button" className="light-button">
              <span>Delete Logo</span>
            </button>
          </div>
        </div>
      </div>
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={VideoIco} />
                  Videos
                </Tab>
                <Tab>
                  <img alt="" src={PodcastIco} />
                  Podcasts
                </Tab>
                <Tab>
                  <img alt="" src={CoursesIco} />
                  Courses
                </Tab>
                <Tab>
                  <img alt="" src={RecipiesIco} />
                  Recipies
                </Tab>
                <Tab>
                  <img alt="" src={MegazinesIco} />
                  Megazines
                </Tab>
                <Tab>
                  <img alt="" src={ShotsIco} />
                  Shots
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <div className="category-top">
                    <h3>Categories</h3>
                    <div>
                      <button type="button" className="light-button">
                        <span>
                          <img alt="" src={Delete} />
                          {' '}
                          Remove Selected
                        </span>
                      </button>
                      <button type="button" className="primary-btn">
                        <span>
                          <img alt="" src={Add} />
                          {' '}
                          Add New Category
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="category-accordion">
                    <Accordion>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Health</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Recipes</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Running</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Organic</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </TabPanel>
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Categories
