/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import Dropdown from '../Dropdown/Dropdown'
import UploadImg from '../../images/upload.svg'
import AddIco from '../../images/AddIco.svg'
import ViewIco from '../../images/ViewIco.svg'
import ClockFillIco from '../../images/ClockFillIco.svg'
import PlatformTag from './PlatformTag'
import {
  imageUploadCreator,
  s3ImagePath,
  addVideoData,
  s3ImagePathCreator,
  deleteVideoData,
  getVideosLibrary,
  editVideoData,
  videoViewCountData,
  videoCommentData,
  videoCommentAddData,
  videoCommentEditData,
  videoCommentDeleteData,
  videoCommentLikeData,
  videoLike
} from '../../services/Service'
import {
  compressImage,
  compressVideo,
  dataDecrypt
} from '../../services/http-services'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import Likes from '../../images/like-fill.svg'
import UnLikes from '../../images/like-empty.svg'
import Delete from '../../images/delete.svg'
import {
  capitalizeFirstLetter,
  debounce,
  formatDuration,
  formatViews,
  timeDifference
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommentPagination from '../Pagination/CommentPagination'
import Plus from '../../images/plus-rounded.svg'

const VideoDetails = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const videoRef = useRef(null)
  const componentRef = useRef()
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    duration: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    search: ''
  })
  const [Platformtags, setPlatformTags] = useState([])
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [previewModel, setPreviewModel] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [videoData, setVideoData] = useState([])
  const [delVideoId, setDelVideoId] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [initialPlatformTags, setInitialPlatformTags] = useState([])
  const [initialDescription, setInitialDescription] = useState('')
  const [hasPlayed, setHasPlayed] = useState(false)
  const [hasFinished, setHasFinished] = useState(false)

  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      duration: '',
      attachment: '',
      cover_attachment: '',
      app_id: '',
      video_id: ''
    })
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  const handleButtonClick = () => {
    setShowAddVideoModal(true)
  }
  const VideoDetailModel = () => {
    setShowModal(true)
    setShowAddVideoModal(false)
  }
  const cancelHandle = () => {
    setShowPreviewModal(false)
    setPreviewModel(false)
    clearValue()
    setHasFinished(true)
  }
  const handleCancel = () => {
    clearValue()
    setShowModal(false)
    setShowAddVideoModal(false)
    setShowConfirmModel(false)
    setPreviewModel(false)
    setIsEditing(false)
    setHasFinished(true)
  }
  const handleBack = () => {
    setShowModal(false)
    setShowAddVideoModal(true)
  }
  const handlePreviewClick = () => {
    setShowModal(false)
    setShowPreviewModal(true)
  }

  const ShowConfirm = (videoId) => {
    setDelVideoId(videoId)
    setShowConfirmModel(true)
  }

  const onClickComment = (video_id) => {
    setSelectedId(video_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      videoComment(video_id)
      setCommentSection(true)
    }
  }

  const HandleCancel = () => {
    getVideosLibraryData()
    setCommentSection(false)
    commentClear()
  }

  const handleVideoPlay = (video_id) => {
    if (!hasPlayed || hasFinished) {
      viewCount(video_id)
      setHasPlayed(true)
      setHasFinished(false)
    }
  }

  const handleVideoEnd = () => {
    setHasFinished(true)
  }

  const getVideosLibraryData = (search) => {
    setIsLoading(true)
    const data = {
      search
    }
    getVideosLibrary(data).then((res) => {
      if (res.data.success) {
        setVideoData(dataDecrypt(res.data.data).data)
        setIsLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getVideosLibraryData(inputVal), 500),
    []
  )

  const deleteVideo = () => {
    const data = {
      video_id: delVideoId
    }
    setLoading(true)
    deleteVideoData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getVideosLibraryData()
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const editVideoApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      video_id: inputFields.video_id
    }
    editVideoData(data).then((res) => {
      if (res.data.success) {
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
        getVideosLibraryData()
        setShowConfirmModel(false)
        setShowPreviewModal(false)
        setPreviewModel(false)
        setIsEditing(false)
        setHasFinished(true)
      }
    })
  }

  const handlePublishClick = async () => {
    const durationInSeconds = Math.trunc(videoRef.current.duration)
    const formattedDuration = formatDuration(durationInSeconds)

    inputFields.duration = formattedDuration

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'video')

        const coverUploadResponse = await imageUploadCreator(coverFormData)
        if (!coverUploadResponse.data.success) {
          throw new Error(
            coverUploadResponse.data.message || 'Cover image upload failed'
          )
        }
        const decryptedCoverData = dataDecrypt(coverUploadResponse.data.data)
        inputFields.cover_attachment = decryptedCoverData
      }

      if (!isEditing || selectedVideoObj) {
        const videoFile = await compressVideo(selectedVideoObj)
        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'video')

        const videoUploadResponse = await imageUploadCreator(videoFormData)

        if (!videoUploadResponse.data.success) {
          throw new Error(
            videoUploadResponse.data.message || 'Video upload failed'
          )
        }
        const decryptedVideoData = dataDecrypt(videoUploadResponse.data.data)
        inputFields.attachment = decryptedVideoData
      }

      if (isEditing) {
        await editVideoApiCall()
      } else {
        await addVideoApiCall()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const addVideoApiCall = () => {
    addVideoData(inputFields).then((res) => {
      if (res.data.success) {
        setShowPreviewModal(false)
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
        getVideosLibraryData()
        setIsEditing(false)
        setHasFinished(true)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const viewCount = (videoId) => {
    const data = {
      video_id: videoId
    }
    videoViewCountData(data).then((res) => {
      if (res.data.success) {
        //
      }
    })
  }

  const videoLikeData = (video_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData?.id,
      video_id
    }
    videoLike(platFormData).then((res) => {
      if (res.data.success) {
        getVideosLibraryData()
        toast.success(res?.data?.message)
      }
    })
  }

  const handleFileChange = (event) => {
    const fileInput = event.target
    const file = fileInput.files[0]
    if (file) {
      setSelectedFileObj(file)
      const reader = new FileReader()
      reader.onload = (e) => {
        setSelectedFilePath(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleVideoChange = (event) => {
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      setSelectedVideoObj(selectedFile)
      const videoURL = URL.createObjectURL(selectedFile)
      setSelectedVideo(videoURL)
      console.log('===============', selectedFile)
    }
  }

  const onClickEditPodcast = (videoData) => {
    setShowAddVideoModal(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id
    })
    setInitialValues({
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id,
      search: ''
    })
    setPlatformTags(videoData.app_data)
  }

  const handleItemClick = (videoData) => {
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id
    })
    setPlatformTags(videoData.app_data)
    setInitialPlatformTags(videoData.app_data)
    setInitialDescription(videoData.description)
  }

  const validateForm = () => {
    const { title, description } = inputFields
    setIsFormComplete(!!title && !!description)
  }

  const videoComment = (video_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: [parsedData?.id],
      video_id
    }
    videoCommentData(platFormData).then((res) => {
      if (res.data.success) {
        const dataComment = dataDecrypt(res.data.data).data.map((d, i) => ({
          id: i + 1,
          text: d.comment,
          video_id: d.video_id,
          is_like: d.is_like,
          reply_id: d.comment_id,
          total_like: d.total_like,
          replies: d.reply_data.map((subData, subIndex) => ({
            id: subIndex + 1,
            text: subData.comment,
            reply_id: subData.comment_id,
            total_like: subData.total_like,
            is_like: subData.is_like,
            user: {
              name: subData.user_name,
              profilePic: s3ImagePath + subData.profile_icon
            }
          })),
          user: {
            name: d.user_name,
            profilePic: s3ImagePath + d.profile_icon
          }
        }))
        setComments(dataComment)
        setIsLoadingComments(false)
      }
    })
  }

  const shotAddComment = (comment) => {
    const platFormData = {
      video_id: selectedId,
      comment
    }
    videoCommentAddData(platFormData).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
      }
    })
  }

  const shotAddCommentReply = (comment_id, replyText) => {
    const platFormData = {
      video_id: selectedId,
      comment_id,
      comment: replyText
    }
    videoCommentAddData(platFormData).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
      }
    })
  }

  const shotCommentEdit = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    videoCommentEditData(platFormData).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
      }
    })
  }

  const shotCommentEditReply = (comment_id, replyText) => {
    const platFormData = {
      video_id: selectedId,
      comment_id,
      comment: replyText
    }
    videoCommentEditData(platFormData).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
      }
    })
  }

  const shotCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    videoCommentDeleteData(data).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const shotCommentDeleteReply = (commentId, replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: commentId,
      reply_id: replyId
    }
    videoCommentDeleteData(data).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const shotCommentLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData?.id,
      comment_id: reply_id
    }
    videoCommentLikeData(platFormData).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const shotCommentReplyLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData?.id,
      comment_id: reply_id
    }
    videoCommentLikeData(platFormData).then((res) => {
      if (res.data.success) {
        videoComment(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const handleAddComment = (comment, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        video_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    shotAddComment(comment)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImagePath + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    shotAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId) => {
    setShowReplies((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId]
    }))
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    shotCommentEdit(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ?
               { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    shotCommentEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  // videos pagination

  const videosPerPage = 11
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const videoDataAll = videoData.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = videoData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  const handlePageChanges = (pageNumber) => {
    setCurrentPageComment(pageNumber)
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    getVideosLibraryData()
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          setShowModal(false)
          setShowPreviewModal(false)
          setShowAddVideoModal(false)
          setShowConfirmModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }
    if (
      !loading &&
      (showModal ||
        showPreviewModal ||
        showAddVideoModal ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    validateForm()
  }, [inputFields, isEditing])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    } else {
      validateForm()
    }
  }, [inputFields, initialValues, isEditing])

  return (
    <div>
      <div className="add-video-row">
        <Link onClick={handleButtonClick}>
          <img src={AddIco} alt="" />
          Add New Video
        </Link>
        <div className="items-filter">
          <div className="position-relative input-position">
            <input
              placeholder="Search Video"
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
          </div>
          <Dropdown
            customClass="content-filter disabledDropdown"
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
      </div>

      <div className="video-detail-manager">
        {videoDataAll.length === 0 && !isLoading ? (
          <div className="awards-item-video">
            <div className="add-profile-video-cover">
              <button type="button" onClick={handleButtonClick}>
                <img src={Plus} alt="add library" />
              </button>
            </div>
            <div className="add-profile-video-detail">
              <p />
              <div className="d-flex align-items-center justify-content-between">
                <span />
                <span />
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="video-detail-item">
            <div className="video-detail-cover">
              <div className="video_like_bg">
                <button type="button" className="video_like">
                  <Skeleton width={25} height={25} />
                </button>
              </div>
            </div>
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          videoDataAll.map((item, index) => (
            <div
              className="video-detail-item"
              key={s3ImagePathCreator + item.cover_attachment}
            >
              <div
                className="video-detail-cover"
                onClick={() => handleItemClick(item)}
                style={{
                  backgroundImage: `url(${
                    s3ImagePathCreator + item.cover_attachment
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="video_like_bg">
                  <button
                    type="button"
                    className="video_like"
                    onClick={(e) => {
                      e.stopPropagation()
                      videoLikeData(item.video_id)
                    }}
                  >
                    {item.is_like ? (
                      <img src={Likes} alt="Unlike" />
                    ) : (
                      <img src={UnLikes} alt="Like" />
                    )}
                  </button>
                </div>
                {item.app_data.length > 0 && (
                  <span className="activity-badge">Published</span>
                )}
                {item.app_data.length > 0 && (
                  <span
                    className={`platform-badge ${
                      item.app_data.length === 2 ? 'platform-badge-alt' : ''
                    }`}
                  >
                    {item.app_data.map((data, i) => (
                      <div
                        key={i}
                        className={`badge-cover ${
                          item.app_data.length === 2 ? 'badge-cover-alt' : ''
                        }`}
                        style={{
                          backgroundImage: `url(${s3ImagePath + data.icon})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '90%'
                        }}
                      />
                    ))}
                  </span>
                )}
              </div>
              <div className="video-content">
                <p>{item.title}</p>
                <div className="views-and-time">
                  <span>
                    <img src={ViewIco} alt="" />
                    {formatViews(item.views)}
                  </span>
                  <span>
                    <img src={ClockFillIco} alt="" />
                    {timeDifference(item.created_at)}
                  </span>
                </div>
              </div>
              <div className="image-buttons z-1">
                <label>
                  <button
                    type="button"
                    onClick={() => onClickEditPodcast(item)}
                    className="d-none"
                  />
                </label>
                <button
                  type="button"
                  className="p-0"
                  onClick={() => {
                    setUpdatingIndex(index)
                    ShowConfirm(item.video_id)
                  }}
                />
                <div
                  type="button"
                  className="button1"
                  onClick={() => onClickComment(item.video_id)}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel && updatingIndex === index}
                onConfirm={deleteVideo}
                onCancel={handleCancel}
                message="Do you want to delete this?"
                isLoading={loading}
              />
              {loading && updatingIndex === index && <Loader />}
            </div>
          ))
        )}
        {previewModel && (
          <div className="add-item-model">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>Upload Files</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={handleCancel}
                >
                  <span>
                    <img className="close-btns" src={CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars">
                <div className="common-card-body">
                  <div className="video-player-container">
                    <video
                      ref={videoRef}
                      width="100%"
                      height="394"
                      poster={selectedFilePath}
                      controls
                      className="video-player"
                      onPlay={() => handleVideoPlay(inputFields.video_id)}
                      onEnded={handleVideoEnd}
                    >
                      <source src={selectedVideo} type="video/mp4" />
                    </video>
                  </div>
                  <div className="show-filled-details">
                    <h5>{inputFields.title}</h5>
                  </div>
                </div>
                <div className="discription common-card-body">
                  <h6>Discription</h6>
                  <textarea
                    className="preview_text"
                    onChange={(e) => setInputFields({
                        ...inputFields,
                        description: e.target.value
                      })}
                    value={inputFields.description}
                  >
                    <p>{inputFields.description}</p>
                  </textarea>
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
                <div className="item-details-input">
                  <div className="button-row">
                    <button type="button" className="secondary-btn-model">
                      <span onClick={cancelHandle} disabled={loading}>
                        Cancel
                      </span>
                    </button>
                    <button
                      type="button"
                      className={`primary-btn-model ${loading ? 'loading' : ''}`}
                      onClick={handlePublishClick}
                      disabled={
                        loading ||
                        (_.isEqual(initialPlatformTags, Platformtags) &&
                          initialDescription === inputFields.description)
                      }
                    >
                      {isAppsSelected ? 'Publish' : 'Upload'}
                      {loading && <span className="spinner" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {commentSection && (
          <div className="add-item-modal mh-100">
            <div className="add-item-contents">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="mb-0">Video Comment Section</h3>
                <button
                  type="button"
                  className="secondary-btn-modal"
                  onClick={HandleCancel}
                >
                  <img className="close-btns" src={CloseIco} alt="clock icon" />
                </button>
              </div>
              <div className="comment-section">
                {isLoadingComments ? (
                  <div>
                    <div className="comments-container">
                      <ul className="comments-list">
                        <li className="comment-item">
                          <button type="button" className="comment-delete">
                            <Skeleton
                              className="rounded-3"
                              width={20}
                              height={20}
                            />
                          </button>
                          <div className="comment-header mb-0 gap-2">
                            <Skeleton circle width={45} height={45} />
                            <span className="user-name">
                              <Skeleton width={150} height={20} />
                            </span>
                          </div>
                          <div className="comment-btns">
                            <button type="button">
                              <Skeleton width={20} height={20} />
                            </button>
                            <Skeleton width={20} height={20} />
                            <button type="button">
                              <Skeleton width={45} height={20} />
                            </button>
                            <button type="button">
                              <Skeleton width={45} height={20} />
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="add-comment">
                      <input
                        type="text"
                        placeholder="Add a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        name="reply"
                      />
                      <button
                        type="button"
                        onClick={() => handleAddComment(newComment)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="comments-container">
                      {commentsList.length === 0 ? (
                        <div className="no-comments-message">
                          No Comments Yet
                        </div>
                      ) : (
                        <ul className="comments-list">
                          {commentsList.map((comment, index) => (
                            <li key={comment.id} className="comment-item">
                              {isDeleting && (
                                <div className="loader-overlay">
                                  {commentIndex === index && <Loader />}
                                </div>
                              )}
                              {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  className="comment-delete"
                                  onClick={() => {
                                    setCommentIndex(index)
                                    shotCommentDelete(comment.reply_id)
                                  }}
                                >
                                  <img src={Delete} alt="delete icon" />
                                </button>
                              )}
                              <div className="comment-header mb-0">
                                <img
                                  src={comment.user.profilePic}
                                  alt={`${comment.user.name}'s profile`}
                                  className="profile-pic"
                                />
                                <span className="user-name">
                                  {comment.user.name}
                                </span>
                              </div>
                              {editCommentId === comment.id ? (
                                <div className="edit-reply">
                                  <input
                                    type="text"
                                    value={editedCommentText}
                                    onChange={(e) => setEditedCommentText(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleSaveComment(
                                        comment.reply_id,
                                        editedCommentText
                                      )}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : (
                                <div className="comment-text">
                                  {comment.text}
                                </div>
                              )}
                              <div className="comment-btns">
                                <button
                                  type="button"
                                  onClick={() => handleLikeComment(comment.reply_id)}
                                >
                                  {comment.is_like ? (
                                    <img src={Likes} alt="Unlike" />
                                  ) : (
                                    <img src={UnLikes} alt="Like" />
                                  )}
                                  {' '}
                                  {comment.total_like}
                                </button>
                                {comment.user.name === userData.user_name && (
                                  <button
                                    type="button"
                                    onClick={() => handleEditComment(
                                        comment.id,
                                        comment.text
                                      )}
                                  >
                                    Edit
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => toggleReplies(comment.id)}
                                >
                                  {showReplies[comment.id] ? 'Hide' : 'Show'}
                                  {' '}
                                  Replies
                                </button>
                              </div>
                              {showReplies[comment.id] && (
                                <ul className="replies-list">
                                  {comment.replies.map((reply) => (
                                    <li key={reply.id} className="reply-item">
                                      <div className="reply-header mb-0">
                                        <img
                                          src={reply.user.profilePic}
                                          alt={`${reply.user.name}'s profile`}
                                          className="profile-pic"
                                        />
                                        <span className="user-name">
                                          {reply.user.name}
                                        </span>
                                        {reply.user.name ===
                                          userData.user_name && (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setCommentIndex(index)
                                              handleDeleteReply(
                                                comment.reply_id,
                                                reply.reply_id
                                              )
                                            }}
                                            className="comment-delete"
                                          >
                                            <img
                                              src={Delete}
                                              alt="delete icon"
                                            />
                                          </button>
                                        )}
                                      </div>
                                      {editReplyId === reply.id ? (
                                        <div className="edit-reply">
                                          <input
                                            type="text"
                                            value={editedReplyText}
                                            onChange={(e) => setEditedReplyText(e.target.value)}
                                          />
                                          <button
                                            type="button"
                                            onClick={() => handleSaveReply(
                                                reply.reply_id,
                                                editedReplyText
                                              )}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="reply-text">
                                          {reply.text}
                                        </div>
                                      )}
                                      <div className="comment-btns">
                                        <button
                                          type="button"
                                          onClick={() => handleLikeReply(reply.reply_id)}
                                        >
                                          {reply.is_like ? (
                                            <img src={Likes} alt="Unlike" />
                                          ) : (
                                            <img src={UnLikes} alt="Like" />
                                          )}
                                          {' '}
                                          {reply.total_like}
                                        </button>
                                        {reply.user.name ===
                                          userData.user_name && (
                                          <button
                                            type="button"
                                            onClick={() => handleEditReply(
                                                reply.id,
                                                reply.text
                                              )}
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </div>
                                    </li>
                                  ))}
                                  <li className="add-reply">
                                    <input
                                      type="text"
                                      placeholder="Reply..."
                                      value={Replied}
                                      onChange={(e) => setShowReplied(e.target.value)}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => handleAddReply(
                                          comment.reply_id,
                                          Replied
                                        )}
                                    >
                                      Reply
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    {totalCommentPages > 1 && (
                      <CommentPagination
                        currentPage={currentPageComment}
                        totalPages={totalCommentPages}
                        onPageChange={handlePageChanges}
                      />
                    )}
                    <div className="add-comment">
                      <input
                        type="text"
                        placeholder="Add a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        name="reply"
                      />
                      {commentsList.length === 0 && (
                        <button
                          type="button"
                          onClick={() => handleAddComment(newComment, '', '')}
                        >
                          Add
                        </button>
                      )}
                      {commentsList.map((comment, i) => (
                        <button
                          key={i}
                          type="button"
                          onClick={() => handleAddComment(
                              newComment,
                              comment.user.profilePic,
                              comment.user.name
                            )}
                        >
                          Add
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      {showAddVideoModal && (
        <div className="add-item-model">
          <div
            className="add-item-content item-details-input"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleCancel}
              >
                <span>
                  <img className="mb-3 close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <Tabs>
                <TabList>
                  <Tab>Upload Video</Tab>
                  <Tab disabled={!selectedVideo}>Upload Cover</Tab>
                </TabList>

                <TabPanel>
                  <div>
                    {!selectedVideo && (
                      <div className="upload-input">
                        <label htmlFor="upload-video">
                          <input
                            onChange={handleVideoChange}
                            id="upload-video"
                            type="file"
                            accept="video/*"
                            className="d-none"
                          />
                          {selectedVideo && (
                            <button
                              type="button"
                              className={`edit-button-model content-meneger-edit ${
                                isEditing ? 'hidden' : ''
                              }`}
                              onClick={() => {
                                setIsEditing(true)
                                document.getElementById('upload-video').click()
                              }}
                            />
                          )}
                          {!selectedVideo && (
                            <div className="upload-div">
                              <div className="upload-img">
                                <img src={UploadImg} alt="" />
                              </div>
                              <h4>Upload a New Video</h4>
                              <p className="drag-drop-text">
                                Drag and drop your file or click the button
                                below to upload
                              </p>
                              <button
                                type="button"
                                htmlFor="upload-video"
                                onClick={() => document
                                    .getElementById('upload-video')
                                    .click()}
                              >
                                <span>Upload Video</span>
                              </button>
                            </div>
                          )}
                        </label>
                      </div>
                    )}

                    {selectedVideo && (
                      <div>
                        <video
                          key={selectedVideo}
                          ref={videoRef}
                          width="100%"
                          height="394"
                          controls
                          controlsList="nodownload noplaybackrate"
                          disablePictureInPicture
                        >
                          <source src={selectedVideo} type="video/mp4" />
                        </video>
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    {!selectedFilePath && (
                      <div className="upload-input">
                        <label htmlFor="upload-cover">
                          <input
                            onChange={handleFileChange}
                            id="upload-cover"
                            type="file"
                            accept="image/*"
                            className="d-none"
                          />
                          {selectedFilePath && (
                            <button
                              type="button"
                              className="edit-button-model content-meneger-edit"
                              onClick={() => document.getElementById('upload-cover').click()}
                            />
                          )}
                          {!selectedFilePath && (
                            <div className="upload-div mb-3">
                              <div className="upload-img">
                                <img src={UploadImg} alt="" />
                              </div>
                              <h4>Upload video cover</h4>
                              <p className="drag-drop-text">
                                Drag and drop your file or click the button
                                below to upload
                              </p>
                              <button
                                type="button"
                                htmlFor="upload-cover"
                                onClick={() => document
                                    .getElementById('upload-cover')
                                    .click()}
                              >
                                <span>Upload Cover</span>
                              </button>
                            </div>
                          )}
                        </label>
                      </div>
                    )}

                    {selectedFilePath && (
                      <div className="text-center image_view">
                        <img src={selectedFilePath} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="button-row pb-2">
                    <button
                      type="button"
                      className="secondary-btn-model"
                      onClick={handleCancel}
                    >
                      <span>Cancel</span>
                    </button>
                    <button
                      type="button"
                      className="primary-btn-model"
                      disabled={!selectedFilePath}
                      onClick={VideoDetailModel}
                    >
                      Next
                    </button>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleCancel}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="video_mb">
                <video
                  ref={videoRef}
                  width="100%"
                  height="394"
                  poster={selectedFilePath}
                  controls
                  controlsList="nodownload noplaybackrate"
                  disablePictureInPicture
                >
                  <source src={selectedVideo} type="video/mp4" />
                </video>
              </div>
              <div className="item-details-input">
                <label className="mb-1">Title of the Video</label>
                <input
                  placeholder="Title"
                  onChange={handleChange}
                  name="title"
                  value={capitalizeFirstLetter(inputFields.title)}
                  maxLength={100}
                />
                <label className="mb-1">Decription</label>
                <input
                  placeholder="Type here"
                  onChange={handleChange}
                  maxLength={250}
                  name="description"
                  value={capitalizeFirstLetter(inputFields.description)}
                />
                <div className="button-row">
                  <button
                    type="button"
                    className="secondary-btn-model"
                    onClick={handleBack}
                  >
                    <span>Back</span>
                  </button>
                  <button
                    type="button"
                    className="primary-btn-model"
                    disabled={!isFormComplete}
                    onClick={handlePreviewClick}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPreviewModal && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                disabled={loading}
                onClick={cancelHandle}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="common-card-body">
                <div>
                  <video
                    ref={videoRef}
                    width="100%"
                    height="394"
                    poster={selectedFilePath}
                    controls
                    controlsList="nodownload noplaybackrate nofullscreen"
                    disablePictureInPicture
                  >
                    <source src={selectedVideo} type="video/mp4" />
                  </video>
                </div>
                <div className="show-filled-details">
                  <h5>{inputFields.title}</h5>
                  <div className="d-flex streaming-data">
                    <p> 2,686 watching now</p>
                    <p> Started streaming on Mar 5, 2021</p>
                  </div>
                </div>
                <div className="podcast-profile mt-3">
                  <div className="podcast-profile-img">
                    <img src={s3ImagePath + userData.profile_image} alt="" />
                  </div>
                  <div className="podcast-profile-detail">
                    <span className="user_name">{userData.user_name}</span>
                    <p className="designation_p">Designation</p>
                  </div>
                </div>
              </div>
              <div className="discription common-card-body mb-30">
                <h6>Discription</h6>
                <p>{inputFields.description}</p>
              </div>
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />
              <div className="item-details-input">
                <div className="button-row">
                  <button
                    type="button"
                    className="secondary-btn-model"
                    disabled={loading}
                  >
                    <span onClick={cancelHandle}>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className={`primary-btn-model ${loading ? 'loading' : ''}`}
                    onClick={handlePublishClick}
                    disabled={loading}
                  >
                    {isAppsSelected ? 'Publish' : 'Upload'}
                    {loading && <span className="spinner" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default VideoDetails
