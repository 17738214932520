import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import './Loader.css'

const Loader = () => {
  return (
    <div className="loader-container">
      <RotatingLines
        visible
        height="40"
        width="40"
        color="#001597"
        strokeColor="#001597"
        strokeWidth="4"
        animationDuration="0.90"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  )
}
export default Loader
