import React from 'react'
import './Rating.css'
import ProgressBar from '@ramonak/react-progress-bar'

const BussinessProfileRatingContainer = () => {
  const items = [
    {
      totalRating: '4.0',
      value: '16145 ratings',
      percentage: ['1', '2', '5', '7', '88'],
      label: ['Terrible', 'Poor', 'Average', 'Very good', 'Excellent']
    }
  ]
  const generateStars = (totalRating) => {
    const count = Math.floor(parseFloat(totalRating))
    const stars = Array.from({ length: count }, (_, index) => (
      <svg
        key={index}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          d="M12.9976 5.32821L13.0833 5.43186L13.2095 5.47849C14.0056 5.77264 14.8433 5.947 15.6935 5.99575C16.5872 6.10773 17.3927 6.2111 18.0134 6.38902C18.6731 6.57809 18.9179 6.7954 18.9799 6.98248C19.0401 7.16414 18.9761 7.46682 18.5619 7.98954C18.2106 8.43294 17.7111 8.90818 17.1558 9.43648C17.0953 9.49399 17.0342 9.55213 16.9726 9.61093C16.3088 10.1282 15.7308 10.7417 15.2593 11.4301L15.18 11.5459L15.1725 11.686C15.1283 12.5134 15.2151 13.3423 15.4297 14.144C15.5905 14.9638 15.7344 15.7203 15.7498 16.338C15.7659 16.9885 15.6328 17.2816 15.4624 17.4012C15.3642 17.4678 15.2443 17.5032 15.1206 17.4998L15.1094 17.4995L15.098 17.4997C14.8111 17.5048 14.3991 17.3879 13.8701 17.158C13.3709 16.941 12.8292 16.6523 12.2717 16.355L12.2299 16.3327C11.5048 15.875 10.7089 15.5329 9.87257 15.3191L9.75275 15.2884L9.63246 15.3172C8.79419 15.5176 7.99334 15.8444 7.25942 16.286C7.22452 16.3042 7.18969 16.3223 7.15493 16.3405C6.63257 16.6131 6.12614 16.8775 5.65508 17.0801C5.13801 17.3025 4.72856 17.4207 4.43505 17.4207H4.41533L4.39568 17.4222C4.24575 17.4341 4.09773 17.3955 3.97668 17.3158C3.80878 17.1928 3.68165 16.9044 3.70387 16.2649C3.72519 15.6514 3.8759 14.8979 4.04433 14.0629C4.27169 13.2621 4.36649 12.4314 4.32503 11.6016L4.31819 11.4648L4.24265 11.3507C3.78081 10.6526 3.20729 10.0308 2.5441 9.5082C2.52325 9.48913 2.50246 9.47012 2.48174 9.45117C1.86401 8.88624 1.30391 8.37401 0.925143 7.89937C0.508462 7.37722 0.459214 7.08258 0.524998 6.90995C0.602184 6.7074 0.870901 6.49079 1.52754 6.30961C2.14408 6.1395 2.93081 6.04496 3.79541 5.94106L3.814 5.93882C4.66917 5.90195 5.51325 5.73485 6.3151 5.4435L6.44154 5.39756L6.52786 5.29437C7.06567 4.65142 7.49893 3.93225 7.81223 3.16163C8.19021 2.39548 8.53336 1.70354 8.8935 1.19797C9.27179 0.666923 9.5588 0.5 9.77845 0.5C9.99929 0.5 10.2845 0.66732 10.6591 1.20264C11.0128 1.70801 11.3475 2.39653 11.7182 3.15916L11.728 3.17922C12.0333 3.95555 12.4617 4.68054 12.9976 5.32821Z"
          fill="url(#paint0_linear_3703_119237)"
          stroke="url(#paint1_linear_3703_119237)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3703_119237"
            x1="9.75"
            y1="0"
            x2="9.75"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD739" />
            <stop offset="0.9999" stopColor="#F8C600" />
            <stop offset="1" stopColor="#FFD739" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3703_119237"
            x1="9.75"
            y1="0"
            x2="9.75"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD739" />
            <stop offset="0.9999" stopColor="#F8C600" />
            <stop offset="1" stopColor="#FFD739" />
          </linearGradient>
        </defs>
      </svg>
    ))
    return stars
  }
  return (
    <div className="services-container rating-container common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Product Quality
      </h3>
      {items.map((item) => (
        <div className="row align-items-center" key={item.totalRating}>
          <div className="col-md-5 col-lg-3 col-xl-3">
            <div className="m-auto" style={{ width: 'fit-content' }}>
              <h4>
                {item.totalRating}
                {' '}
                / 5
              </h4>
              <div className="d-flex col-gap-10">
                {generateStars(item.totalRating)}
              </div>
              <span className="total-rating-value">{item.value}</span>
            </div>
          </div>
          <div className="col-md-7 col-lg-9 col-xl-7">
            <div className="rating-column">
              {item.percentage.map((percentageValue, index) => (
                <div
                  className="rating-progress-bar rating-progress-container"
                  key={index}
                >
                  <span style={{ width: '100px' }}>{item.label[index]}</span>
                  <ProgressBar
                    className="profress-line"
                    baseBgColor="#E0E0E0"
                    height={8}
                    width={241}
                    completed={percentageValue}
                    borderRadius={25}
                    bgColor="#FC2F63"
                    isLabelVisible={false}
                  />
                  <div>
                    {/* <span>{percentageValue}%</span> <span>(1738)</span> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default BussinessProfileRatingContainer
