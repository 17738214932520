import React from 'react'
import './REcentShots.css'
import ShotsComponent from './ShotsComponent'


const AddShots = () => {
  return (
    <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
      <ShotsComponent showCount={3} />
    </div>
  )
}
export default AddShots

