import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Add from '../../images/Plus-tag.svg'
import Delete from '../../images/delete.svg'
import personality1 from '../../images/personality1.png'
import personality2 from '../../images/personality2.png'
import FoodIco from '../../images/FoodIco.svg'
import SustainabilityIco from '../../images/SustainabilityIco.svg'
import MovementIco from '../../images/MovementIco.svg'
import RelaxationIco from '../../images/RelaxationIco.svg'

const PersonalityTest = () => {
  return (
    <div className="categories-admin">
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={FoodIco} />
                  Food
                </Tab>
                <Tab>
                  <img alt="" src={RelaxationIco} />
                  Relaxation
                </Tab>
                <Tab>
                  <img alt="" src={MovementIco} />
                  Movement
                </Tab>
                <Tab>
                  <img alt="" src={SustainabilityIco} />
                  Sustainability
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <h3>Personality Test</h3>
                  <button type="button" className="primary-btn">
                    <span>
                      <img alt="" src={Add} />
                      {' '}
                      Add New Question
                    </span>
                  </button>
                </div>
                <div className="common-card-body">
                  <div>
                    <button type="button" className="light-button">
                      <span>
                        <img alt="" src={Delete} />
                        {' '}
                        Remove
                      </span>
                    </button>
                  </div>
                  <div className="personality-questions">
                    <h4>Q #: 1</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cum auctor proin est aliquam massa.
                      {' '}
                    </p>
                    <div className="personality-test">
                      <div>
                        <img alt="" src={personality1} />
                      </div>
                      <h2>OR</h2>
                      <div>
                        <img alt="" src={personality2} />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PersonalityTest
