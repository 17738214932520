/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false)
  const navigate = useNavigate()
  const logOut = () => {
    localStorage.clear()
    navigate('/login', { replace: true })
    toast.dismiss()
    toast.error('Session Expired, Please Login Again')
  }

  useEffect(() => {
    const user = localStorage.getItem('token')
    if (!user) {
      navigate('/login')
    } else if (user) {
      const { exp } = jwtDecode(user)
      const tokenExp = exp - 60
      const currentTime = Date.now() / 1000
      if (tokenExp < currentTime) {
        logOut()
      }
    }
    setStatus(true)
  }, [component])

  return status ? component : null
}

export default AuthGuard
