import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './ServiceCenter.css'
import { Pagination, Navigation } from 'swiper/modules'
import { Link } from 'react-router-dom'

const SupportCetegory = () => {
  const items = [
    {
      name: 'Help Articles',
      link: '/help-articles',
      icon: './images/servicecetegory/HelpArticles.svg'
    },
    {
      name: 'Culture',
      link: '/culture',
      icon: './images/servicecetegory/Culture.svg'
    },
    {
      name: 'Party / Bar',
      link: '/party-bar',
      icon: './images/servicecetegory/Bar.svg'
    },
    {
      name: 'Art',
      link: '/art',
      icon: './images/servicecetegory/Art.svg'
    },
    {
      name: 'Locations',
      link: '/locations',
      icon: './images/servicecetegory/Locations.svg'
    },
    {
      name: 'Help Articles',
      link: '/help-articles',
      icon: './images/servicecetegory/HelpArticles.svg'
    },
    {
      name: 'Culture',
      link: '/culture',
      icon: './images/servicecetegory/Culture.svg'
    },
    {
      name: 'Party / Bar',
      link: '/party-bar',
      icon: './images/servicecetegory/Bar.svg'
    },
    {
      name: 'Art',
      link: '/art',
      icon: './images/servicecetegory/Art.svg'
    },
    {
      name: 'Locations',
      link: '/locations',
      icon: './images/servicecetegory/Locations.svg'
    }
  ]
  return (
    <div className="support-cetegory-slider recent-video-component">
      <h3>Support Categories</h3>
      <div className="slide-item position-relative">
        <Swiper
          pagination={{
            type: 'progressbar'
          }}
          navigation
          slidesPerView="auto"
          spaceBetween={82}
          modules={[Pagination, Navigation]}
          className="mySwiper position-static"
        >
          {items.map((item) => (
            <SwiperSlide key={item}>
              <Link to={item.link} className="swiper-item">
                <div className="swiper-item-img">
                  <img alt="" src={item.icon} />
                </div>
                <h2>{item.name}</h2>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
export default SupportCetegory
