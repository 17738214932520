import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import ReactDatePicker from 'react-datepicker'

const ApexChart = () => {
  const [chartData] = useState({
    series: [
      {
        name: 'This Week',
        data: [
          310000000, 400000000, 280000000, 510000000, 420000000, 1090000000,
          1000000000
        ]
      },
      {
        name: 'Last Week',
        data: [
          400000000, 600000000, 290000000, 530000000, 40000000, 990000000, 0
        ]
      }
    ],
    options: {
      legend: {
        show: true,
        markers: {
          fillColors: ['#001C93', '#ECE9F1'] // Colors for legend markers
        }
      },
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: ['#001C93', '#ffffff'],
        opacity: [0.9, 0],
        type: 'gradient'
      },
      stroke: {
        curve: 'smooth',
        colors: ['#001597', '#ece9f1']
      },
      xaxis: {
        type: 'category',
        categories: ['1', '5', '10', '15', '20', '25', '30']
      },
      yaxis: {
        tickAmount: 4, // Set the number of ticks on the y-axis
        labels: {
          formatter(value) {
            // Convert large numbers to "k" format and remove trailing zeros
            const absValue = Math.abs(value)
            if (absValue >= 1.0e9) {
              return `${(absValue / 1.0e9).toFixed(1).replace(/\.0$/, '')}k`
            } if (absValue >= 1.0e6) {
              return `${(absValue / 1.0e6).toFixed(1).replace(/\.0$/, '')}k`
            } if (absValue >= 1.0e3) {
              return `${(absValue / 1.0e3).toFixed(1).replace(/\.0$/, '')}k`
            }
              return value.toFixed(1).replace(/\.0$/, '')
          }
        }
      },
      tooltip: {
        enabled: true,
        fixed: {
          enabled: false,
          position: 'top',
          offsetX: 0,
          offsetY: 0
        },
        x: {
          show: false,
          format: 'dd/MM/yy HH:mm'
        },
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex === 1) {
            // Hide tooltip for series2
            return ''
          }
          const series1Data = w.globals.series[seriesIndex][dataPointIndex]
          return `<div class='chart-tooltip-data'><div class='this-month'>This Month</div><div class='chart-data'>${series1Data}</div> <div class='month-name'>Nov</div></div>`
        }
      }
    }
  })
  const [startDate, setStartDate] = useState(new Date('2014/02/08'))
  const [endDate, setEndDate] = useState(null)

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }
  return (
    <div id="chart">
      <div className="ads-chart">
        <div className="ads-chart-header d-flex flex-wrap justify-content-between align-items-center">
          <h2>Advertisements Likes</h2>
          <div className="monthpicker chart p-0 border-0">
            <button type="button" className="previous-month-button">
              <span />
              Previous Month
            </button>
            <ReactDatePicker
              selected={startDate}
              onChange={handleChange}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
        </div>
        <div className="chart-indicator d-flex flex-wrap align-items-center col-gap-20 p-30">
          <div>
            <h4>
              137,540
              {' '}
              <span>Total Avdertisements Likes</span>
            </h4>
            <div className="chart-lavel chart-lavel-green">0.9% Up</div>
          </div>
          <div>
            <h4>
              137,5
              {' '}
              <span>This Week Avdertisements Likes</span>
            </h4>
            <div className="chart-lavel chart-lavel-red">0.9% Up</div>
          </div>
        </div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="area"
          height={350}
          style={{ padding: '30px' }}
        />
      </div>
    </div>
  )
}

export default ApexChart
