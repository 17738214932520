import React from 'react'
import { Link } from 'react-router-dom'

const Menu = () => {
  const items = [
    {
      img: './images/Restaurant/breakfast.svg',
      name: 'Breakfast',
      detail:
        'Lorem ipsum dolor sit amet, consectetur adip iscing elit. Turpis id sed eu mattis. Tort Turpis id sed eu mattis. Tortor. '
    },
    {
      img: './images/Restaurant/lunch.svg',
      name: 'Lunch',
      detail:
        'Lorem ipsum dolor sit amet, consectetur adip iscing elit. Turpis id sed eu mattis. Tort Turpis id sed eu mattis. Tortor. '
    },
    {
      img: './images/Restaurant/dinner.svg',
      name: 'Dinner',
      detail:
        'Lorem ipsum dolor sit amet, consectetur adip iscing elit. Turpis id sed eu mattis. Tort Turpis id sed eu mattis. Tortor. '
    },
    {
      img: './images/Restaurant/dessert.svg',
      name: 'Dessert',
      detail:
        'Lorem ipsum dolor sit amet, consectetur adip iscing elit. Turpis id sed eu mattis. Tort Turpis id sed eu mattis. Tortor. '
    }
  ]
  return (
    <div className=" services-container menu-wrapper common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Our Menu
      </h3>
      <div className="restaurant-menu--grid">
        {items.map((item) => (
          <div className="restaurant-menu--item" key={item.name}>
            <div className="menu--image">
              <img alt="" src={item.img} />
            </div>
            <div className="manu--details">
              <h4>{item.name}</h4>
              <p>{item.detail}</p>
              <h3>
                <Link>See menu</Link>
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Menu
