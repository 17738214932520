import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import ProgressBar from '@ramonak/react-progress-bar'

const Rating = () => {
  const location = useLocation()
  const { state } = location || {}
  const productRating = state || []

  const [startDateRange, setStartDateRange] = useState(new Date('2014/02/08'))
  const [endDateRange, setEndDateRange] = useState(new Date('2014/02/10'))

  const generateStars = (rating) => {
    const totalStars = 5 // Total number of stars to display
    const count = Math.min(Math.floor(parseFloat(rating)), totalStars)
    const remainder = parseFloat(rating) - count // Decimal part to represent partial star
    const stars = []
    for (let i = 0; i < count; i++) {
      stars.push(<img alt="" key={i} src="./images/star.svg" />)
    }
    if (remainder > 0) {
      stars.push(<img alt="" key={count} src="./images/star.svg" />)
    }
    for (let i = stars.length; i < totalStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
    }

    return stars
  }
  return (
    <div className="creator-profile-main">
      <div className="common-card-body  recent-video-component">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="m-0">All Rating </h3>
          <div className="date-range">
            <ReactDatePicker
              selected={startDateRange}
              onChange={(date) => setStartDateRange(date)}
              selectsStart
              startDate={startDateRange}
              endDate={endDateRange}
              dateFormat="dd/MM/yyyy"
            />
            <span>To</span>
            <ReactDatePicker
              selected={endDateRange}
              onChange={(date) => setEndDateRange(date)}
              selectsEnd
              startDate={startDateRange}
              endDate={endDateRange}
              minDate={startDateRange}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="">
          {productRating.map((item, index) => (
            <div className="product-detail-card mt-20" key={index}>
              <div className="row">
                <div className="product-img col-md-6">
                  <img alt="" src={item.img} />
                </div>
                <div className="col-md-6">
                  <h3>Product Quality</h3>
                  <h2>{item.rating}</h2>
                  <div className="rating-star mb-30 mt-1 justify-content-start">
                    {generateStars(item.rating)}
                  </div>
                  <span>
                    {item.totalRating}
                    {' '}
                    ratings
                  </span>
                </div>
              </div>
              <div className="rating-column">
                {item.percentage?.map((percentageValue, index) => (
                  <div
                    className="rating-progress-bar rating-progress-container"
                    key={index}
                  >
                    <span>{index + 1}</span>
                    <ProgressBar
                      className="profress-line"
                      baseBgColor="#E0E0E0"
                      height={20}
                      width={394}
                      completed={percentageValue}
                      borderRadius={25}
                      bgColor="linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%)"
                      isLabelVisible={false}
                    />
                    <div>
                      <span>
                        {percentageValue}
                        %
                      </span>
                      {' '}
                      <span>(1738)</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Rating
