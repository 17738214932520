import React, { useState } from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import Feeds from '../Feeds/Feeds'
import OurTeamProfile from '../OurTeam/OurTeam'
import RatingContainer from '../RatingContainer/Rating'
import ServicesProfile from '../Services/Services'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import Slider from '../ReviewsAndRating/slider'
import PersonalTraining from '../../images/personal-training.png'
import Aerobics from '../../images/aerobics.png'
import Yoga from '../../images/yoga.png'
import Exercice from '../../images/exersice.png'
import Therapie from '../../images/therapir.png'
import CurruntJobs from './CurruntJobs'
import Jobs from './Jobs'
import AboutIco from '../../images/AboutIco.svg'
import HomeIco from '../../images/HomeIco.svg'
import FeedbackIco from '../../images/FeedbackIco.svg'
import ServiceIco from '../../images/ServiceIco.svg'
import FeedIco from '../../images/FeedIco.svg'
import JobIco from '../../images/JobIco.svg'

const EducationSchoolUnivercity = () => {
  const items = [
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    },
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    },
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    }
  ]
  const Badgeitems = [
    {
      badge: './images/badge/1.svg'
    },
    {
      badge: './images/badge/2.svg'
    },
    {
      badge: './images/badge/3.svg'
    },
    {
      badge: './images/badge/4.svg'
    },
    {
      badge: './images/badge/5.svg'
    },
    {
      badge: './images/badge/6.svg'
    },
    {
      badge: './images/badge/7.svg'
    },
    {
      badge: './images/badge/8.svg'
    },
    {
      badge: './images/badge/9.svg'
    }
  ]
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div
        className={`feed-container mt-30 ${tabIndex === 1 ? '' : 'd-block'}`}
      >
        <div
          className={`details-tab-view common-card-body ${
            tabIndex === 1 ? 'feed-left' : ''
          }`}
        >
          <div className="tabs-for-details-view">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab>
                  <img src={HomeIco} alt="" />
                  Home
                </Tab>
                <Tab>
                  <img src={FeedIco} alt="" />
                  Feed
                </Tab>
                <Tab>
                  <img src={ServiceIco} alt="" />
                  Services
                </Tab>
                <Tab>
                  <img src={JobIco} alt="" />
                  Jobs
                </Tab>
                <Tab>
                  <img src={FeedbackIco} alt="" />
                  Reviews/Rating
                </Tab>
                <Tab>
                  <img src={AboutIco} alt="" />
                  About
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="facilities-gellery mt-40 recent-video-component">
                  <h3>Services</h3>
                  <div className="grid-gellery">
                    <div className="gellery-item grid-1">
                      <img src={PersonalTraining} alt="" />
                      <h4>Personal Training</h4>
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img src={Aerobics} alt="" />
                      <h4>Aerobics</h4>
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img src={Yoga} alt="" />
                      <h4>Yoga</h4>
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img src={Exercice} alt="" />
                      <h4>Exercise</h4>
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img src={Therapie} alt="" />
                      <h4>Therapie</h4>
                      <div className="overlay-gredient" />
                    </div>
                  </div>
                </div>
                <div className="mt-40">
                  <CurruntJobs />
                </div>
                <div className="free-courses-starter-pack mt-40">
                  <div className="details">
                    <h5>Free Courses Starter Pack </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pulvinar consequat urna pharetra.
                    </p>
                  </div>
                  <button type="button" className="light-button">
                    <span>See More</span>
                  </button>
                </div>
                <div className="mt-40">
                  <OurTeamProfile />
                </div>
                <div className="about common-card-body recent-video-component mt-40">
                  <h3>About</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                    pellentes nullam sit amet, lorem. Semper vel egestas
                    convallis nisl duis mattis ut molestie sed. Mauris feugiat
                    tincidunt nunc, quam mauris, massa diam faucibus.
                    {' '}
                    <span>See more</span>
                  </p>
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div>
                  <Feeds />
                </div>
                <div className="free-courses-starter-pack mt-30">
                  <div className="details">
                    <h5>Free Courses Starter Pack </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pulvinar consequat urna pharetra.
                    </p>
                  </div>
                  <button type="button" className="light-button">
                    <span>See More</span>
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <ServicesProfile />
              </TabPanel>
              <TabPanel>
                <Jobs />
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {tabIndex === 1 && (
          <div className="common-card-body feed-right p-20">
            <h3>completed challenges</h3>
            <div className="feed-challanges-container">
              {items.map((item) => (
                <div
                  key={item.name}
                  className="feed-challanges-item mt-20"
                  style={{
                    background: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  <div className="about-challanges">
                    <p>{item.name}</p>
                    <div className="d-flex justify-content-between">
                      <span>{item.date}</span>
                      <span>{item.time}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button type="button">See All</button>
            <h3>Badges</h3>
            <div className="badge-container">
              {Badgeitems.map((item) => (
                <div className="badge-item" key={item.badge}>
                  <img src={item.badge} alt="" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default EducationSchoolUnivercity
