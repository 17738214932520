/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import { ForgotPassword } from '../../services/Service'

const ForgetPasswordPage = () => {
  const navigate = useNavigate()

  const [inputFields, setInputFields] = useState({
    email: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.email.length === 0) {
      errors.email = 'Please enter email'
    }
    return errors
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    if (emailRegex.test(e.target.value)) {
      setIsButtonEnabled(true)
    } else {
      setIsButtonEnabled(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    ForgotPassword(inputFields).then((res) => {
      if (res.data.success) {
        localStorage.setItem('forgotPassword', inputFields.email)
        navigate('/reset-password')
        setLoading(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  return (
    <div className=" auth-register auth-login">
      <div className="bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6">
          <div className="h-100 inline-padding mt-5">
            <div className="w-100">
              <h2 className="mt-5 pt-5">Find Your Account</h2>
              <form onSubmit={handleSubmit}>
                <p>
                  <label className="clip-text">Email *</label>
                  <div className="email-input">
                    <input
                      type="email"
                      name="email"
                      placeholder="Find your APP WORLD ONE Account"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  {errors.email ? (
                    <p className="error-text">{errors.email}</p>
                  ) : null}

                  <footer>
                    <p className="text-start">
                      Please enter your email address to receive a new OTP
                    </p>
                  </footer>
                  <button
                    type="submit"
                    className="primary-button m-auto mt-1"
                    disabled={!isButtonEnabled || loading}
                  >
                    <span>Submit</span>
                    {loading && <span className="spinner" />}
                  </button>
                </p>
                {/* <p>
                  <button id="sub_btn" className='secondary-button m-auto' type="submit">
                  <span>Create Account</span>
                  </button>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForgetPasswordPage
