import React from 'react'
import VideoPlayIco from '../../images/VideoPlayIco.svg'

const Donation = () => {
  const items = [
    {
      img: './images/workplace/1.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/2.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/3.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/4.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/5.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/6.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    }
  ]
  const VideoStyle = {
    background: 'url("./images/workplace/1.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const ConponentStyle = {
    background: 'url("./images/workplace/6.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  return (
    <div>
      <div className="donation-head">
        <div className="video-wall" style={VideoStyle}>
          <div className="overlay" />
          <div className="video-button">
            <img alt="" src={VideoPlayIco} />
          </div>
        </div>
        <div className="recent-video-component">
          <h3>FEGG India</h3>
          <p>
            FEGG Foundation to Educate Girls Globally is an award-winning Indian
            non-profit that leverages existing community and government
            resources to ensure that all girls are in school and learning well
          </p>
          <button type="button" className="primary-btn">Learn More</button>
        </div>
      </div>

      <div className="donate-section mt-40">
        <div className="recent-video-component w-40">
          <h3>Donation</h3>
          <p>
            20 million more girls may never return to school once the COVID-19
            crisis subsides.
          </p>
          <p>
            <strong>
              Donate today to keep girls learning during and after the global
              crisis.
            </strong>
          </p>
        </div>
        <div className="donate-amount w-60">
          <div className="custom-input-select mb-20">
            <div>
              <input type="radio" id="onetime" name="radio-group" checked />
              <label htmlFor="onetime">
                <span>ONE TIME</span>
              </label>
            </div>
            <div>
              <input type="radio" id="monthly" name="radio-group" />
              <label htmlFor="monthly">
                <span>MONTHLY</span>
              </label>
            </div>
          </div>
          <div className="custom-input-select">
            <div>
              <input type="radio" id="25" name="radio-group1" />
              <label htmlFor="25">
                <span>€25</span>
              </label>
            </div>
            <div>
              <input type="radio" id="50" name="radio-group1" />
              <label htmlFor="50">
                <span>€50</span>
              </label>
            </div>
            <div>
              <input type="radio" id="100" name="radio-group1" />
              <label htmlFor="100">
                <span>€100</span>
              </label>
            </div>
            <div>
              <input type="radio" id="250" name="radio-group1" />
              <label htmlFor="250">
                <span>€250</span>
              </label>
            </div>
          </div>
          <div className="donate-custom">
            <input />
          </div>
          <button type="button" className="primary-btn w-100">Donate</button>
        </div>
      </div>

      <div className="services-container common-card-body recent-video-component mt-40">
        <h3 className="d-flex align-items-center justify-content-between mb-3">
          Where we work
        </h3>
        <p className="mb-30">
          FEGG Fund’s Education Champion Network supports the work of educators
          and advocates and helps bolster girls’ secondary education around the
          world.
        </p>
        <div className="workplace-grid">
          {items.map((item) => (
            <div key={item.heading} className="workplace-item">
              <img alt="" src={item.img} />
              <div className="hover-content">
                <h4>{item.heading}</h4>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="donation-join-mail" style={ConponentStyle}>
        <div className="position-relative z-1">
          <h3>The fight for girls’ education won’t be won by girls alone.</h3>
          <p>
            Sign up to learn how you can help support FEGG Fund and receive the
            latest updates on our work.
          </p>
        </div>
        <div className="position-relative z-1 d-flex align-items-center">
          <input placeholder="Your Email" />
          <button type="button" className="light-button ms-3">
            <span>Join</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export default Donation
