import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import RatingContainer from '../RatingContainer/Rating'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import SliderLocal from '../LocalProfile/Slider'
import Rooms from '../LocalProfile/Rooms'
import Photos from '../Creator-profile-photos/photos'
import Slider from '../ReviewsAndRating/slider'
import HomeAbout from '../HomeAbout/HomeAbout'
import shover from '../../images/shower-linier.svg'
import Wifi from '../../images/wifi-linier.svg'
import Cigarette from '../../images/air-conditioner.svg'
import RoomService from '../../images/room-service-linear.svg'
import Iron from '../../images/iron-linier.svg'
import Library from '../../images/locker-room.svg'
import Offer from './offer'
import Booking from './Booking'
import AboutIco from '../../images/AboutIco.svg'
import HomeIco from '../../images/HomeIco.svg'
import FeedbackIco from '../../images/FeedbackIco.svg'
import RoomIco from '../../images/RoomIco.svg'
import OfferIco from '../../images/OfferIco.svg'
import BookingIco from '../../images/BookingIco.svg'
import ShowIco from '../../images/ShowIco.svg'

const Hotel = () => {
  const items = [
    {
      image: './images/hotels/4.jpg'
    },
    {
      image: './images/hotels/5.jpg'
    },
    {
      image: './images/hotels/6.jpg'
    },
    {
      image: './images/hotels/7.jpg'
    },
    {
      image: './images/hotels/8.jpg'
    },
    {
      image: './images/hotels/9.jpg'
    },
    {
      image: './images/hotels/7.jpg'
    },
    {
      image: './images/hotels/8.jpg'
    },
    {
      image: './images/hotels/9.jpg'
    }
  ]
  const itemsReview = [
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    }
  ]
  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={HomeIco} />
                  Home
                </Tab>
                <Tab>
                  <img alt="" src={RoomIco} />
                  Rooms
                </Tab>
                <Tab>
                  <img alt="" src={OfferIco} />
                  Offers
                </Tab>
                <Tab>
                  <img alt="" src={BookingIco} />
                  Booking
                </Tab>
                <Tab>
                  <img alt="" src={FeedbackIco} />
                  Reviews/Rating
                </Tab>
                <Tab>
                  <img alt="" src={AboutIco} />
                  About
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="mt-40">
                  <SliderLocal />
                </div>
                <div className="mt-40">
                  <Rooms />
                </div>
                <div className="mt-40">
                  <Photos propsitems={items} />
                </div>
                <div className="mt-40 booking">
                  <form className="common-card-body">
                    <h5>Book unique places to stay and things to do</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Non turpis.
                    </p>
                    <div className="input-label">
                      <label>User Name</label>
                      <input placeholder="User name" />
                    </div>
                    <div className="input-label">
                      <label>Email</label>
                      <input placeholder="User name" />
                    </div>
                    <div className="input-label">
                      <label>Message</label>
                      <input placeholder="Enter your message here..." />
                    </div>
                    <button type="button" className="primary-btn">Contact Us</button>
                  </form>
                </div>
                <div className="mt-40">
                  <HomeAbout />
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <h3 className="room-tab--heading">Room Photos</h3>
                <div className="grid-gellery position-relative">
                  <div className="gellery-item grid-1--span-4">
                    <img alt="" src="./images/gellery/1.jpg" />
                    <div className="overlay-gredient" />
                  </div>
                  <div className="gellery-item">
                    <img alt="" src="./images/gellery/2.jpg" />
                    <div className="overlay-gredient" />
                  </div>
                  <div className="gellery-item">
                    <img alt="" src="./images/gellery/3.jpg" />
                    <div className="overlay-gredient" />
                  </div>
                  <button type="button" className="show-photos">
                    <img alt="" src={ShowIco} />
                    <span>Show all photos</span>
                  </button>
                </div>

                <div className="discription-box recent-video-component mb-0 mt-40">
                  <h3>Description</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin iaculis morbi tempus volutpat tortor. Ipsum vitae enim
                    sit suscipit in ridiculus. Morbi potenti eu facilisis vitae
                    posuere suspendisse. Praesent sit lorem amet posuere vel
                    diam scelerisque a. Nisl imperdiet egestas dui consequat,
                    diam et, at tempor.
                  </p>
                </div>

                <div className="common-card-body recent-video-component place-offer mt-40">
                  <h3>What this place offers</h3>
                  <ul>
                    <li>
                      <img alt="" src={shover} />
                      Parking
                    </li>
                    <li>
                      <img alt="" src={Wifi} />
                      Free Wifi
                    </li>
                    <li>
                      <img alt="" src={Cigarette} />
                      Cigarette
                    </li>
                    <li>
                      <img alt="" src={RoomService} />
                      Room Service
                    </li>
                    <li>
                      <img alt="" src={Iron} />
                      Iron
                    </li>
                    <li>
                      <img alt="" src={Library} />
                      Library
                    </li>
                  </ul>
                </div>

                <div className="common-card-body mt-40 recent-video-component">
                  <h3>Reviews</h3>
                  <div className="hotel-reserve--review">
                    {itemsReview.map((item) => (
                      <div
                        className="hotel-reserve-review--item"
                        key={item.name}
                      >
                        <p>{item.review}</p>
                        <div className="devider" />
                        <div className="hotel-reserve-review--detail d-flex align-items-center col-gap-20">
                          <img alt="" src={item.image} />
                          <div>
                            <p>{item.name}</p>
                            <span>{item.date}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-center mt-30">
                  <button type="button" className="primary-btn w-4">Select Room</button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-40">
                  <SliderLocal />
                </div>
                <div className="mt-40">
                  <Offer />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-40">
                  <Booking />
                </div>
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Hotel
