import React from 'react'
import { Link } from 'react-router-dom'
// import LikeEmpty from '../../images/like-empty.svg'

const CommunityMegazines = () => {
  // const items = [
  //   {
  //     CoverImage: './images/megazines-1.png',
  //     Title: 'Shoes',
  //     text: 'Lorum ipsum',
  //     price: '$25.00'
  //   },
  //   {
  //     CoverImage: './images/megazines-2.png',
  //     Title: 'Shoes',
  //     text: 'Lorum ipsum',
  //     price: '$25.00'
  //   },
  //   {
  //     CoverImage: './images/megazines-3.png',
  //     Title: 'Shoes',
  //     text: 'Lorum ipsum',
  //     price: '$25.00'
  //   },
  //   {
  //     CoverImage: './images/megazines-4.png',
  //     Title: 'Shoes',
  //     text: 'Lorum ipsum',
  //     price: '$25.00'
  //   }
  // ]

  return (
    <div className="content-manager-communitymegazines-wrapper common-card-body">
      <div className="recipies-wrapper-header common-contnt-wrapper">
        <h2>Megazines</h2>
        <Link>View All</Link>
      </div>
      <div className="megazines-row">
        {/* {items.map((item) => (
          <div className="magazines-items" key={item.Title}>
            <div
              className="magazines-cover"
              style={{
                background: `url(${item.CoverImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            >
              <div className="like">
                <img src={LikeEmpty} alt="like" />
              </div>
            </div>
            <div className="magazines-detail">
              <h5>{item.Title}</h5>
              <div className="other-detail-megazines">
                <span>{item.text}</span>
                <p className="price">{item.price}</p>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  )
}
export default CommunityMegazines
