import React from 'react'
import './Ads.css'
import ApexChart from './chart'

const ChartContainer = () => {
  return (
    <div className="chart-container">
      <div className="mt-30">
        <ApexChart />
      </div>
      <div className="mt-30">
        <ApexChart />
      </div>
      <div className="mt-30">
        <ApexChart />
      </div>
    </div>
  )
}
export default ChartContainer
